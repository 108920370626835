import classes from './ListView.module.scss';
import { Link } from "react-router-dom";
import NetImg from './lib/NetImg';

export const ListViewItem = (props) => {

    const component = {val: props.noLink ? "div" : (props.url ? "a" : Link)}


    return (
        <component.val href={props.url} to={props.to || `/shop/${props.id}`} className={`${classes.item} ${props.className}`} onClick={props.onClick}>
            <NetImg className={classes.icon} src={props.icon || '/profile/food-ico.svg'} alt="icon" />
            <div className={classes.text}>
                <div className={classes.title}>{props.name}</div>
                <div className={classes.desc}>{props.desc}</div>
                {props.children}
            </div>
        </component.val>
    )
}

const ListView = (props) => {

    return (
        <div className={classes.root}>

            {props.datas?.map((item, index) => (
                <ListViewItem key={index} {...item} />
            ))}
            {props.children}

        </div>
    );
};

export default ListView;