import { Route } from 'react-router-dom';
import { useAuthContext } from '../context/lib/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect, } from "react";
import { useHistory } from 'react-router-dom';

const AppRoute = ({ component, props, layout: Layout, ...rest }) => {
    

    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory();

    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{


        const init = async ()=>{

            //認証チェックしない時はスキップ
            if(!rest.authCheck){

                setIsLoading(false)
                return
            }
            
            if(user.current === undefined)
                return


            //ログインしていない時
            if(user.current === null)
            {
                //ブート画面に移動
                history.push(`/authlogin?url=${ encodeURIComponent(window.location.pathname + window.location.search) }`)
                return
            }

            setIsLoading(false)
        }
        init()

        
    }, [user, history, rest.authCheck])

    //読込中
    if(isLoading)
        return (
            <Box sx={{
                position: 'fixed', left:0, right:0, top:0, bottom: 0,
                margin: 'auto', zIndex: 10000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor: '#ffffff99', }}>
                <CircularProgress />
                <Box sx={{ fontSize:12, marginTop: 3, }}>
                    ログインしています
                </Box>
            </Box>
        )

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                
                return <Layout 
                    component={component}
                    props={{ ...props, ...routeProps}}
                />
            }}
        />
    );
};

export default AppRoute;