import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import classes from './Temple.module.scss';
import { Link, useHistory } from 'react-router-dom';
import Iframe from 'react-iframe';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useCartContext } from '../context/lib/CartContext';
import { doc, getDoc } from 'firebase/firestore';
import { firebaseDB, } from './lib/firebase';
import { FirestoreMap$profiles } from './lib/FirestoreMap';
import LoadingScreen from './lib/LoadingScreen';
import Footer from './Footer';

const Temple = (props) => {
    const { uuid } = useParams();
    const [temple, setTemple] = useState(null);
    const history = useHistory();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const slideRef = useRef(null);
    const { addItem, clearCart, removeItem, items } = useCartContext();

    useEffect(() => {
        const fetchTempleData = async () => {
            console.log(props)
            const docRef = doc(firebaseDB, FirestoreMap$profiles, props?.コード || uuid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setTemple(docSnap.data());
            } else {
                console.log('No such document!');
            }
        };

        fetchTempleData();
    }, [uuid, props]);

    const goToSlide = (index) => {
        if (slideRef.current) {
            slideRef.current.goTo(index);
            setCurrentImageIndex(index);
        }
    };

    if (!temple) {
        return <LoadingScreen show={true}/>
    }

    const images = temple.imgs;
    const basicInfo = [
        { label: '所在地', value: temple.所在地 },
        { label: '駐車場', value: `${temple.駐車場}${temple.駐車場 === "あり" ? `(${temple.駐車可能台数})` : ""}` },
        { label: '収容人数', value: temple.収容人数 },
        { label: '空調', value: '冷暖房完備' }, // ここはデータに基づいて変更する必要があるかもしれません
        { label: 'その他', value: [
            { label: '宿泊', value: temple.宿泊 },
            { label: '会食室', value: temple.会食室 },
            { label: 'バリアフリー', value: temple.バリアフリー },
            { label: '貸し切り', value: temple.貸し切り },
            { label: '控室', value: temple.控室 },
            { label: '法要室', value: temple.法要室 },
            { label: '飲食持ち込み', value: temple.飲食持ち込み },
        ]}
    ];

    const handleAddButtonClick = () => {
        console.log('Add button clicked');
        // すでに追加されていたら削除
        const existItem = items.find(item => item.コード === temple.コード);
        if (existItem) {
            console.log('すでに追加されています');
            console.log(existItem)
            removeItem(existItem.uuid);
            return;
        }
        addItem(temple);
    };
    const handleRequestButtonClick = () => {
        console.log('Request button clicked');
        clearCart();
        addItem(temple);
        history.push('/request')
    };

    return (
        <div className={classes.root}>
            {/* Header */}
            <div className={classes.header}>
                <Link to="/" className={classes.backButton}>
                    <ArrowBackIosNewIcon />
                </Link>
                <h1 className={classes.title}>{temple.寺院名}</h1>
                <p className={classes.subTitle}>{temple.宗派}</p>
            </div>

            {/* Main Image */}
            <div className={classes.mainImage}>
                <Slide
                    ref={slideRef}
                    selectedItem={currentImageIndex}
                    onChange={(index) => setCurrentImageIndex(index)}
                    duration={500}
                    transitionDuration={300}
                    infinite={true}
                    arrows={true}
                    autoplay={false} // 自動再生を無効にする
                >
                    {images.map((image, index) => (
                        <img key={index} src={`${image.url}`} alt={`Main Slide`} />
                    ))}
                </Slide>
            </div>

            {/* Thumbnail Slider */}
            <div className={classes.thumbnailSlider}>
                {images.map((image, index) => (
                    <div key={index} className={classes.eachSlide} onClick={() => goToSlide(index)}>
                        <img src={`${image.url}`} alt={`Slide ${index}`} />
                    </div>
                ))}
            </div>

            {/* Location */}
            <div className={classes.location}>
                <h2>所在地</h2>
                {temple['寺院郵便番号(‐あり)'] && <div className={classes.postalCode}>〒{temple['寺院郵便番号(‐あり)']}</div>}
                <div className={classes.address}>{temple.所在地}</div>
                <div className={classes.map}>
                    <Iframe
                        src={`https://maps.google.co.jp/maps?output=embed&q=${temple.所在地}`}
                        width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="eager" />
                </div>
            </div>

            {/* Plan */}
            <div className={classes.plan}>
                <h2>プラン</h2>
                <div className={classes.planItem}>
                    <div className={classes.planSubTitle}>全てを揃ったワンパック料金</div>
                    <div className={classes.planTitle}>あんしんプラン</div>
                    <div className={classes.option}>
                        <img className={classes.planImage} src="/search-list/plan-a.jpg" alt="Plan" />
                        <div className={classes.serviceList}>
                            {false && /* 無効にする場合のスタイル→ */<div className={classes.serviceItem}><CloseIcon className={classes.serviceIcon} />お迎え</div>}
                            <div className={`${classes.serviceItem} ${classes.active}`}><CheckIcon className={classes.serviceIcon} />お迎え</div>
                            <div className={`${classes.serviceItem} ${classes.active}`}><CheckIcon className={classes.serviceIcon} />ご安置</div>
                            <div className={`${classes.serviceItem} ${classes.active}`}><CheckIcon className={classes.serviceIcon} />通夜式</div>
                            <div className={`${classes.serviceItem} ${classes.active}`}><CheckIcon className={classes.serviceIcon} />告別式</div>
                            <div className={`${classes.serviceItem} ${classes.active}`}><CheckIcon className={classes.serviceIcon} />火葬</div>
                        </div>
                    </div>
                    {false && <div className={classes.price}>
                        <div className={classes.num}>
                            <span>50,000</span>
                        </div>
                        <div className={classes.tax}>
                            <small>税抜</small>
                            <span>円</span>
                        </div>
                    </div>}
                    <div className={classes.buttons}>
                        <div className={classes.addButton} onClick={handleAddButtonClick}>依頼リストに追加</div>
                        <div className={classes.requestButton} onClick={handleRequestButtonClick}>今すぐ依頼</div>
                    </div>
                </div>
            </div>

            {/* Basic Info */}
            <div className={classes.basicInfo}>
                <h2>基本情報</h2>
                <div className={classes.info}>{temple.寺院紹介文}</div>
                <table className={classes.basicInfoTable}>
                    <tbody>
                        {basicInfo.map((item, index) => (
                            <tr key={index}>
                                <td>{item.label}</td>
                                <>
                                    {Array.isArray(item.value) ? (
                                        <td className={classes.basicInfoTableOther}>
                                            <table>
                                                <tbody>
                                                    {item.value.map((subItem, subIndex) => (
                                                        <tr key={subIndex}>
                                                            <td>{subItem.label}</td>
                                                            <td>{subItem.value}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    ) : (
                                        <td>{item.value}</td>
                                    )}
                                </>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br /><br />
            <Footer />
        </div>
    );
};

export default Temple;