import classes from './ShopDetail.module.scss';
import Footer from "./Footer";
import { useEffect } from "react";
import NetImg from './lib/NetImg';
import { useHistory } from 'react-router-dom';
import Iframe from 'react-iframe';

const ShopDetail = (props) => {

    
    const history = useHistory()



    useEffect(()=>{
        
        // if(props.menus){
        //     setMenuList(props.menus.map((item, index) => ({
        //         name: item.name,
        //         desc: `${item.price}円`,
        //         icon: item.icon,
        //         noLink: true,
        //         srcData: item,
        //         onClick: ()=>{
        //             setShowMenuPopup(true)
        //             setSelectMenu(item)
        //         },
        //         children: item.desc ? <div className={classes.menu_desc}>{item.desc}</div> : null,
        //     })))
        // }

        // if (props.links) {
        //     setLinks(props.links.filter(item => item !== null)) // null は除外
        // }

        // if(props.sns){
        //     setSnsLinks(Object.keys(props.sns).map(key => ({type:key, url:props.sns[key]})).filter(item => item !== null && item.url)) // null は除外
        // }

        // // 営業時間
        // setTimeText(getBusinessHourText(props))

        // // 月〜日までの営業時間
        // const _allTimeText = []
        // for(let n = 1; n <= 7; n++){
        //     console.log(n, n % 7)
        //     _allTimeText.push(`${dayjs().startOf('week').add(n, 'day').format('ddd')} ${getBusinessHourText(props, n % 7)}`)
        // }
        // setAllTimeText(_allTimeText.join('\n'))

        
        setTimeout(()=>{
            // className rootを一番上までスクロール
            const root = document.querySelector(`.${classes.root}`)
            root.scrollTop = 0
        }, 100)


    }, [props])

    // カレンダーの日付を設定
    // useEffect(()=>{
    //     const _calendars = []
    //     for (let n = -1; n <= 1; n++) {
    //         _calendars.push(dayjs().add(calendarIndex + n, 'week').toDate())
    //     }
    //     setCalendars(_calendars)

    //     carouselRef.current.setSlide(1)

    // }, [calendarIndex])

    // const afterChange = (currentIndex, afterIndex) => {
        
    //     setCalendarIndex(afterIndex + calendarIndex - 1)
    // }

    
    

    return (
        <div className={classes.root}>
            
            <br />
            <br />
            <div className={classes.header} onClick={()=>{
                console.log(props.popup_mode)
                if(!props.popup_mode) return
                history.push(`/shop/${props.id}?q=test`)
            }}>

                <div className={classes.text}>
                    <div className={classes.name}>{props.name}</div>
                    <div className={classes.desc}>{Array.isArray(props.cate) ? props.cate.join('、') : props.cate}</div>
                </div>
            
                {props.payment_methods && <div className={classes.payment_methods}>
                    {props.payment_methods.map((item, index)=><div key={index} className={classes.item}>{item}</div>)}
                </div>}
                <div className={classes.photo}>
                    <NetImg className={classes.img} src={'/profile/0.jpg'} alt="shop" />
                </div>
                <div className={classes.thumbs}>
                    <NetImg className={classes.img} src={'/profile/0.jpg'} alt="shop" />
                    <NetImg className={classes.img} src={'/profile/1.jpg'} alt="shop" />
                    <NetImg className={classes.img} src={'/profile/2.jpg'} alt="shop" />
                    <NetImg className={classes.img} src={'/profile/3.jpg'} alt="shop" />
                </div>

                <div className={classes.infotext}>
                浄光寺では規模やご予算に応じて「本堂」、
                本堂地下「礼拝堂」の2会場で葬儀を執り行うことが
                できます。<br />
                どちらも必要以上に飾るのではなくシンプルかつ
                できるだけ負担の少ない葬儀を心がけています。
                </div>
            </div>

            {props.address && <div className={classes.area}>
                
                <div className={classes.title}>所在地</div>
                <div className={classes.desc}>
                    {props.address}
                </div>
                <div className={classes.map}>
                    <Iframe
                        src={`https://maps.google.co.jp/maps?output=embed&q=${props.address}`}
                        width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="eager" />
                </div>

            </div>}


            {props.tel && <div className={classes.contact}>
                
                <div className={classes.title}>連絡先</div>

                <div className={classes.text}>
                    {props.tel}
                </div>
            </div>}


            <div className={classes.plan}>
                
                <div className={classes.title}>プラン</div>

                <img src="/profile/plan.png" alt="プラン" />
            </div>

            <br /><br />

            <div className={classes.info}>
                
                <div className={classes.title}>基本情報</div>

                <img src="/profile/info.png" alt="基本情報" />
            </div>


            <br />
            <br />

            {/* <div className={classes.popular}>
                <div className={classes.title}>
                    人気の商品
                </div>
                <div className={classes.list}>
                    {menuList.map((item, index)=><div key={index} className={classes.item} onClick={()=>{
                        setShowMenuPopup(true)
                        setSelectMenu(item.srcData)
                    }}>
                        <NetImg className={classes.icon} src={item.icon || '/profile/food-ico.svg'} alt="icon" />
                        <div className={classes.text}>
                            <div className={classes.title}>{item.name}</div>
                            <div className={classes.desc}>{item.desc}</div>
                        </div>
                    </div>)}
                </div>
            </div> */}

            {/* <div className={classes.menulist}>

                <ListView datas={menuList} />

            </div> */}

            {props.pr_video && <div className={ classes.pr_video }>
                <video src={props.pr_video} playsInline autoPlay muted loop controls></video>
            </div>}
            
            {/* {props.address && <div className={ classes.goto_bt }><a onClick={handleClickGotoLink} href={`https://maps.google.com/maps?q=${ props.address }`} >ここへ行く</a></div>} */}
            
            {/* {sns_links.length > 0 && <div className={classes.sns_links}>
                
                {sns_links.map((item, index) => {
                    return (<a href={item.url} className={classes.item} key={index}>
                        <img src={`/icon/${item.type}.svg`} alt="ico" />
                    </a>)
                })}
            </div>} */}


            <div className={classes.links}>
                
                    <a href="https://jhokoji.net/" className={classes.item}>
                        <img src="/icon/web.svg" alt="ico" />https://jhokoji.net/
                    </a>
                
            </div>

            <div className={classes.fog} />

            
            {/* <Dialog
                open={ showCalendarPopup }
                onClose={()=>setShowCalendarPopup(false)}
                className={classes.calendar_popup}
            >

                {selectedCalendarDate.date && <div className={classes.date}>{selectedCalendarDate && dayjs(selectedCalendarDate.date.toDate()).format("MM月DD日")}</div> }
                {selectedCalendarDate.name && <div className={classes.name}>{selectedCalendarDate.name}</div>}
                {selectedCalendarDate.address && <div className={classes.address}>{selectedCalendarDate.address}</div>}
                {selectedCalendarDate.time && <div className={classes.time}>{selectedCalendarDate.time}</div>}
                {selectedCalendarDate.desc && <div className={classes.desc}>{selectedCalendarDate.desc}</div>}
                {selectedCalendarDate.url && <a className={classes.url} href={selectedCalendarDate.url}>{selectedCalendarDate.url}</a>}
                <br />
                <Button onClick={()=>setShowCalendarPopup(false)} variant='outlined' fullWidth>閉じる</Button>
            </Dialog>
            

            <Dialog
                open={ showMenuPopup }
                onClose={()=>setShowMenuPopup(false)}
                className={classes.menu_popup}
            >
                <div className={classes.header}>
                    <img src={selectMenu.icon} className={classes.icon} alt="" />
                    <div>
                        <div className={classes.title}>{selectMenu.name}</div>
                        <div className={classes.prie}>{selectMenu.price}円</div>
                    </div>
                </div>

                <div className={classes.text}>
                    {selectMenu.desc}
                </div>
                
                
            </Dialog> */}

            
            {!props.popup_mode && <Footer />}
        </div>
    );
};

export default ShopDetail;