import SearchList from './SearchList';
import { Typography, Box } from '@mui/material';
const Search = (props) => {
    
    return <Box sx={{ overflow: 'scroll', height: '100vh'}}>
        <br />
        <Typography variant="h4" align="center" fontWeight={900}>検索結果</Typography>
        <SearchList />
    </Box>
};

export default Search;