import { useEffect, useRef, useState, useCallback, } from "react";
import algoliasearch from 'algoliasearch/lite'
import classes from './ShopSearch2.module.scss';
import LoadingScreen from "./lib/LoadingScreen";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useGeocodeContext } from "../context/lib/GeocodeContext";
import sleep from "./lib/sleep";
const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
)
const algoliaIndex = algoliaClient.initIndex("profile")
const shopHoursIndex = algoliaClient.initIndex("shop_hours")


const ShopSearch = ({stateCheck, onCallBack, searchWord, searchState, onFocus, onBlur, searchCategory}) => {

    const inputRef = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [isShowCloseBt, setIsShowCloseBt] = useState(false)
    const initalRef = useRef(true)
    const [shouldSearch, setShouldSearch] = useState(true);
    const [_searchWord, _setSearchWord] = useState(null)
    const [_searchCategory, _setSearchCategory] = useState(null)
    const { geocode, updateGps, } = useGeocodeContext()
    const [updateGpsTime, setUpdateGpsTime] = useState(null)
    
    /* -----------------------------------------------
    * handleSearch
    ----------------------------------------------- */
    const handleSearch = useCallback(async (evn) => { // useCallbackでラップ

        console.log(evn.type, evn.keyCode)

        if(isLoading) return

        if(evn.type !== "blur" && evn.keyCode !== 13) return
        setShouldSearch(false)

        setIsLoading(true)
        // 一旦フォーカスを外す
        inputRef.current.blur()

        var date = new Date()
        // algolia検索
        const option = {}
        if(geocode) {
            option.aroundLatLng = geocode
            option.aroundRadius = 200000 // 検索結果を200kmで絞る(メートルで指定)
        }
        
        // カテゴリで絞り込み
        let result = null
        if(evn.cate !== null && (evn.cate || _searchCategory)) {
            const cate = evn.cate || _searchCategory
            console.log(cate)
            if(cate === "営業中"){
                
                // 指定曜日の営業中の店を検索
                var currentMinutes = date.getHours() * 60 + date.getMinutes(); // 現在時刻を分で計算
                const day = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][new Date().getDay()];
                const shopHoursResult = await shopHoursIndex.search(inputRef.current.value, {
                    filters : `time_start <= ${currentMinutes} AND time_end > ${currentMinutes} AND day:"${day}"`,
                    hitsPerPage: 100,
                    ...option
                })
                
                // 重複するshop_idを除去
                const shopIds = [...new Set(shopHoursResult.hits.map(hit => hit.shop_id))];
                const shopFilters = shopIds.map(id => `objectID:${id}`).join(' OR ');

                if(shopIds.length > 0) {
                    // 営業中データから店データを取得
                    result = await algoliaIndex.search(inputRef.current.value, {
                        filters: shopFilters
                    });    
                }else{
                    result = {hits:[]}
                }
                
                
            }else{
                option.filters = `cate:${cate}`
            }
        }

        if(!result) result = await algoliaIndex.search( inputRef.current.value,option)
        console.log(`[${inputRef.current.value}]`, `${new Date().getTime() - date.getTime()}sec`)

        console.log(result.hits)

        const results = []
        for(const hit of result.hits)
        {
            console.log(hit)

            // プロフィールを取得
            // const profileDoc = await getDoc(doc(firebaseDB, FirestoreMap$profiles, hit.objectID))

            // // プロフィールが存在しない場合はスキップ
            // if(!profileDoc.exists()) continue

            // const profileData = profileDoc.data()

            // // geocodeが存在しない場合はスキップ
            // if(!profileData.hasOwnProperty('geocode')) {
            //     console.warn("shop search skip [geocode]", profileData.id, profileData.name)
            //     continue
            // }

            // // stateチェック
            // if(stateCheck && profileData.state !== 'active-test'){
            //     console.warn("shop search skip [state]", profileData.id, profileData.name)
            //     continue
            // }
            

            results.push({
                id: hit.objectID,
                icon: hit.icon || `/profile/shop.png`,
                ...hit
                // ...profileDoc.data()
            })
            // 最大20件まで
            if(results.length >= 20) break
        }

        console.log("results", results)

        // 結果を返す
        onCallBack(results, initalRef.current)
        initalRef.current = false

        setIsLoading(false)
    }, [isLoading, onCallBack, geocode, _searchCategory])

    /* -----------------------------------------------
    * handleCloseBt
    ----------------------------------------------- */
    const handleCloseBt = () => {

        inputRef.current.value = ""
        setIsShowCloseBt(false)
        handleSearch({type: 'blur'})
    }

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        if (shouldSearch) {

            handleSearch({type: 'blur'})
        }
    }, [handleSearch, shouldSearch]) // 依存性の配列にhandleSearchを追加

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        console.log(_searchWord)

        if(!_searchWord) return

        console.log(_searchWord)
        inputRef.current.value = _searchWord === "*" ? "" : _searchWord
        _setSearchWord(null)
        handleSearch({type: 'blur'})

    }, [handleSearch, _searchWord])

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        
        if(!updateGps || updateGpsTime === updateGps) return

        setUpdateGpsTime(updateGps)
        handleSearch({type: 'blur'})

    }, [updateGps, handleSearch, updateGpsTime, ])

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        console.log(searchWord, searchState)

        if(!searchWord) return

        _setSearchWord(searchWord)

        console.log(searchWord)

        setIsShowCloseBt(searchWord !== "" && searchWord !== "*")

    }, [searchWord, searchState, ])

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        console.log(searchCategory)

        
        // if(!_searchCategory && !searchCategory) return
        if(_searchCategory === searchCategory) return

        console.log("setSearchCategory")

        _setSearchCategory(searchCategory)

        console.log(searchCategory)
        handleSearch({type: 'blur', cate: searchCategory})

    }, [searchCategory, _searchCategory, handleSearch])

    

    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return (
        <div className={ `${classes.root}` } style={{display: 'none'}}>
            <LoadingScreen show={isLoading} transparent /> 
            <div className={ classes.searchbox }>
                {!isShowCloseBt && <SearchIcon className={classes.search_icon} />}
                <input
                    placeholder="おにぎり　ジュース　店名　商品名"
                    ref={inputRef}
                    onKeyDown={handleSearch}
                    onChange={()=>setIsShowCloseBt(inputRef.current.value !== "")}
                    onBlur={async ()=>{
                        await sleep(.1)
                        setIsShowCloseBt(false)
                        handleSearch({type: 'blur'})
                    }}
                    onFocus={()=>{
                        setIsShowCloseBt(true)
                        onFocus()
                    }}
                />
                {isShowCloseBt && <CloseIcon className={classes.close_bt} onClick={handleCloseBt} />}
            </div>

        </div>
    )

};

export default ShopSearch;