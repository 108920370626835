import { useEffect, useState, useRef, } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


const ValidateTextField = (props) => {

    const [ inputValue, setInputValue ] = useState("")
    const [ isError, setIsError ] = useState(false)
    const [ errorText, setErrorText ] = useState(false)
    const InputRef = useRef()
    const ref = useRef()
    

    useEffect(()=>{
        
        if(props.defaultValue === undefined)
            return
        
        setInputValue(props.defaultValue)

    }, [props.defaultValue])

    return (
        
        <TextField
            name={ props.name }
            label={ props.label }
            // defaultValue={ inputDefaultValue }
            value={ inputValue }
            inputRef={InputRef}
            type={ props.type }
            inputProps={ props.inputProps }
            sx={{ width: '100%', mb: 2, ...props.sx }}
            error={ isError }
            helperText={ errorText }
            InputProps={ props.InputAdornment ? {
                startAdornment: props.InputAdornment.position === "start" ? <InputAdornment position={props.InputAdornment.position}>{props.InputAdornment.label}</InputAdornment> : undefined,
                endAdornment: props.InputAdornment.position === "end" ? <InputAdornment position={props.InputAdornment.position}>{props.InputAdornment.label}</InputAdornment> : undefined,
            } : undefined}
            multiline={ props.multiline }
            rows={ props.rows }
            minRows={ props.minRows }
            variant={props.variant}
            onChange={ ()=>{
                setErrorText(InputRef?.current?.validationMessage)
                setIsError(!InputRef?.current?.validity?.valid)
                setInputValue(InputRef?.current?.value)
                if(props.onChange)
                    props.onChange(InputRef?.current?.value)
            }}
            onBlur={ ()=>{
                if(props.onBlur)
                    props.onBlur()
            } }
            onFocus={ ()=>{
                
            } }
            onClick={ ()=> {


                if(props.onClick)
                    props.onClick()
            }}
            ref={ ref }
            placeholder={props.placeholder}
            className={props.className}
            />
                    
    );
};

export default ValidateTextField;