import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import classes from './HomeMenu.module.scss'
import { useEffect, useState, } from "react"
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useAuthContext } from '../context/lib/AuthContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { doc, getDoc } from 'firebase/firestore';
import { firebaseDB } from './lib/firebase';
import { FirestoreMap$users, FirestoreMap$users_readonly } from './lib/FirestoreMap';
import FixedLayout from './lib/FixedLayout';
import PersonIcon from '@mui/icons-material/Person';


const HomeMenu = ({ component: Component, ...rest }) => {
    
    
    const { user } = useAuthContext();
    const [userId, setUserId] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isShowMenu, setIsShowMenu] = useState(false)


    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined) return

        const init = async ()=>{

            //ログインしていない時
            if(user.current === null) return

            setUserId(user.current.uid)

            // 読み取り専用データを取得
            const userDoc = await getDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readonly, user.current.uid))
            if(!userDoc.exists()) return

            const userData = userDoc.data()
            if(userData === undefined) return

            setIsAdmin(userData.isAdmin)
        }
        init()

        
    }, [user])

    // ブラウザチェック
    const handleCheckBrowser = (e)=>{
        const ua = window.navigator.userAgent.toLowerCase().trim();

        // アプリでは無い時
        if(!ua.includes('collabo-app-browser')){
            
            if(ua.includes("iphone")){
                e.preventDefault();
                window.location.href="https://apps.apple.com/us/app/eat-plus-%E3%82%A4%E3%83%BC%E3%83%88%E3%83%97%E3%83%A9%E3%82%B9/id6450914531"
            } else {
                // alert("Android版ただいま準備中です、お楽しみに！")
            }
        }
    }

    return (
        
        <FixedLayout>
            <div className={`${classes.menu} ${isShowMenu ? classes.show : classes.hide}`} onClick={()=>setIsShowMenu(!isShowMenu)}>
                <div className={classes.list}>
                    {userId && <>
                    <Link to={`/shop/${userId}?q=test&single=1`}><StorefrontIcon />マイショップ</Link>
                    <Link to="/profile/edit"><AccountCircleIcon />編集</Link>
                    <Link to="/account/edit"><AccountCircleIcon />アカウント</Link>
                    { isAdmin && <Link to="/admin/shoplist"><AdminPanelSettingsIcon />管理者</Link> }
                    </>}
                    {!userId && <Link to={`/authlogin`} onClick={handleCheckBrowser}><AccountCircleIcon />店舗様入り口</Link> }

                </div>
                <div className={`${classes.box}`}>
                    <PersonIcon className={classes.ico} />
                </div>
                <div className={classes.bg} />
            </div>
        </FixedLayout>
    );
};

export default HomeMenu;