
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useRef, useState } from 'react';

const NetImg = (props) => {


    const imgRef = useRef()
    const [currentUrl, setCurrentUrl] = useState()

    useEffect(()=>{

        if(!imgRef.current)
            return

        imgRef.current.addEventListener('error', (evn)=>{

            setCurrentUrl("/profile/photo-ico.png")
        })
        
    }, [imgRef, props.src])


    useEffect(()=>{

        setCurrentUrl(props.src)

    }, [props.src])


    if(props.src){
        return <img className={props.className} src={ currentUrl } alt={props.alt || "NetImg"} ref={imgRef} onClick={props.onClick} />
    }else{
        return <Skeleton className={props.className} />
    }
}

export default NetImg