
import React, { useState, useEffect, useRef, } from "react";
import classes from './ModalBottomSheet.module.scss';
import {Animator} from './Animator';



const ModalBottomSheet = (props) => {

    const[rootAnimate, setRootAnimate] = useState({ opacity: 0, })
    const[boxAnimate, setBoxAnimate] = useState({ transform_translateY: '100%', })
    const[isClose, setIsClose] = useState(false)
    const rootRef = useRef()
    
    /* ----------------------------------------
    * handleBackClick
    * 背面をクリック
    ----------------------------------------*/
    const handleBackClick = (evn)=>{

        if(evn.target.className !== classes.root)
            return

        setRootAnimate({ opacity: 0, })
        setBoxAnimate({ transform_translateY: '100%', })

        if(props.onBackClick)
            props.onBackClick()
            
        setIsClose(true)
    }


    /* ----------------------------------------
    * handleAnimateComplete
    * アニメーション終了
    ----------------------------------------*/
    const handleAnimateComplete = ()=>{

        if(isClose && props.onClosed)
            props.onClosed()
    }


    /* ----------------------------------------
    * handleBackBt
    ----------------------------------------*/
    useEffect(()=>{

        if(props.show)
            return

            console.log("hide")
        setRootAnimate({ opacity: 0, })
        setBoxAnimate({ transform_translateY: '100%', })

        setIsClose(true)

    }, [props.show])

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{

        console.log("effect", props.show)

        if(!props.show)
            return

        //開始アニメーションを設定
        setRootAnimate({ opacity: 1, })
        setBoxAnimate({ transform_translateY: '0%', })
        setIsClose(false)

        //スクロールを戻す
        rootRef.current.scrollTop = 0

    },[props.show])

    /* ----------------------------------------
    * レンダリング全体
    ----------------------------------------*/
    return (
        <Animator id={props.id} className={ `${props.isComplete ? `${classes.root} ${classes.hide}` : classes.root} ${props.className}` } animate={ rootAnimate } transitionDuration={300} onClick={ handleBackClick } onComplete={ handleAnimateComplete } ref2={rootRef}>
            <Animator className={ classes.box } animate={ boxAnimate } transitionDuration={300}>

                {props.children}

            </Animator>
        </Animator>
    );
};

export default ModalBottomSheet;