import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { appLib } from './lib/_app_lib';
import { Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '100vh'
};




const MapRoute = (props) => {
    
    const [directions, setDirections] = useState(null);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [center, setCenter] = useState();
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const history = useHistory()
    const [durationText, setDurationText] = useState('')

    useEffect(() => {


        (async () => {

            const location = await appLib.GetLocation()
            
            if(location.status){
                setCenter({lat:location.pos[0], lng:location.pos[1]})
                setStart({lat:location.pos[0], lng:location.pos[1]})
                setEnd(props.match.params.address)
                setDirectionsResponse('FETCHING');
            }

        })()
        
    }, [props.match.params.address])

    const directionsCallback = response => {
      if (response !== null) {
        if (response.status === 'OK') {
            const duration = response.routes[0].legs[0].duration;
            console.log('Estimated duration:', duration.text);
            setDurationText(duration.text)
          setDirections(response);
        } else {
          console.error('response: ', response);
        }
        setDirectionsResponse(null);
      }
    };
  
  
    return (
      <div>

        <Box sx={{ position: 'absolute', top: '10px', left: '10px', zIndex: 9999, background: 'white', padding: 1, borderRadius: 100, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }} onClick={()=>history.goBack()}><ArrowBackIosNewIcon /></Box>
        <Box sx={{ textAlign:'center', position: 'absolute', bottom: '50px', left: '10px', right: '10px', zIndex: 9999, background: '#0096f7', color:'#fff', fontSize:'1.5em', fontWeight:900, padding: 2, borderRadius: 1, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>
            所要時間：{durationText}
        </Box>

        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} options={{
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    rotateControl: false,
    scaleControl: false
  }}>
            {
              directionsResponse === 'FETCHING' && (
                <DirectionsService
                  // required
                  options={{ // google.maps.DirectionsRequest object
                    destination: end,
                    origin: start,
                    travelMode: 'DRIVING',
                    region: '.jp',
                  }}
                  // required
                  callback={directionsCallback}
                />
              )
            }
  
            {
              directions && (
                <DirectionsRenderer
                  // required
                  options={{ // google.maps.DirectionsRendererOptions object
                    directions: directions
                  }}
                />
              )
            }
          </GoogleMap>
        </LoadScript>
        
      </div>
    );
};

export default MapRoute;