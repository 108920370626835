import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from './ShopListAdmin.module.scss';
import Footer from "./Footer";
import { Link, useHistory, useLocation, } from "react-router-dom";
import ListView, { ListViewItem } from "./ListView";
import { useEffect, useRef, useState } from "react";
import { collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, startAfter, } from "firebase/firestore";
import { firebaseDB } from "./lib/firebase";
import { FirestoreMap$profiles, FirestoreMap$users, FirestoreMap$users_readonly } from "./lib/FirestoreMap";
import { InfiniteScrollVertical } from "./lib/infiniteScrollVertical";
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, } from '@mui/material/CircularProgress';
import { useAuthContext } from "../context/lib/AuthContext";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import LoadingScreen from "./lib/LoadingScreen";
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ProfileEdit from "./lib/ProfileEdit";
import queryString from 'query-string';
import { DialogContent } from "@mui/material";
import ValidateTextField from "./lib/ValidateTextField";
import { firebaseFunctions, } from "./lib/firebase";
import { httpsCallable } from "firebase/functions";
import AddIcon from '@mui/icons-material/Add';
import FloatingActionButton from "@mui/material/Fab";
import TextField from '@mui/material/TextField';
import Temple from "./Temple";
const functions = {
    adminAuthMailChange : httpsCallable(firebaseFunctions, 'adminAuthMailChange'),
}

const ShopListAdminView = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const isRequesting = useRef(false)
    const shopList = useRef([])
    const [listState, setListState] = useState(0)
    const currentDocId = useRef()
    const { user } = useAuthContext();
    const history = useHistory()
    const [showApprovalConfirm, setShowApprovalConfirm] = useState(false)
    const [showBanConfirm, setShowBanConfirm] = useState(false)
    const selectItem = useRef({})
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [showAuthChangeConfirm, setShowAuthChangeConfirm] = useState(false)
    const authChangeContainerRef = useRef()
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [code, setCode] = useState('');

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined) return

        const _failed = () => {
            alert("権限がありません")
            history.push("/")
        }

        const _init = async () => {
            

            if(isRequesting.current) return
            setIsLoading(true)
            isRequesting.current = true

            // 認証チェック
            if(user.current === null) return _failed()
            const userDoc = await getDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readonly, user.current.uid))
            if(!userDoc.exists()) return _failed()
            const userData = userDoc.data()
            if(userData === undefined || !userData.isAdmin) return _failed()


            // プロフィールを取得
            const profileRef = collection(firebaseDB, FirestoreMap$profiles)

            const queryList = [
                profileRef,
                orderBy("寺院名"),
                limit(10),
            ]

            // 開始位置がある場合
            if(currentDocId.current)
            {
                console.log('currentDocId=', currentDocId.current)
    
                // //開始のドキュメントを取得
                const startDoc = await getDoc(doc(profileRef, currentDocId.current));
                

                //開始位置からデータを取得する
                queryList.push(startAfter(startDoc))
            }
            
            //ニュースを取得する
            const profileSnapshot = await getDocs(
                query(
                    ...queryList
                )
            )

            
            for(const profileDoc of profileSnapshot.docs)
            {
                const data = profileDoc.data()

                var state = ""
                switch(data.state)
                {
                    case "active-test":
                        state = "済"
                        break
                    case "unsubscribe":
                        state = "辞"
                        break
                    default:
                        state = data.status === "active" ? "仮" : "未"
                        break
                }

                shopList.current.push({
                    id: profileDoc.id,
                    name: `${data.寺院名}`,
                    state_ja: state,
                    desc: data.宗派,
                    icon: profileDoc.data().icon || `/shop/${profileDoc.id}/shop.jpg`,
                    url: "https://test.com",
                    to: `/shop/${data.コード}?q=test`,
                    noLink: true,
                    isApp: data.isApp,
                })

                // 続き用の開始データを保持
                currentDocId.current = profileDoc.id
            }

            setIsLoading(false)
            isRequesting.current = false

        }
        _init()



    },[listState, history, user, ])

    /* -----------------------------------------------
    * handleItemClick
    ----------------------------------------------- */
    const handleItemClick = (evn, item) => {
        
        // ボタンの時
        if(evn.target.classList.contains(classes.listview_item))
        {
            props.onSelectShop(item.to || item.href)
        }
        // 承認ボタン
        else if(evn.target.classList.contains(classes.approval_bt))
        {
            selectItem.current = item
            setShowApprovalConfirm(true)
        }
        // 非承認ボタン
        else if(evn.target.classList.contains(classes.ban_bt))
        {
            selectItem.current = item
            setShowBanConfirm(true)
        }
        // アカウント変更ボタン
        else if(evn.target.classList.contains(classes.auth_change_bt))
        {
            selectItem.current = item
            setShowAuthChangeConfirm(true)
        }
        // 編集ボタン
        else if(evn.target.classList.contains(classes.edit_bt))
        {
            props.onEditShop(item.id)
        }
        // 削除ボタン
        else if(evn.target.classList.contains(classes.delete_bt))
        {
            selectItem.current = item
            setShowDeleteConfirm(true)
        }
    }
    /* -----------------------------------------------
    * handleApproval
    ----------------------------------------------- */
    const handleApproval = async () => {

        setShowApprovalConfirm(false)
        setIsProcessing(true)
        try{
            await setDoc(doc(firebaseDB, FirestoreMap$profiles, selectItem.current.id), {
                    state: "active-test",
                    approvalDate: serverTimestamp(),
                }, { merge: true }
            )
            alert("承認しました")

            // 再読み込み
            window.location.reload()

        }catch(e){
            alert("承認に失敗しました")
            console.log(e)
        }
        setIsProcessing(false)
    }

    /* -----------------------------------------------
    * handleBan
    ----------------------------------------------- */
    const handleBan = async () => {

        setShowBanConfirm(false)
        setIsProcessing(true)
        try{
            await setDoc(doc(firebaseDB, FirestoreMap$profiles, selectItem.current.id), {
                    state: "pending",
                    banDate: serverTimestamp(),
                }, { merge: true }
            )
            alert("BANしました")

            // 再読み込み
            window.location.reload()

        }catch(e){
            alert("BANに失敗しました")
            console.log(e)
        }
        setIsProcessing(false)
    }

    /* -----------------------------------------------
    * handleDelete
    ----------------------------------------------- */
    const handleDelete = async () => {
            
        setShowDeleteConfirm(false)
        setIsProcessing(true)
        try{
            await deleteDoc(doc(firebaseDB, FirestoreMap$profiles, selectItem.current.id))
            alert("削除しました")

            // 再読み込み
            window.location.reload()

        }catch(e){
            alert("削除に失敗しました")
            console.log(e)
        }
        setIsProcessing(false)

    }

    /* -----------------------------------------------
    * handleAuthChange
    ----------------------------------------------- */
    const handleAuthChange = async () => {

        setShowAuthChangeConfirm(false)
        setIsProcessing(true)
        try{
            const result = await functions.adminAuthMailChange({
                "email": authChangeContainerRef.current.querySelector(`input[name="email"]`).value,
                uid: selectItem.current.id,
            });
            console.log(result)
            if(result.data.success)
            {
                alert("メールを送信しました")
            }else{
                alert("エーが発生しました\n" + result.data.message + "\n" + result.data.error_code)
            }

        }catch(e){
            alert("失敗しました")
            console.log(e)
        }
        setIsProcessing(false)
    }

    const handleAddClick = () => {
        setShowAddDialog(true);
    };

    const handleAddDialogClose = () => {
        setShowAddDialog(false);
    };

    const handleAddDialogSubmit = () => {
        // "コード" の処理をここに追加
        console.log("コード:", code);
        setShowAddDialog(false);
    };

    return (
        <div className={classes.root}>


            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>ShopList | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            <LoadingScreen isLoading={isProcessing} />


            <InfiniteScrollVertical
                throttle={150}
                threshold={300}
                hasMore={ true }
                onLoadMore={()=>{
                    
                    console.log("onLoadMore")
                    setListState(listState+1)
                }}
                className={ classes.scroller }
                // scrollParamCounter={ 0 }
            >
                <Link to="/" className={classes.logo}>
                    <img src="/logo.svg" alt="logo" />
                </Link>

                { !isLoading && shopList.current.length === 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '30%', alignItems: 'center' }}>
                        <div style={{ height: 'fit-content' }}>まだお知らせはありま��ん。</div>
                    </Box>
                }

                {/* 読み込み中下からにょきっとロード */}
                <Box sx={{
                        position: 'fixed',
                        left:0, right:0,
                        bottom: isLoading ? 50 : 0,
                        margin: 'auto',
                        zIndex: 10000,
                        width: 'fit-content',
                        backgroundColor: '#fff',
                        borderRadius: 1000,
                        padding: '5px',
                        boxShadow: '0 6px 10px rgb(0 0 0 / 15%)',
                        transition: 'all 0.2s',
                        opacity: isLoading ? 1.0 : .0,
                        pointerEvents: 'none',
                    }}>
                        <CircularProgress sx={{
                            animationDuration: '550ms',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }} />
                </Box> 
                
                <div className={classes.menulist}>

                    <ListView>
                        {shopList.current.map((item, index) => (
                            <ListViewItem className={classes.listview_item} key={index} {...item} onClick={evn=>handleItemClick(evn, item)}>
                                <div className={classes.platform}>{ item.isApp ? <PhoneIphoneIcon /> : < LanguageIcon /> }</div>
                                <div className={classes.sales}><span className={classes.open}>営業中</span>・営業終了18:00</div>
                                <div className={classes.action_bt}>
                                    {/* {item.state_ja === "未" && <Button className={classes.approval_bt}>承認</Button>} */}
                                    {/* {item.state_ja !== "未" && <Button className={classes.ban_bt}>BAN</Button>} */}
                                    <Button className={classes.edit_bt}>編集</Button>
                                    {/* <Button className={classes.auth_change_bt}>アカウント変更</Button> */}
                                    <Button className={classes.delete_bt} color="error">削除</Button>
                                </div>
                            </ListViewItem>
                        ))}
                    </ListView>

                </div>

                <Footer />
            </InfiniteScrollVertical>


            <Dialog
                open={ showAuthChangeConfirm }
                onClose={ () => setShowAuthChangeConfirm(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle>
                    アカウントのメールアドレス変更
                </DialogTitle>
                <DialogContent>
                    <div ref={authChangeContainerRef}>
                        {selectItem.current.name}<br />
                        <ValidateTextField
                            name={`email`}
                            type='email'
                            placeholder="メールアドレス"
                            />
                            <br />
                        メールアドレスにログインメールを送信します
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowAuthChangeConfirm(false)}>キャンセル</Button>
                    <Button onClick={handleAuthChange} color="error" >変更して送信</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showApprovalConfirm }
                onClose={ () => setShowApprovalConfirm(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {selectItem.current.name}を承認しますか？
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setShowApprovalConfirm(false)}>キャンセル</Button>
                    <Button onClick={handleApproval} color="error" >承認</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showBanConfirm }
                onClose={ () => setShowBanConfirm(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {selectItem.current.name}をBANしますか？
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setShowBanConfirm(false)}>キャンセル</Button>
                    <Button onClick={handleBan} color="error" >BAN</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ showDeleteConfirm }
                onClose={ () => setShowDeleteConfirm(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {selectItem.current.name}を削除しますか？
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=>setShowDeleteConfirm(false)}>キャンセル</Button>
                    <Button onClick={handleDelete} color="error" >削除</Button>
                </DialogActions>
            </Dialog>

            <FloatingActionButton
                color="primary"
                aria-label="add"
                style={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleAddClick}
            >
                <AddIcon />
            </FloatingActionButton>

            <Dialog open={showAddDialog} onClose={handleAddDialogClose}>
                <DialogTitle>コードを入力</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="コード"
                        type="text"
                        fullWidth
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddDialogClose}>キャンセル</Button>
                    <Button onClick={handleAddDialogSubmit} color="primary">追加</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};


const ShopListAdmin = (props) => {

    const [shopData, setShopData] = useState()
    const [editShopId, setEditShopId] = useState()
    const current_route_path = useLocation();
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSelectShop = (shopUrl) => {
        console.log(shopUrl)
        setEditShopId(null)
        const matches = shopUrl.matchAll(/\/shop\/(.*?)\?/g)
        let docId = ""
        for(const match of matches){
            docId = match[1]
        }

        (async ()=>{
            const profileDoc = await getDoc(doc(firebaseDB, FirestoreMap$profiles, docId))
            setShopData({id:profileDoc.id, ...profileDoc.data()})
        })()
    }

    const handleEditShop = (shopId) => {
        setEditShopId(shopId)
        setShopData(null)
    }

    useEffect(()=>{

        // urlのqueryにshopIdがある場合
        const query = queryString.parse(current_route_path.search)
        if(query.shopId)
        {
            handleEditShop(query.shopId)
        }

    },[current_route_path.search])

    const handleAddDialogClose = () => {
        setShowAddDialog(false);
    };

    const handleAddDialogSubmit = async () => {
        // "コード" の処理をここに追加
        console.log("コード:", code);
        setShowAddDialog(false);

        setIsLoading(true)
        await setDoc(doc(firebaseDB, FirestoreMap$profiles, code), {
            コード: code,
        });
        setIsLoading(false)
        handleEditShop(code)
    };

    return (
        <div className={classes.layout}>
            <LoadingScreen show={isLoading}/>
            <ShopListAdminView onSelectShop={handleSelectShop} onEditShop={handleEditShop} />
            <div className={classes.detail}>
                {shopData && <Temple {...shopData}/>}
                {editShopId && <ProfileEdit shopId={editShopId} />}
            </div>
            <FloatingActionButton
                color="primary"
                aria-label="add"
                style={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => {
                    setShowAddDialog(true);
                }}
            >
                <AddIcon />
            </FloatingActionButton>
            <Dialog open={showAddDialog} onClose={handleAddDialogClose}>
                <DialogTitle>コードを入力</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="コード"
                        type="text"
                        fullWidth
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddDialogClose}>キャンセル</Button>
                    <Button onClick={handleAddDialogSubmit} color="primary">追加</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ShopListAdmin;