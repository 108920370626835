import { forwardRef } from 'react'
import TextField from '@mui/material/TextField';
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input'



const PhoneInput2 = styled(PhoneInput)(({ theme }) => ({

    '.MuiInputLabel-root': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
    },
    input: {
        padding: '16.5px 14px',
    }

}));

const CustomPhoneNumber = forwardRef((props, ref) => {

    return (

        <TextField
            {...props}
            InputProps={{

            }}
            inputRef={ref}
            fullWidth
            size='small'
            label='電話番号'
            variant='outlined'
            name='phone'
        />
    )
})

const phoneInput = (props) => {


    return (
        <PhoneInput2
            {...props}
            
            placeholder='電話番号'

            defaultCountry="JP"
            
            inputComponent={CustomPhoneNumber}
        />
    )
}

export default phoneInput