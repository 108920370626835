import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from './ShopList.module.scss';
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ListView from "./ListView";
import { useEffect, useRef, useState } from "react";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { firebaseDB } from "./lib/firebase";
import { FirestoreMap$profiles } from "./lib/FirestoreMap";
import { InfiniteScrollVertical } from "./lib/infiniteScrollVertical";
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, } from '@mui/material/CircularProgress';


const ShopList = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const isRequesting = useRef(false)
    const shopList = useRef([])
    const [listState, setListState] = useState(0)
    const currentDocId = useRef()


    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{



        const _init = async () => {
            

            if(isRequesting.current) return
            setIsLoading(true)
            isRequesting.current = true


            // プロフィールを取得
            const profileRef = collection(firebaseDB, FirestoreMap$profiles)

            const queryList = [
                profileRef,
                orderBy("name"),
                where("status", "==", "active"),
                limit(10),
            ]

            // 開始位置がある場合
            if(currentDocId.current)
            {
                console.log('currentDocId=', currentDocId.current)
    
                // //開始のドキュメントを取得
                const startDoc = await getDoc(doc(profileRef, currentDocId.current));
                

                //開始位置からデータを取得する
                queryList.push(startAfter(startDoc))
            }
            
            //ニュースを取得する
            const profileSnapshot = await getDocs(
                query(
                    ...queryList
                )
            )

            
            for(const profileDoc of profileSnapshot.docs)
            {
                const data = profileDoc.data()

                
                shopList.current.push({
                    id: profileDoc.id,
                    name: data.name,
                    desc: data.cate,
                    icon: `/shop/${profileDoc.id}/shop.jpg`,
                    url: data.url,
                })

                // 続き用の開始データを保持
                currentDocId.current = profileDoc.id
            }

            setIsLoading(false)
            isRequesting.current = false

        }
        _init()



    },[listState, ])

    return (
        <div className={classes.root}>


            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>ShopList | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            


            <InfiniteScrollVertical
                throttle={150}
                threshold={300}
                hasMore={ true }
                onLoadMore={()=>{
                    
                    console.log("onLoadMore")
                    setListState(listState+1)
                }}
                className={ classes.scroller }
                // scrollParamCounter={ 0 }
            >
                <Link to="/" className={classes.logo}>
                    <img src="/logo.svg" alt="logo" />
                </Link>

                { !isLoading && shopList.current.length === 0 &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '30%', alignItems: 'center' }}>
                        <div style={{ height: 'fit-content' }}>まだお知らせはありません。</div>
                    </Box>
                }

                {/* 読み込み中下からにょきっとロード */}
                <Box sx={{
                        position: 'fixed',
                        left:0, right:0,
                        bottom: isLoading ? 50 : 0,
                        margin: 'auto',
                        zIndex: 10000,
                        width: 'fit-content',
                        backgroundColor: '#fff',
                        borderRadius: 1000,
                        padding: '5px',
                        boxShadow: '0 6px 10px rgb(0 0 0 / 15%)',
                        transition: 'all 0.2s',
                        opacity: isLoading ? 1.0 : .0,
                        pointerEvents: 'none',
                    }}>
                        <CircularProgress sx={{
                            animationDuration: '550ms',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }} />
                </Box> 
                
                <div className={classes.menulist}>

                    <ListView datas={shopList.current} />

                </div>

                <Footer />
            </InfiniteScrollVertical>

        </div>
    );
};

export default ShopList;