import classes from "./NotificationEdit.module.scss";
import { useEffect, useRef, useState, } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FirestoreMap$users, FirestoreMap$users_readwrite, } from "./FirestoreMap";
import { useAuthContext } from '../../context/lib/AuthContext';
import { doc, setDoc, getDoc, } from "firebase/firestore";
import { firebaseDB, } from "./firebase";
import { useHistory, } from "react-router-dom";
import queryString from 'query-string';
import { logEvent, } from "./firebase";
import { fcmInit } from "./FcmLib";
import { useLocation } from "react-router-dom";
import Switch from '@mui/material/Switch';




const NotificationEdit = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const inputContainerRef = useRef()
    const { user } = useAuthContext();
    const history = useHistory()
    const [backUrl, setBackUrl] = useState('')
    const [readwriteData, setReadwriteData] = useState()
    const current_route_path = useLocation();



    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        //アナリティクス
        logEvent("notication_edit")

    }, [])

    /* -----------------------------------------------
    * useEffect
    * プッシュ通知設定
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined)
            return

        const init = async()=>{
            await fcmInit(user, true)
        }
        init()

    }, [user])

    /* -----------------------------------------------
    * useEffect
    * 設定を取得
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined)
            return

        const init = async()=>{

            const readwriteSnap = await getDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readwrite, user.current.uid))
            
            if(readwriteSnap.exists())
                setReadwriteData(readwriteSnap.data())

            setIsLoading(false)

        }
        init()

    }, [user])

    /* -----------------------------------------------
    * handleSave
    ----------------------------------------------- */
    const handleSave = async ()=>{

        setIsLoading(true)

        //公開データを保存
        {
            const sendData = {}
            inputContainerRef.current.querySelectorAll(`input,textarea`).forEach((elm, index)=>{

                // MUIで生成された不要なフォームはスキップ
                if(!elm.name)
                    return

                var arrayMatch = elm.name.match(/(.*)\[([0-9]+)\](.*)/)

                // 配列の時
                if(arrayMatch)
                {
                    if(!sendData[arrayMatch[1]])
                        sendData[arrayMatch[1]] = []

                    // マップ形式の時
                    if(arrayMatch[3])
                    {
                        if(!sendData[arrayMatch[1]][Number(arrayMatch[2])])
                            sendData[arrayMatch[1]][Number(arrayMatch[2])] = {}

                        sendData[arrayMatch[1]][Number(arrayMatch[2])][arrayMatch[3].replace(/^\./, '')] = elm.type === 'checkbox' ? elm.checked : elm.value
                    } else {
                        sendData[arrayMatch[1]][Number(arrayMatch[2])] = elm.type === 'checkbox' ? elm.checked : elm.value
                    }

                } else {

                    sendData[elm.name] = elm.type === 'checkbox' ? elm.checked : elm.value
                }

            })


            for(const key in sendData)
            {
                // 無視がついた時はフラグを反転する
                if(key.startsWith('ignore_'))
                    sendData[key] = !sendData[key]
            }

            console.log(sendData)
            await setDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readwrite, user.current.uid), sendData, { merge: true })

            
        }

        // setIsLoading(false)
        history.push(backUrl)
    }

    /* -----------------------------------------------
    * handleCancel
    ----------------------------------------------- */
    const handleCancel = async ()=>{

        history.push(backUrl)
    }

    /* -----------------------------------------------
    * useEffect
    * 戻るパス取得
    ----------------------------------------------- */
    useEffect(()=>{
    

        //戻るボタンのパス
        const query_search = queryString.parse(current_route_path.search)
        if(query_search.back)
        {
            //指定のURL
            setBackUrl(query_search.back)
            
        } else {
            //チャットルームリスト
            setBackUrl('/account/edit')
        }
    }, [setBackUrl, current_route_path.search])

    if(isLoading)
        return <Box sx={{
            position: 'fixed', left:0, right:0, top:0, bottom: 0,
            margin: 'auto', zIndex: 10000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff99', }}>
            <CircularProgress />
        </Box>


    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <div className={ classes.root }>


            {isLoading && 
                <Box sx={{
                    position: 'fixed', left:0, right:0, top:0, bottom: 0,
                    margin: 'auto', zIndex: 10000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff99', }}>
                    <CircularProgress />
                </Box>
            }

            <div className={ classes.header }>
                <button className={ classes.back_bt } onClick={ handleCancel }>戻る</button>
                <div className={ classes.title }>
                    通知設定
                </div>
                <button className={ classes.save_bt } onClick={ handleSave }>保存</button>
            </div>

            
            <div className={ classes.scroller } ref={ inputContainerRef }>


            <div className={classes.item} >
                    <div className={classes.label}>毎日更新通知</div>
                    <div className={classes.row}>
                        <div className={classes.text}>
                            AM 7:00 に本日の出店場所を毎日更新してお客様にお店をアピールする為のプッシュ通知
                        </div>
                        <div className={classes.val}>
                            <Switch name="renewalPromptNotification" defaultChecked={readwriteData?.renewalPromptNotification} />
                        </div>
                    </div>
                </div>

            </div>

        </div>
};

export default NotificationEdit;