
import React, { useEffect, } from "react";
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingScreen = ((props) => {

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{
        
    }, [])

    /* ----------------------------------------
    * レンダリング全体
    ----------------------------------------*/
    if(!props.show)
        return (null)

    return ReactDOM.createPortal(

            <Box sx={{
                position: 'fixed', left:0, right:0, top:0, bottom: 0,
                margin: 'auto', zIndex: props.zIndex || 10000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: props.transparent ? '#ffffff00' : '#ffffff99', 
                flexDirection: 'column',
                pointerEvents: props.transparent ? 'none' : 'all',
                }}>
                <CircularProgress />
                {props.children}
            </Box>

    , document.body
    );
    
});


export default LoadingScreen;