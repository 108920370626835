export const FirestoreMap$__servermail = "__servermail"
export const FirestoreMap$users = "users"
export const FirestoreMap$users_readonly = "readonly"
export const FirestoreMap$users_writeonly = "writeonly"
export const FirestoreMap$users_readwrite = "readwrite"
export const FirestoreMap$users_notifications = "notifications"
export const FirestoreMap$users_auth = "auth"
export const FirestoreMap$profiles = "profiles"
export const FirestoreMap$profiles_calendars = 'calendars'
export const FirestoreMap$call_kitchen_car = "call_kitchen_car"
export const FirestoreMap$recruit = "recruit"
export const FirestoreMap$system = 'system'
export const FirestoreMap$system_errors = 'errors'
export const FirestoreMap$system_user_auth = 'user_auth'
