import { createContext, useState, useContext, useCallback} from 'react';

const GeocodeContext = createContext();


export function useGeocodeContext() {
    return useContext(GeocodeContext);
}

export function GeocodeProvider({ children }) {
    const [_geocode, _setGeocode] = useState();
    const [updateGps, setUpdateGps] = useState(false);

    const value = {
        geocode : _geocode,
        setGeocode : useCallback((pos, _updateGps)=>{
            _setGeocode(pos)
            if(_updateGps) setUpdateGps(new Date().getTime())
        }, []),
        updateGps: updateGps,
    };

    return (
        <GeocodeContext.Provider value={value}>
            { children }
        </GeocodeContext.Provider>
    );
}