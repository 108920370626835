import classes from './SearchList.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { firebaseDB, } from './lib/firebase';
import { FirestoreMap$profiles } from './lib/FirestoreMap';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const SearchList = (props) => {
    const [showMore, setShowMore] = useState(true);
    const [list, setList] = useState([]);

    const history = useHistory();
    useEffect(() => {
        let isMounted = true; // アンマウントされていないことを示すフラグ
    
        const fetchData = async () => {
            const docRef = collection(firebaseDB, FirestoreMap$profiles);
            const docSnap = await getDocs(docRef);
            
            if (isMounted) { // コンポーネントがまだマウントされているか確認
                setList(docSnap.docs.map(doc => doc.data()));
            }
        };
    
        fetchData();
    
        return () => {
            isMounted = false; // アンマウントされたことを示す
        };
    }, []);
    

    return (
        <div className={classes.root}>
            {list.length === 0 && <Box sx={{
                position: 'relative', left:0, right:0, top:0, bottom: 0,
                margin: 'auto', zIndex: props.zIndex || 10000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: props.transparent ? '#ffffff00' : '#ffffff99', 
                flexDirection: 'column',
                pointerEvents: props.transparent ? 'none' : 'all',
                }}>
                <CircularProgress />
                {props.children}
            </Box>
}
            {list.map((item, index) => (
                <Link className={classes.templeCard} key={index} to={`/temple/${item["コード"]}`}>
                    <div className={classes.imgs}>
                        {item?.imgs?.map((img, index) => (
                            <img src={`${img.url}`} alt="Temple 1" key={index} />
                        ))}
                    </div>
                    <div className={classes.box}>
                        <div className={classes.name}>
                            {item["寺院名"]}
                            <div className={classes.category}>{item["宗派"]}</div>
                        </div>
                        <div className={classes.address}>
                            {item["所在地"]}

                            {false && <div className={classes.distance}>
                            <img src="/search-list/distance-icon.svg" alt="distance" />
                                3km
                            </div>}
                        </div>
                        <div onClick={(evn) => {
                            evn.preventDefault();
                            history.push(`/request/${item["コード"]}/${item["寺院名"]}`);
                        }} className={classes.request}>
                            お問い合わせ(無料)
                        </div>
                    </div>
                </Link>
            ))}
            {showMore && <div className={classes.moreButton} onClick={() => setShowMore(false)}>もっと表示する</div>}
        </div>
    );
};

export default SearchList;