import classes from "./UnsubscribeSuccess.module.scss";
import { useEffect, } from "react";
import { logEvent, } from "./lib/firebase";
import { getAuth, signOut } from "firebase/auth";

const UnsubscribeSuccess = (props) => {


    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        
        logEvent('unsubscribe-success')

    }, [])

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{
        
        signOut(getAuth()).then(() => {
            console.log("logout success")
        })

    }, [])

    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <div className={ classes.root }>
            
            

            <div className={classes.content}>

                <div className={classes.title}>
                    サービスの退会の完了
                </div>
                <img alt="inital2" src="/success.svg" />
                <span>
                    正常にサービスの退会を受け付けました。<br />
                    <br/>
                    「お寺のお葬式」のご利用ありがとうございました。
                </span>
            </div>
        </div>
};

export default UnsubscribeSuccess;