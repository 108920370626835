import classes from "./AdminUserAdd.module.scss";
import { useEffect, useRef, useState, } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useHistory, } from "react-router-dom";
import queryString from 'query-string';
import { logEvent, } from "./firebase";
import { useLocation } from "react-router-dom";
import { Button, } from "@mui/material";
import ValidateTextField from "./ValidateTextField";
import { firebaseFunctions, } from "./firebase";
import { httpsCallable } from "firebase/functions";
const {v4: uuidv4 } = require('uuid');

const functions = {
    adminAddUser : httpsCallable(firebaseFunctions, 'adminAddUser'),
}



const AdminUserAdd = (props) => {

    const [isLoading, setIsLoading] = useState()
    const inputContainerRef = useRef()
    const history = useHistory()
    const [backUrl, setBackUrl] = useState('')
    const current_route_path = useLocation();



    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        //アナリティクス
        logEvent("notication_edit")

    }, [])


    /* -----------------------------------------------
    * handleSave
    ----------------------------------------------- */
    const handleSave = async (email)=>{

        setIsLoading(true)

        // メール認証
        const result = await functions.adminAddUser({
            "email": email || inputContainerRef.current.querySelector(`input[name="email"]`).value,
        });
        console.log(result)
        if(result.data.status)
        {
            alert("追加しました")

            history.push(`/admin/shoplist?shopId=${result.data.uid}`)
        }else{

            alert("エラーが発生しました\n" + result.data.message)
        }

        setIsLoading(false)
    }

    /* -----------------------------------------------
    * handleCancel
    ----------------------------------------------- */
    const handleCancel = async ()=>{

        history.push(backUrl)
    }

    /* -----------------------------------------------
    * useEffect
    * 戻るパス取得
    ----------------------------------------------- */
    useEffect(()=>{
    

        //戻るボタンのパス
        const query_search = queryString.parse(current_route_path.search)
        if(query_search.back)
        {
            //指定のURL
            setBackUrl(query_search.back)
            
        } else {
            //チャットルームリスト
            setBackUrl('/account/edit')
        }
    }, [setBackUrl, current_route_path.search])



    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <div className={ classes.root }>


            {isLoading && 
                <Box sx={{
                    position: 'fixed', left:0, right:0, top:0, bottom: 0,
                    margin: 'auto', zIndex: 10000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff99', }}>
                    <CircularProgress />
                </Box>
            }

            <div className={ classes.header }>
                <button className={ classes.back_bt } onClick={ handleCancel }>戻る</button>
                <div className={ classes.title }>
                    ユーザーの追加
                </div>
            </div>

            
            <div className={ classes.scroller } ref={ inputContainerRef }>


                <div className={classes.item} >
                    <div className={classes.label}>メールアドレスでユーザーを追加する</div>
                    <div className={classes.row}>
                        手動でメールアドレスを入力しないでください。送信確認がとれたメールアドレスをコピーして貼り付けてください。
                    </div>
                    <br />
                    <ValidateTextField
                        name={`email`}
                        type='email'
                        placeholder="メールアドレス"
                        />
                    <br />
                    <Button className={ classes.save_bt } onClick={ ()=>handleSave() } fullWidth>保存</Button>
                </div>
                <br /><br />
                <hr />
                <br />
                <div className={classes.item} >
                    <div className={classes.label}>お店を追加（メールアドレスなし）</div>
                    <div className={classes.row}>
                        地図クリックでURLを開く設定はこちらから
                    </div>
                    <br />
                    <br />

                    <Button className={ classes.save_bt } onClick={ ()=>handleSave(`dummy-${uuidv4()}@shapedummy.com`) } fullWidth>追加</Button>
                </div>
            </div>

        </div>
};

export default AdminUserAdd;