import classes from "./TermsText.module.scss";

const TermsText = (props) => {
    
    return (
        <div className={ classes.root }>
            
            <h2><span>利用規約</span></h2>
            <p>
                <span>本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。 本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。</span>
            </p>
            <h4>
                <span>第1条（適用）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、 登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。</span>
                </li>
                <li>
                    <span>本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第2条（定義）</span>
            </h4>
            <p>
                <span>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</span>
            </p>
            <ol start="1">
                <li>
                    <span>「サービス利用契約」とは、本規約及び当社と登録ユーザーの間で締結する、本サービスの利用契約を意味します。</span>
                </li>
                <li>
                    <span>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権 （それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</span>
                </li>
                <li>
                    <span>「投稿データ」とは、登録ユーザーが本サービスを利用して投稿その他送信するコンテンツ （文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。</span>
                </li>
                <li>
                    <span>「当社」とは、【株式会社Shapeplanning】を意味します。</span>
                </li>
                <li>
                    <span>「本アプリ」とは、当社が提供する「お寺のお葬式」（理由の如何を問わずアプリの名称または内容が変更された場合は、当該変更後のアプリを含みます。）を意味します。</span>
                </li>
                <li>
                    <span>「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。</span>
                </li>
                <li>
                    <span>「本サービス」とは、当社が提供する【お寺のお葬式】という名称のサービス （理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</span>
                </li>
                <li>
                    <span>「利用不能」とは、登録ユーザーが本サービスを全く利用できない状態を意味します。</span>
                </li>
            </ol>
            <h4>
                <span>第3条（登録）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、 かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、 当社に対し、本サービスの利用の登録を申請することができます。</span>
                </li>
                <li>
                    <span>当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、 当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。</span>
                </li>
                <li>
                    <span>前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。</span>
                </li>
                <li>
                    <span>当社は、登録ユーザーからの問い合わせに回答する際、もしくはサービス運営に必要と判断される場合、本サービスの利用状況等を閲覧できるものとします。</span>
                </li>
                <li>
                    <span>当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。</span>
                </li>
            </ol>
            <ol start="1">
                <li>
                    <span>当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</span>
                </li>
                <li>
                    <span>未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</span>
                </li>
                <li>
                    <span>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、 または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</span>
                </li>
                <li>
                    <span>登録希望者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合</span>
                </li>
                <li>
                    <span>第15条に定める措置を受けたことがある場合</span>
                </li>
                <li>
                    <span>その他、当社が登録を適当でないと判断した場合</span>
                </li>
            </ol>
            <ol start="6">
                <li>
                    <span>前各項に掲げる内容から発生する一切の損害について、当社は責任を負わないものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第4条（登録事項の変更）</span>
            </h4>
            <p>
                <span>登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。通知を遅滞したことによる一切の損害について、当社は責任を負わないものとします。</span>
            </p>
            <h4>
                <span>第5条（ユーザーIDの管理）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>登録ユーザーは、自己の責任において、本サービスに関するユーザーIDを適切に管理及び保管するものとし、 これを第三者に利用させ、または貸与、譲渡、名義変更、売買、質入等をしてはならないものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは、本サービスの提供を受けるために必要な機器、通信手段等の環境を全て自らの費用と責任で備え、本サービスの利用のために必要となる通信環境、通信費用は、全て登録ユーザーの責任とします。</span>
                </li>
                <li>
                    <span>ユーザーID、通信機器の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。</span>
                </li>
                <li>
                    <span>登録ユーザーは、ユーザーIDを盗まれたり、第三者に使用されたりしていることが判明し、又はそのおそれがある場合には、直ちに当社にその旨を通知するとともに、当社からの指示に従うものとします。</span>
                </li>
                <li>
                    <span>当社は、登録ユーザーからの問い合わせに回答する際、もしくはサービス運営に必要と判断される場合、登録ユーザーのＩＤを使用できるものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第6条（登録ユーザーの責任）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>本サービスの利用者は、自らの意思および責任をもって、本サービスに登録するものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーが自ら登録した情報については、自らがその内容について一切の責任を負うものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは自らが登録した情報をいつでも変更、追加、削除することができます。</span>
                </li>
                <li>
                    <span>登録ユーザーは自らの意思によって本サービスを利用し、利用にかかわる全ての責任を負うものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは本サービスに関する当社からの問い合わせがあった場合には、速やかに回答するものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは責任をもって利用目的に沿い自らの登録情報を、正確、完全、最新に保つものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは本サービスを通じて、お客様及び販売者との取引でのトラブルには、当社は関わらず当社では一切責任を負いません。</span>
                </li>
                <li>
                    <span>本サービスの登録ユーザー間におけるコミュニケーションは、各ユーザーの責任において最大限の注意を払って行うものとします。ユーザー間で生じたあらゆる紛争、中傷、いやがらせ、詐欺、及びそれが原因で発生した損失又は損害について、当社では一切責任を負いません。</span>
                </li>
                <li>
                    <span>登録ユーザーの登録情報、契約の合意報告等の内容に虚偽又は誤りが含まれており、それに起因して当社に損害が生じた場合には、当社に生じた損害（紛争解決に要する弁護士費用、本サービスのイメージを回復するために講じた措置に要した費用、その他当社に生じた一切の損害を含みます）について、登録ユーザーに対して補償を求める場合があります。</span>
                </li>
            </ol>
            <h4>
                <span>第7条（不保証）</span>
            </h4>
            <p>
                <span>本サービスは登録ユーザーに対して、以下の各号を保証するものではありません。</span>
            </p>
            <ol start="1">
                <li>
                    <span>成約の成立または採用合意の成立</span>
                </li>
                <li>
                    <span>本サービスにエラーがないこと、サーバ等にウィルスその他の有害な要素が含まれていないこと、その他本サービスの提供のためのインフラ、システム等に瑕疵がないこと</span>
                </li>
                <li>
                    <span>サイト上の情報に誤字脱字等の誤記載がないこと、情報及び資料等について紛失・壊損・データ破壊がないこと</span>
                </li>
                <li>
                    <span>登録ユーザーによる本サービスの利用が、第三者の権利を何ら侵害するものではないこと</span>
                </li>
                <li>
                    <span>本サービスに関連する全ての画面が、見本もしくは参考資料と同じであること、及び仕様デザイン・情報の配置等が変更されないこと</span>
                </li>
            </ol>
            <h4>
                <span>第8条（禁止事項）</span>
            </h4>
            <p>
                <span>登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断した場合、当社は本サービスの提供の停止・終了その他当社が必要と合理的に判断する行為をすることができるものとします。</span>
            </p>
            <ol start="1">
                <li>
                    <span>法令に違反する行為または犯罪行為に関連する行為</span>
                </li>
                <li>
                    <span>当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</span>
                </li>
                <li>
                    <span>公序良俗に反する行為</span>
                </li>
                <li>
                    <span>当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</span>
                </li>
                <li>
                    <span>本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること</span>
                </li>
            </ol>
            <ul>
                <li>
                    <span>過度に暴力的または残虐な表現を含む情報</span>
                </li>
                <li>
                    <span>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</span>
                </li>
                <li>
                    <span>当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</span>
                </li>
                <li>
                    <span>過度にわいせつな表現を含む情報</span>
                </li>
                <li>
                    <span>差別を助長する表現を含む情報</span>
                </li>
                <li>
                    <span>自殺、自傷行為を助長する表現を含む情報</span>
                </li>
                <li>
                    <span>薬物の不適切な利用を助長する表現を含む情報</span>
                </li>
                <li>
                    <span>反社会的な表現を含む情報</span>
                </li>
                <li>
                    <span>チェーンメール等の第三者への情報の拡散を求める情報</span>
                </li>
                <li>
                    <span>他人に不快感を与える表現を含む情報</span>
                </li>
            </ul>
            <ol start="6">
                <li>
                    <span>登録ユーザーの本サービス外での連絡誘導行為</span>
                </li>
                <li>
                    <span>権利者の同意のない情報の公表</span>
                </li>
                <li>
                    <span>お客様及び販売者との約束を正当な理由なく破る、その他お客様及び販売者に対する不誠実な行為</span>
                </li>
                <li>
                    <span>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</span>
                </li>
                <li>
                    <span>本サービスの運営を妨害するおそれのある行為</span>
                </li>
                <li>
                    <span>当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為</span>
                </li>
                <li>
                    <span>第三者に成りすます行為</span>
                </li>
                <li>
                    <span>本サービスの他の利用者のIDを利用する行為</span>
                </li>
                <li>
                    <span>当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</span>
                </li>
                <li>
                    <span>本サービスの他の利用者の情報の収集</span>
                </li>
                <li>
                    <span>当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</span>
                </li>
                <li>
                    <span>反社会的勢力等への利益供与</span>
                </li>
                <li>
                    <span>その他、当社が不適切と判断する行為</span>
                </li>
            </ol>
            <h4>
                <span>第9条（情報の変更・削除）</span>
            </h4>
            <p>
                <span>当社は、前条の禁止行為がなされた等を理由に登録情報を変更・削除する必要がある場合、登録ユーザーに通知することなく、当該情報を変更・削除することができます。</span>
            </p>
            <h4>
                <span>第10条（停止・除名）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>登録ユーザーが本規約に違反したと当社が判断した場合、当社は当該登録ユーザーに何らの催告・通知無しに、即時にサービスの提供を停止し、又は終了することができるものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーが、当社(当社の役員・従業員を含みます)に対する誹謗中傷、脅迫、強要その他当社の正常かつ円滑な業務に支障をきたしもしくはそのおそれがある行為をしたと当社が判断した場合、その他登録ユーザーとして不適切な行為があると当社が判断した場合も、前項と同様とします。</span>
                </li>
                <li>
                    <span>前二項の場合において、当社はその判断の根拠もしくは理由の開示、質問等への回答、その他一切の対応を行う義務はないものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第11条（本サービスの停止等）</span>
            </h4>
            <p>
                <span>当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</span>
            </p>
            <ol start="1">
                <li>
                    <span>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</span>
                </li>
                <li>
                    <span>コンピューター、通信回線等が事故により停止した場合</span>
                </li>
                <li>
                    <span>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</span>
                </li>
                <li>
                    <span>その他、当社が停止または中断を必要と判断した場合</span>
                </li>
            </ol>
            <h4>
                <span>第12条（権利帰属）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>本アプリ及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本アプリまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</span>
                </li>
                <li>
                    <span>登録ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。また、登録ユーザーが、本条の規定に違反して問題が発生した場合、登録ユーザーは、自己の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担又は損害を与えないよう適切な措置を講じます。</span>
                </li>
                <li>
                    <span>登録ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録ユーザーに対しても、本サービスを利用して登録ユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。</span>
                </li>
                <li>
                    <span>登録ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権（公表権、氏名表示権及び同一性保持権を含みます。）を行使しないことに同意するものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第13条（退会）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>登録ユーザーは、当社所定の方法で当社に通知することにより、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。</span>
                </li>
                <li>
                    <span>退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</span>
                </li>
                <li>
                    <span>退会後の利用者情報の取扱いについては、第19条の規定に従うものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第14条（本サービスの内容の変更、終了）</span>
            </h4>
            <p>
                <span>当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。</span>
            </p>
            <h4>
                <span>第15条（免責）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、 登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、 及び不具合が生じないことについて、何ら保証するものではありません。</span>
                </li>
                <li>
                    <span>当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、 登録ユーザーが本サービスに送信したメッセージまたは情報の削除または消失、登録ユーザーの登録の抹消、 本サービスの利用による登録データの消失または機器の故障もしくは損傷、 その他本サービスに関して登録ユーザーが被った損害（以下「ユーザー損害」といいます。）につき、 賠償する責任を一切負わないものとします。</span>
                </li>
                <li>
                    <span>本サービスまたは本アプリに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、 連絡、紛争等については、当社は一切責任を負いません。</span>
                </li>
                <li>
                    <span>当社は、法律上の請求の原因の種類を問わず、本サービス（本サービスと連携するサービスを含みます。以下、本条において同じ。） の利用もしくは利用不能またはその他本規約に規定する事項に関して生じる特別損害、付随的損害、間接的損害、派生的損害、 その他の一切の損害（逸失利益、機密情報、データもしくはその他の情報の喪失、事業の中断、人身傷害、 プライバシーの侵害またはその他の金銭的損失を含みますがこれらに限られません。）に関して、 当社は、一切の責任を負わないものとします。 当社に故意もしくは過失がある場合を除き、登録ユーザーから損害発生の可能性について示唆もしくは通知等がされていた場合も同様です。</span>
                </li>
                <li>
                    <span>当社は本サービス（本サービスと連携するサービスその他の本サービスに関連して第三者が提供するサービスを含みます。）の中断、 停止、利用制限、変更、追加、終了または登録ユーザーデータ （登録ユーザーが本サービスと連携するサービスの提供元その他の本サービスに関連するサービスの提供元等の第三者に対して提供したデータを含みます。） の喪失、流出等に関連して登録ユーザーが被った損害等について、一切の責任を負わないものとします。</span>
                </li>
                <li>
                    <span>当社は、当社の管理外である通信回線や当社の設備に属さない設備の状態について、一切の責任を負わないものとします。</span>
                </li>
                <li>
                    <span>当社は、登録ユーザーが本サービスの利用に関連して他の登録ユーザーを含む第三者に与えた損害または損失等について、一切の責任を負わないものとします。</span>
                </li>
                <li>
                    <span>前各項に定めるほか、当社は、登録ユーザーが本サービスの利用に関連して被った損害または損失等について、一切の責任を負わないものとします。</span>
                </li>
                <li>
                    <span>当社は、登録ユーザーが使用するいかなる機器、ソフトウェアについても、その動作保証を一切行わないものとします。</span>
                </li>
                <li>
                    <span>当社は、本サービスによってアクセスが可能な情報、ソフトウェア等について、その完全性、正確性、有用性又は適法性を保証しないものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第16条（秘密保持）</span>
            </h4>
            <p>
                <span>登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、 当社の事前の書面による承諾、法令の定め又は裁判所の命令に基づき開示が要請された場合を除き、秘密に取り扱うものとし、本契約が終了した場合には、当社の指示に従い速やかに当該秘密情報を破棄します。</span>
            </p>
            <h4>
                <span>第17条（利用者情報の取扱い）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>当社による登録ユーザーの利用者情報の取扱いについては、本サービスの個人情報保護方針の定めによるものとし、 登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。</span>
                </li>
                <li>
                    <span>当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、 利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは、当社がシステム等の保守、点検ないし管理のため、当社と業務委託契約を締結する企業に対し、当該企業との間で予め各種情報の保護についての契約を締結した上で、本サービスに関わる一切の情報を開示することを承諾するものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第18条（本規約等の変更）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>当社は、本規約を変更できるものとします。当社は、本規約を変更する場合には、変更後の本規約の効力発生時期を定めて、登録ユーザーに当該変更内容及び効力発生時期を通知その他当社所定の方法により登録ユーザーに周知するものとし、 当該変更内容の通知後、登録ユーザーが本サービスを利用した場合または当社の定める期間内に登録抹消の手続をとらなかった場合には、 登録ユーザーは、本規約の変更に同意したものとみなします。</span>
                </li>
                <li>
                    <span>当社は民法第548条の4の規定に基づき、登録ユーザーの個別の了解を得ることなく本規約を変更できるものとします。この場合、本サービスの利用条件は、変更後の本規約によるものとします。</span>
                </li>
                <li>
                    <span>変更後の規約は、当社が別途定める場合を除き、本サイト上に表示した効力発生時点より、効力を生じるものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第19条（連絡／通知）</span>
            </h4>
            <p>
                <span>本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、 及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、 当社の定める方法で行うものとします。</span>
            </p>
            <h4>
                <span>第20条（サービス利用契約上の地位の譲渡等）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>登録ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位または本規約に基づく権利もしくは義務につき、 第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</span>
                </li>
                <li>
                    <span>当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、 本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、 登録ユーザーは、その譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、 通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</span>
                </li>
            </ol>
            <h4>
                <span>第21条（分離可能性）</span>
            </h4>
            <p>
                <span>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、 本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。また、本規約の規定の一部がある登録ユーザーとの関係で無効又は取消となった場合でも、本規約は他の登録ユーザーとの関係では有効とします。</span>
            </p>
            <h4>
                <span>第22条（準拠法及び管轄裁判所）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>本規約及びサービス利用契約の準拠法は日本法とします。なお、本サービスにおいて物品の売買が発生する場合であっても、 国際物品売買契約に関する国際連合条約の適用を排除することに合意します。</span>
                </li>
                <li>
                    <span>本規約またはサービス利用契約に起因し、または関連する一切の紛争については、金沢地方裁判所を専属的管轄裁判所とします。</span>
                </li>
            </ol>
            <h4>
                <span>第23条（損害賠償）</span>
            </h4>
            <ol start="1">
                <li>
                    <span>当社は、故意または重大な過失により本サービスの提供をしなかったときは（第11条（本サービスの停止等）、及び第16条（本サービスの内容の変更、終了）、及び第20条（本規約等の変更）があった場合は含みません）、利用不能にあることを当社が知った時刻から起算して、24時間以上利用不能が継続したときに限り、登録ユーザーに対し、その請求に基づき登録ユーザーに現実に発生した損害の賠償に応じます。ただし、天災地変等当社の責めに帰することができない事由により生じた損害、当社の予見の有無を問わず特別の事情から生じた損害、間接損害及び逸失利益については、当社は賠償責任を負わないものとします。</span>
                </li>
                <li>
                    <span>損害賠償の上限は、本サービスの利用料金までとします。</span>
                </li>
                <li>
                    <span>登録ユーザーは、本規約に違反したことにより、または本サービスの利用に関して故意もしくは過失により当社に損害を与えた場合は、 当社に対し、その全ての損害（逸失利益や合理的な弁護士費用を含みますがこれに限られません。）を直ちに賠償するものとします。</span>
                </li>
            </ol>
            <p>
                <span></span>
            </p>


            <div className={ classes.date }>2023年6月1日制定</div>


        </div>
    );
};

export default TermsText;