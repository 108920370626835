import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from "react-leaflet";
import dayjs from 'dayjs';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import 'leaflet/dist/leaflet.css';
import { styled } from '@mui/system';
const MapContainer2 = styled(MapContainer)({
    height: "300px",
    
    ".leaflet-marker-icon" : {
        border: "none !important",
    },
    ".leaflet-tooltip" : {
        pointerEvents: "all !important",
    }
});

const shopList = [
    {
        text: <><b>急募！{dayjs().add(10, 'day').format("MM/DD")} イートフェス(サンプル)</b><br />
        場所：イートプラス<br />
        たこ焼き x 1台<br />
        お好み焼き x 1台<br />
        ビール x 2台<br /></>,
        pos:[36.5425231, 136.6249059],
        zoom: 13,
    },
    {
        text: <><b>急募！{dayjs().add(15, 'day').format("MM/DD")} 和倉港わくわくプラザ(サンプル)</b><br />
        場所：和倉港わくわくプラザ<br />
        ピザ x 1台<br />
        パスタ x 1台<br />
        スイーツ x 2台<br />
        ワイン x 2台<br /></>,
        pos:[37.0907127,136.9162403],
        zoom: 13,
    },
    {
        text: <><b>急募！{dayjs().add(20, 'day').format("MM/DD")} 日本酒祭り(サンプル)</b><br />
        場所：しいのき迎賓館<br />
        イカ焼き x 1台<br />
        たこ焼き x 1台<br />
        お好み焼き x 1台<br />
        日本酒 x 5台<br /></>,
        pos:[36.5624259,136.652956],
        zoom: 13,
    },
    {
        text: <><b>急募！{dayjs().add(20, 'day').format("MM/DD")} Seeフェスタ(サンプル)</b><br />
        場所：金沢港クルーズターミナル<br />
        イカ焼き x 1台<br />
        磯焼き x 1台<br />
        串焼き x 1台<br />
        焼き鳥 x 1台<br />
        酒類 x 3台<br /></>,
        pos:[36.6079709,136.6119225],
        zoom: 13,
    },
    {
        text: <><b>急募！{dayjs().add(20, 'day').format("MM/DD")} わじまあさイチフェス(サンプル)</b><br />
        場所：輪島マリンタウン<br />
        イカ焼き x 1台<br />
        たこ焼き x 1台<br />
        かき氷 x 1台<br />
        日本酒 x 1台<br />
        ビール酒 x 1台<br />
        </>,
        pos:[37.3957932,136.9062596],
        zoom: 13,
    },
    {
        text: <><b>急募！{dayjs().add(20, 'day').format("MM/DD")} サッカーフェス(サンプル)</b><br />
        場所：産業展示館４号館<br />
        ハンバーガー x 1台<br />
        ホットドック x 1台<br />
        アイスクリーム x 1台<br />
        かき氷 x 1台<br />
        ラーメン x 1台<br />
        </>,
        pos:[36.5732924,136.6031664],
        zoom: 13,
    },
    
    
    
]

const EventHoldMap = (props) => {

    const ChangeView = ({ center, zoom }) => {

        const map = useMap();

        map.setView(center, zoom);
        
        return null;
    }

    const currentShopIndexRef = useRef(0)
    const [currentCenter, setCurrentCenter] = useState([36.5, 136.6])
    const [currentZoom, setCurrentZoom] = useState(11.5)
    
    useEffect(() => {

        const exec = () => {
            if(currentShopIndexRef.current >= shopList.length) {
                currentShopIndexRef.current = 0
            }
            
            const currentShop = shopList[currentShopIndexRef.current]
            
            setCurrentCenter([currentShop.pos[0]+.01, currentShop.pos[1]])
            setCurrentZoom(currentShop.zoom || 11.5)
            currentShopIndexRef.current++
        }
        exec()
        const interval = setInterval(exec, 5 * 1000);

        return () => clearInterval(interval);

    }, [])

    const renderContent = useMemo(() => {
        console.log("useMemo", currentCenter, currentZoom)

        return <MapContainer2 center={currentCenter} zoom={currentZoom} scrollWheelZoom={true} zoomControl={false}>
            <ChangeView center={currentCenter} zoom={currentZoom} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {shopList.map((shop, index) => {

                return (
                    <Marker key={index} position={shop.pos} /*icon={L.icon({iconUrl: shop.icon, iconAnchor:[24,24]})}*/ eventHandlers={{
                        click: (e) => {
                          alert("こちらはサンプルです。フェスが開催されるまでお楽しみに！")
                        },
                      }}>
                        <Tooltip permanent direction="top">
                            <div onClick={()=>alert("こちらはサンプルです。フェスが開催されるまでお楽しみに！")} style={{zIndex: 999999, position:'relative'}}>
                                {shop.text}
                                <Link to="/call-kitchen-car" onClick={(evn)=>evn.preventDefault()}>詳細はこちら</Link>
                            </div>
                        </Tooltip>
                      </Marker>
            )})}
        </MapContainer2>
    }, [currentCenter, currentZoom])


    return renderContent
};

export default EventHoldMap;
