import { GoogleAuthProvider, signInWithPopup , getAuth, signInWithPhoneNumber, RecaptchaVerifier, signInWithEmailAndPassword, } from "firebase/auth";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { useEffect, useCallback, useState, } from "react";
import EmailIcon from '@mui/icons-material/Email';
import Divider from '@mui/material/Divider';
import Link2 from './Link2';
import { useAuthContext } from '../../context/lib/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import { useLocation, useHistory, } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import * as validateLib from './validateLib';
import { Helmet, HelmetProvider } from "react-helmet-async";
import CallIcon from '@mui/icons-material/Call';
import PhoneNumberInput from './PhoneNumberInput'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { setDoc, doc, } from "firebase/firestore";
import { firebaseDB, } from "./firebase";
import { FirestoreMap$users, FirestoreMap$users_auth, } from "./FirestoreMap"
import { appLib } from "./_app_lib";
import { firebaseFunctions, } from "./firebase";
import { httpsCallable } from "firebase/functions";
import VpnKeyIcon from '@mui/icons-material/VpnKey';


const functions = {
    sendSignInEmail : httpsCallable(firebaseFunctions, 'sendSignInEmail'),
}

const Login = (props) => {

    const { user } = useAuthContext();
    const current_route_path = useLocation();
    const [isShowMailInput, setIsShowMailInput] = useState(false);
    const [isShowPhoneNumberInput, setIsShowPhoneNumberInput] = useState(false);
    const [emailInputValue, setEmailInputValue] = useState()
    const [emailInputError, setEmailInputError] = useState(false)
    const [phoneNumberInputValue, setPhoneNumberInputValue] = useState()
    const [phoneNumberInputError, setPhoneNumberInputError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showMailComplete, setShowMailComplete] = useState(false)
    const [useGoogleLogin, setUseGoogleLogin] = useState(false)
    const [isShowPhoneNumberVerifyCodeInput, setIsShowPhoneNumberVerifyCodeInput] = useState(false)
    const [phoneNumberVerifyCodeInputValue, setPhoneNumberVerifyCodeInputValue] = useState()
    const [verifyRecaptcha, setVerifyRecaptcha] = useState(false)
    const history = useHistory()
    const [isShowDisableAccount, setIsShowDisableAccount ]= useState(false)
    const [isShowAuthError, setIsShowAuthError ]= useState(false)
    const [isShowPasswordInput, setIsShowPasswordInput ]= useState(false)
    const [passwordInputValue, setPasswordInputValue ]= useState("")

    /* ----------------------------------------
    * getRedirect
    ----------------------------------------*/
    const getRedirect = useCallback(()=>{
        var url = queryString.parse(current_route_path.search).url
        return url || "/"
    }, [current_route_path.search])


    /* ----------------------------------------
    * handleGoogleLogin
    * Googleログイン
    ----------------------------------------*/
    const handleGoogleLogin = (event) => {
        event.preventDefault();
        
        //ログイン済みの場合
        if(user.current)
            throw Error("user logined")

        //読込中
        setIsLoading(true)
        
        //Googleログインポップアップ
        signInWithPopup(getAuth(), new GoogleAuthProvider())
            .then(async (result) => {

                //コールバック
                if(props.onCallback)
                    await props.onCallback(result.user)
                
                //リダイレクト（ ✗ history.push → ○ window.location.href ：カートの反映遅延の対処）
                const redirect = getRedirect()
                window.location.href = redirect ? decodeURIComponent(redirect) : '/';


            }).catch((error) => {
                
                // ユーザー無効
                if(error.code === "auth/user-disabled")
                {
                    setIsLoading(false)
                    setIsShowDisableAccount(true)
                }
                
            });
        

    };


    /* ----------------------------------------
    * handleMailLogin
    * メールログイン
    ----------------------------------------*/
    const handleMailLogin = (event) => {
        setEmailInputValue("")
        setIsShowMailInput(true)
        setIsShowDisableAccount(false)
    }

    /* ----------------------------------------
    * handlePasswordLogin
    * パスワードログイン
    ----------------------------------------*/
    const handlePasswordLogin = (event) => {
        setEmailInputValue("")
        setIsShowPasswordInput(true)
        setIsShowDisableAccount(false)
    }

    /* ----------------------------------------
    * handleSubmitMail
    ----------------------------------------*/
    const handleSubmitMail = async (event) => {

        //ローディング中にしてメールポップアップを消す
        setIsLoading(true)
        setIsShowMailInput(false)


        // サインインメールを送信する
        const ret = await functions.sendSignInEmail({
            email: emailInputValue,
            continueUrl: getRedirect(),
        })
        
        console.log(ret)
        
        if(ret?.data?.success){
                
                setShowMailComplete(true)
                setIsLoading(false)

        }else {

                // ユーザー無効
            if(ret?.data?.error_code === "auth/user-disabled")
                {
                    setIsLoading(false)
                    setIsShowDisableAccount(true)
            } else {
                
                alert("エラーが発生しました。時間をおいて再度お試しください。")
              
                // 読み込み直し
                appLib.SuperReload()
            }
        }  

    };


    /* ----------------------------------------
    * handleSubmitPassword
    ----------------------------------------*/
    const handleSubmitPassword = async (event) => {

        //ローディング中にしてパスワードポップアップを消す
        setIsLoading(true)


        // ログインを開始する
        try {
            const userCredential = await signInWithEmailAndPassword(getAuth(), emailInputValue, passwordInputValue);
            const user = userCredential.user;
            console.log('User logged in:', user);
            history.push("/")
            
        } catch (error) {
            console.error('Error logging in:', error);
            console.error(error.code)
            console.error(error.message)
            setIsLoading(false)
                
            if(error.code === "auth/user-not-found"){
                alert("ユーザーが見つかりませんでした")
            }else if(error.code === "auth/wrong-password"){
                alert("パスワードが違います")
            }else if(error.code === "auth/user-disabled"){

                setIsShowDisableAccount(true)
            }else{
                alert("ログインできませんでした")
            }
        }
        
    };
    /* ----------------------------------------
    * handlePhoneNumberLogin
    * 電話番号ログイン
    ----------------------------------------*/
    const handlePhoneNumberLogin = (event) => {
        setIsShowPhoneNumberInput(true)
        setIsShowDisableAccount(false)
        setIsShowPhoneNumberVerifyCodeInput(false)

        //Recaptchaをリセット
        setVerifyRecaptcha(false)

        //次のフレームで recaptchaを初期化
        window.requestAnimationFrame(()=>{
        // setTimeout(()=>{
            console.log("requestAnimationFrame")
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'normal',
                'callback': (response) => {
                    console.log("recaptcha-container callback")
                    setVerifyRecaptcha(true)
                },
                'expired-callback': () => {
                    console.log("recaptcha-container expired-callback")
                    setVerifyRecaptcha(false)
                }
              }, getAuth())
            window.recaptchaVerifier.render()
        }, 1000)
        
    }
        
    /* ----------------------------------------
    * handleSubmitPhoneNumber
    ----------------------------------------*/
    const handleSubmitPhoneNumber = async (event) => {


        //ローディング中にしてメールポップアップを消す
        setIsLoading(true)


        const auth = getAuth();

        // サインアウト
        // await signOut(auth)



        const phoneNumber = phoneNumberInputValue.replaceAll("-", "")
        const appVerifier = window.recaptchaVerifier;

        console.log(phoneNumber)

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                console.log("signInWithPhoneNumber result", confirmationResult)
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;

                setIsLoading(false)

                //電話番号入力を消す
                setIsShowPhoneNumberInput(false)

                //SNSコード入力画面を表示
                setIsShowPhoneNumberVerifyCodeInput(true)


                // ...
            }).catch((error) => {
                console.log("error")
                console.error(error)

                setIsLoading(false)

                if(window.grecaptcha){
                    //recaptchaをリセット
                    window.grecaptcha.reset(window.recaptchaWidgetId);
                }

                alert("エラーが発生しました。時間をおいて再度お試しください。")

                // 読み込み直し
                appLib.SuperReload()

            });

    };

    /* ----------------------------------------
    * registerDomain
    ----------------------------------------*/
    const registerDomain = async (user)=>{

        if(props.skipRegisterDomain)
            return true

        console.log(user.uid)
        
        try{
            //ユーザー認証データにドメインを登録する
            await setDoc(doc(firebaseDB, FirestoreMap$users, user.uid, FirestoreMap$users_auth, user.uid),{
                domain: window.location.host,
            }, { merge: true })
            return true
        } catch (e){
            console.error(e)
            setIsLoading(false)
            // ポップアップを表示
            setIsShowAuthError(true)
            return false
        }

    }

    /* ----------------------------------------
    * handleSubmitPhoneNumberVerifyCode
    ----------------------------------------*/
    const handleSubmitPhoneNumberVerifyCode = () => {

        setIsLoading(true)

        const code = phoneNumberVerifyCodeInputValue;

        window.confirmationResult.confirm(code).then(async (result) => {

            // ログインしたドメインを登録する
            if(!(await registerDomain(result.user)))
                return

            //コールバック
            if(props.onCallback)
                await props.onCallback(result.user)
        
            //リダイレクト（ ✗ history.push → ○ window.location.href ：カートの反映遅延の対処）
            const redirect = getRedirect()
            window.location.href = redirect ? decodeURIComponent(redirect) : '/';



        }).catch((error) => {

            setIsLoading(false)
            console.error(error)

            // ユーザー無効
            if(error.code === "auth/user-disabled")
            {
                setIsShowDisableAccount(true)
            } else {
                alert("認証コードが違います")
            }
        });
    }

    
    /* ----------------------------------------
    * Googleログインが可能かチェック
    ----------------------------------------*/
    useEffect(()=>{

        //WebViewの時はGoogleログインをオミット
        const ua = window.navigator.userAgent.toLowerCase().trim();

        let useGoogleLogin = !(ua.includes('fb_iab') || ua.includes('fbios') || ua.includes('instagram') || ua.includes('line/') || ua.includes('collabo-app-browser'))

        if(useGoogleLogin && !props.useGoogleLogin)
            useGoogleLogin = false

        setUseGoogleLogin( useGoogleLogin )

    }, [props.useGoogleLogin])
    
    
    /* ----------------------------------------
    * 開始時のモード設定処理
    ----------------------------------------*/
    useEffect(()=>{
        if(props.startMailLogin)
        {
            handleMailLogin()
        }
    }, [props.startMailLogin])

    return (
        
        <Box sx={{ 
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden scroll",
            transform: "translate3d(0px, 0px, 0px)",
            top: 0,
            left: 0
            }}>

            <HelmetProvider>
                <Helmet>
                   <meta
                        name="description"
                        content="ログイン"
                    />
                    <title>ログイン</title>
                </Helmet>
            </HelmetProvider>

            {(!user || isLoading) && 
                <Box sx={{
                    position: 'fixed', left:0, right:0, top:0, bottom: 0,
                    margin: 'auto', zIndex: 10000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff11', }}>
                    <CircularProgress />
                </Box>
            }


            { user && !isLoading && <>


                <Box sx={{ padding: 5,  mt:4}}>
                    <img src="/logo.svg" style={{ width: '40%', maxWidth: 500  }} alt="logo" />
                </Box>
                
                {user.current && <Box sx={{ backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                
                    {user.current.email || user.current.phoneNumber.replace('+81', '0').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') } でログイン済みです
                    <br /><br />
                    このまま続行しますか？<br />
                    <br />
                    <br />
                    <Button variant="contained" onClick={()=>history.push(getRedirect()) } fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >続行</Button>
                    <br />
                    <Divider sx={{m:2, width: '80%'}}></Divider>
                    <br />
                    別のアカウントでログインするには<br />
                    ログアウトをタップしてください<br />
                    <br />
                    <br />
                    <Button variant="outlined" onClick={()=>history.push('logout')} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >ログアウト</Button>
                    <br />
                    <br />

                </Box>}

                {!user.current && <Box sx={{ backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    
                    ログイン方法を選択してください                

                    <Box sx={{m:2}} />

                    { useGoogleLogin && 
                        <>
                            <Button variant="contained" onClick={handleGoogleLogin} startIcon={<GoogleIcon />} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >Googleアカウント</Button>

                            <Divider sx={{m:2, width: '80%'}}>または</Divider>
                        </>
                    }

                    { props.useMailLogin &&
                        <>
                            <Button variant="contained" onClick={handleMailLogin} startIcon={<EmailIcon />} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >メールアドレス</Button>

                            <Divider sx={{m:2, width: '80%'}}></Divider>
                        </>
                    }

                    { props.usePassword &&
                        <>
                            <Button variant="contained" onClick={handlePasswordLogin} startIcon={<VpnKeyIcon />} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >パスワード</Button>

                            <Divider sx={{m:2, width: '80%'}}></Divider>
                        </>
                    }

                    { props.usePhoneLogin &&
                        
                        <Button variant="contained" onClick={handlePhoneNumberLogin} startIcon={<CallIcon />} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >電話番号</Button>
                    }


                    <Box sx={{m:2}} />

                    <Box sx={{p:3}}>「お寺のお葬式」の<Link2 href="/terms">利用規約</Link2>と<Link2 href="/privacy">
                        プライバシー規約</Link2>に同意いただける場合はログインしてください。<br /><br /><br />
                    </Box>

                </Box>}
            </>}

            { isShowMailInput && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 999, top:0, bottom:0, left:0, right:0, }}>
            
                {!props.startMailLogin && <CloseIcon sx={{ position: 'absolute', left: 10, top: 10, }} fontSize='large' onClick={()=>setIsShowMailInput(false) }/>}
                
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                <Typography variant="body1" component="h1" sx={{ mt:2, mb:6 }}></Typography>

                <TextField
                    id="outlined-uncontrolled"
                    label="メールアドレス"
                    defaultValue=""
                    sx={{ width: '80%' }}
                    error={emailInputError}
                    helperText={ emailInputError ? "メールアドレス形式で入力してください" : "" }
                    onChange={ event => {

                        
                        const isEmail = validateLib.isEmail(event.target.value)

                        setEmailInputError(!isEmail)
                        setEmailInputValue(event.target.value)
                    }}
                    />

                <Box sx={{p:5}}>
                アカウントを作成すると、<Link2 href='/terms'>利用規約</Link2>、および<Link2 href='/privacy'>プライバシーポリシー</Link2>に同意したことになります。<br />
                </Box>

                <Button variant="contained" sx={{ mt:5, width: '80%' }} size="large" onClick={ handleSubmitMail } disabled={emailInputError || !emailInputValue} >認証メールの送信</Button>

            </Box>}


            { isShowPasswordInput && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 999, top:0, bottom:0, left:0, right:0, }}>
            
                <CloseIcon sx={{ position: 'absolute', left: 10, top: 10, }} fontSize='large' onClick={()=>setIsShowPasswordInput(false) }/>
                
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                <Typography variant="body1" component="h1" sx={{ mt:2, mb:6 }}></Typography>

                <TextField
                    id="outlined-uncontrolled"
                    label="メールアドレス"
                    defaultValue=""
                    sx={{ width: '80%' }}
                    error={emailInputError}
                    helperText={ emailInputError ? "メールアドレス形式で入力してください" : "" }
                    onChange={ event => {

                        
                        const isEmail = validateLib.isEmail(event.target.value)

                        setEmailInputError(!isEmail)
                        setEmailInputValue(event.target.value)
                    }}
                    />
                <br />
                <TextField
                    id="outlined-uncontrolled"
                    label="パスワード"
                    type="password"
                    defaultValue=""
                    sx={{ width: '80%' }}
                    onChange={ event => {
                        setPasswordInputValue(event.target.value)
                    }}
                    />

                <Box sx={{p:5}}>
                アカウントを作成すると、<Link2 href='/terms'>利用規約</Link2>、および<Link2 href='/privacy'>プライバシーポリシー</Link2>に同意したことになります。<br />
                </Box>


                <Button variant="contained" sx={{ mt:5, width: '80%' }} size="large" onClick={ handleSubmitPassword } disabled={emailInputError || !passwordInputValue || !emailInputValue} >ログイン</Button>

            </Box>}


            {isShowDisableAccount && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 9999, top:0, bottom:0, left:0, right:0, }}>
            
                <CloseIcon sx={{ position: 'absolute', left: 10, top: 10, }} fontSize='large' onClick={()=>setIsShowDisableAccount(false) }/>
                
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                <Typography variant="h5" color="#ff0000" component="h1" sx={{ mt:2, mb:6, fontWeight: 900 }}>アカウントが無効です</Typography>


                <Box sx={{p:5}}>
                    アカウントが無効になっています。<br />
                    このアカウントでログインする場合は運営サポートまでご連絡ください。<br/>
                    <a href="mailto:sales@shapeplanning.jp">sales@shapeplanning.jp</a><br/>
                    <br/>
                    別のアカウントでログインするには<br />
                    ログインをタップしてください
                </Box>


                <Button variant="contained" onClick={handleMailLogin} fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >ログイン</Button>

            </Box>}


            { showMailComplete && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 999, top:0, bottom:0, left:0, right:0, }}>
            
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                <Typography variant="h5" component="h1" sx={{ mt:2, mb:0 }}>認証メールを送信しました</Typography>

                <Box sx={{p:5}}>
                    {emailInputValue}に登録案内のメールを送信いたしました。
                    <br />
                    <br />
                    <Box sx={{ backgroundColor: "#fffcd9", borderRadius: "1em", }}>

                        <Typography variant="body1" sx={{ p:2, pb:0 }}>
                            メールが届くまでに10分程度かかることがあります。
                        </Typography>

                        <Typography variant="body2" sx={{ p:2 }}>
                            メールが届かない場合、迷惑メールフォルダに入っている可能性もございますので、そちらもご確認ください。メールが見当たらない場合やその他の問題が発生した際には、遠慮なくサポートチームまでご連絡ください。
                        </Typography>
                    </Box>
                </Box>

                


            </Box>}
            
            

            { isShowPhoneNumberInput && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 998, top:0, bottom:0, left:0, right:0, }}>
            
                <CloseIcon sx={{ position: 'absolute', left: 10, top: 10, }} fontSize='large' onClick={()=>setIsShowPhoneNumberInput(false) }/>
                
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                <Typography variant="h5" component="h1" sx={{ mt:2, mb:6 }}></Typography>

                
                <PhoneNumberInput
                    sx={{ width: '80%' }}
                    error={phoneNumberInputError}
                    helperText={ phoneNumberInputError ? "電話番号を入力してください" : "" }
                    onChange={ value => {
                        
                        const isTel = validateLib.isTel(value)

                        setPhoneNumberInputError(!isTel)
                        setPhoneNumberInputValue(value)
                    }}
                    />


                {/* recaptcha */}
                <Box sx={{my:2}}>
                    <div id="recaptcha-container" />
                </Box>

                <Box sx={{p:5, py:2}}>
                ログインすると、<Link2 href='/terms'>利用規約</Link2>、および<Link2 href='/privacy'>プライバシーポリシー</Link2>に同意したことになります。
                </Box>

                <Button variant="contained" sx={{ mt:5, width: '80%' }} size="large" onClick={ handleSubmitPhoneNumber } disabled={!verifyRecaptcha || phoneNumberInputError || !phoneNumberInputValue} >ログイン</Button>

            </Box>}


            { isShowPhoneNumberVerifyCodeInput && <Box sx={{ position: 'fixed', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: 999, top:0, bottom:0, left:0, right:0, }}>
            
                <CloseIcon sx={{ position: 'absolute', left: 10, top: 10, }} fontSize='large' onClick={()=>setIsShowPhoneNumberVerifyCodeInput(false) }/>
                
                <img src="/logo.svg" style={{ width: '20%', maxWidth: 300  }} alt="logo" />

                
                <Box sx={{ p:4, mt:2, mb:6 }}>
                    <Typography variant="h6" component="h1" sx={{fontWeight:'bold', textAlign: 'center'}}>認証コードを入力してください</Typography>
                    <Typography sx={{ mt:2, fontSize: 16, textAlign: 'center' }}>電話番号にメッセージを送信しました</Typography>
                    <Typography  sx={{ mt:2, fontSize: 13 }}>届くまで5分ほどかかる場合があります。届かない場合は再度やり直してください。</Typography>
                </Box>

                <TextField
                    id="outlined-uncontrolled"
                    label="認証コード"
                    defaultValue=""
                    sx={{ width: '80%' }}
                    min="0"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    type="tel"
                    onChange={ event => {

                        setPhoneNumberVerifyCodeInputValue(event.target.value)
                    }}
                    />

                <Button variant="contained" sx={{ mt:5, width: '80%' }} size="large" onClick={ handleSubmitPhoneNumberVerifyCode } >ログイン</Button>

            </Box>}
            
            
            <Dialog
                open={ isShowAuthError }
                // onClose={ () => setShowLogoutConfirm(false) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                        この電話番号は既に別のサービスで使用されています。
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=>history.push(`/logout?back=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`)} color="error" >別の番号を使う</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Login;