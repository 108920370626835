import { createContext, useState, useContext, useEffect } from 'react';
import { firebaseApp } from '../../components/lib/firebase';
import { getAuth, onAuthStateChanged, } from "firebase/auth";

const AuthContext = createContext();

const auth = getAuth(firebaseApp);

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(true);

    const value = {
        user,
        loading,
    };

    
    useEffect(() => {
        
        const unsubscribed = onAuthStateChanged(auth, (user) => {
            
            setUser({current: user});
            setLoading(false);
        });
        return () => {
            unsubscribed();
        };
    }, []);


    /*if (loading) {
        return <p>loading...</p>;
    } else {*/
        return (
            <AuthContext.Provider value={value}>
                {  children}
            </AuthContext.Provider>
        );
    // }
}