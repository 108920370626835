import imageCompression from 'browser-image-compression';
import {detect} from 'detect-browser'

const filePickBrowser = (option, callback) => {

    // キャンセルイベントをキャッチ
    document.body.onfocus = () =>{
        setTimeout(()=>{
            document.body.onfocus = null

            //何も選択されていない時
            if(fileElm.files.length === 0)
                callback([]);
        }, 500)
    }

    const fileElm = document.createElement("input");
    fileElm.type = "file";
    fileElm.multiple = option.multiple
    fileElm.addEventListener('change', evt => {
        
        console.log(fileElm.files);
        callback( fileElm.files );
    });
    fileElm.click();
}

export const appLib = {
    
    /* ----------------------------------------
    * valid
    ----------------------------------------*/
    valid : ()=>{

        return(
            typeof(window.JsResume) != "undefined" &&
            typeof(window.FilePicker) != "undefined" &&
            typeof(window.FileRead) != "undefined" &&
            typeof(window.Share) != "undefined" &&
            typeof(window.GetFcmToken) != "undefined" &&
            typeof(window.FcmRequestPermission) != "undefined" &&
            typeof(window.FcmGetNotificationPermission)!= "undefined" &&
            typeof(window.QrCodeReader) != "undefined" &&
            typeof(window.AppTrackingTransparency) != "undefined" &&
            typeof(window.UpdateBadgeCount)!= "undefined"
            );
    },

    /* ----------------------------------------
    * _init
    ----------------------------------------*/
    _init : async (resolve)=>{
        
        //再開用データを設定
        window.JsResume.postMessage(window.location.href);

        console.log("window._appLibResume");
        window._appLibResume = (data) =>{

            //awatの再開
            resolve(JSON.parse(data));

            //実行後は解除
            window._appLibResume = null;
        };


        new Promise(resolve => {
            resolve();
        });
    },

    /* ----------------------------------------
    * SuperReload
    ----------------------------------------*/
    SuperReload : async ()=>{

        // appLibに無い時
        if(typeof(window.SuperReload) == "undefined"){
            window.location.reload(true)
            return
        }
            
        window.SuperReload.postMessage({})
    },

    /* ----------------------------------------
    * GetPlatform
    ----------------------------------------*/
    GetPlatform : async ()=>{

        // appLibに無い時
        if(typeof(window.GetPlatform) == "undefined"){
            return new Promise(resolve => { resolve({name: detect().name}); });
        }

        return new Promise(async resolve => {
            
            await appLib._init(resolve);

            window.GetPlatform.postMessage({});
        });
    },
    /* ----------------------------------------
    * _FilePicker
    ----------------------------------------*/
    _FilePicker : async (args)=>{

        return new Promise(async resolve => {
            
            await appLib._init(resolve);

            window.FilePicker.postMessage(args);
        });
    },

    /* ----------------------------------------
    * FilePicker
    ----------------------------------------*/
    FilePicker : async (callback, option)=>{

        if(!option)
            option = {}

        const startCallback = Array.isArray(callback) ? callback[0] : undefined
        const endCallback = Array.isArray(callback) ? callback[1] : callback
        
        const platform = await appLib.GetPlatform()


        if(platform.name !== "android"){
            return new Promise(resolve => { 

                //ブラウザのファイル選択
                filePickBrowser(option, async (files)=>{

                    if(startCallback)
                        await startCallback()

                    const blobs = []
                    for(let i=0; i < files.length; i++)
                    {
                        const file = files[i]

                        console.log(file)

                        let compressBlob = null
            
                        try {
                            // 圧縮画像の生成
                            compressBlob = await imageCompression(file, {
                                maxSizeMB: 1, // 最大ファイルサイズ
                                maxWidthOrHeight: 1000 // 最大画像幅もしくは高さ
                            });
                            blobs.push(compressBlob)
                            
                        }catch{
                            console.warn('imageCompression skip')
                            blobs.push(file)
                        }
                    }
                    await endCallback(blobs)

                    resolve()
                });
            })
        }

        // Androidの時
        return new Promise(async resolve => {
            
            const files = await appLib._FilePicker(JSON.stringify(option))

            if(startCallback)
                await startCallback()

            const blobs = []
            if(files)
            {
                for(let i=0; i < files.length; i++)
                {
                    const file = files[i]

                    while(true)
                    {
                        console.log("readfile...");
                        const data = await appLib.ReadFile(file);
                        console.log("readfile end");

                        if(!data)
                            break;

                        console.log("変換中 " + (data.current + 1) + " / " + data.total);

                        //base64から blobに変換
                        let bin = window.atob(data.data);
                        let buffer = new Uint8Array(bin.length);
                        for(let i = 0; i < bin.length; i++){
                            buffer[i] = bin.charCodeAt(i);
                        }
                        const blob = new Blob([buffer.buffer], {
                            type: data.type
                        });
                        blob.lastModified = new Date()      //ブラウザのファイル選択した時のパラメータを追加
                        blobs.push(blob)
                    }
                    
                }

            }

            await endCallback(blobs)
            resolve()
            
            
        });

    },

    /* ----------------------------------------
    * ReadFile
    ----------------------------------------*/
    ReadFile : async (file)=>{

        return new Promise(async resolve => {
            
            await appLib._init(resolve);

            window.FileRead.postMessage(file);
        });
    },
    /* ----------------------------------------
    * DownloadFile
    ----------------------------------------*/
    DownloadFile : async (downloadUrl, name)=>{

        // appLibに無い時
        if(typeof(window.DownloadFile) == "undefined"){
            return new Promise(resolve => {
                setTimeout(() => resolve({status: false}), Math.random() * 1000);
            });
        }
        
        return new Promise(async resolve => {
            
            await appLib._init(resolve);

            window.DownloadFile.postMessage(JSON.stringify({url:downloadUrl, name:name}));
        });
    },
    /* ----------------------------------------
    * Share
    ----------------------------------------*/
    Share : (data)=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve(); });
        }

        return new Promise(async resolve => {

            await appLib._init(resolve);

            window.Share.postMessage(JSON.stringify(data));
        });
        
    },
    /* ----------------------------------------
    * UpdateBadgeCount
    ----------------------------------------*/
    UpdateBadgeCount : (data)=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve(); });
        }

        // 再開処理がある時は割り込まないようにする（FilePickerでアプリに戻った時に window._onAppResume が呼ばれるため対応）
        if(window._appLibResume)
            return

        return new Promise(async resolve => {

            await appLib._init(resolve);

            window.UpdateBadgeCount.postMessage(JSON.stringify({count: data}));
        });
        
    },
    /* ----------------------------------------
    * GetFcmToken
    ----------------------------------------*/
    GetFcmToken : ()=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve({token: false}); });
        }

        return new Promise(async resolve => {

            await appLib._init(resolve);

            window.GetFcmToken.postMessage(JSON.stringify({}));
        });
        
    },
    /* ----------------------------------------
    * FcmRequestPermission
    ----------------------------------------*/
    FcmRequestPermission : ()=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve({ enabled: true }); });
        }

        return new Promise(async resolve => {

            await appLib._init(resolve);

            window.FcmRequestPermission.postMessage(JSON.stringify({}));
        });
        
    },

    /* ----------------------------------------
    * FcmGetNotificationPermission
    ----------------------------------------*/
    FcmGetNotificationPermission : ()=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve({ enabled: true }); });
        }

        return new Promise(async resolve => {

            await appLib._init(resolve);

            window.FcmGetNotificationPermission.postMessage(JSON.stringify({}));
        });
        
    },
    
    /* ----------------------------------------
    * QRCodeReader
    ----------------------------------------*/
    QRCodeReader : ()=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve({ result: "qrcode123" }); });
        }

        return new Promise(resolve => {

            //画面が固まるのでsetTimeout
            setTimeout(async ()=>{

                await appLib._init(resolve);

                window.QrCodeReader.postMessage(JSON.stringify({}));

            }, 50)
            
        });
        
    },
    /* ----------------------------------------
    * AppTrackingTransparency
    ----------------------------------------*/
    AppTrackingTransparency : ()=>{

        if(!appLib.valid()){
            return new Promise(resolve => { resolve({ result: true }); });
        }
        
        return new Promise(resolve => {
            
            //画面が固まるのでsetTimeout
            setTimeout(async ()=>{
                
                await appLib._init(resolve);

                window.AppTrackingTransparency.postMessage(JSON.stringify({}));

            }, 50)
            
        });
        
    },
    
    /* ----------------------------------------
    * GetLocation
    ----------------------------------------*/
    GetLocation : async ()=>{

        if(!appLib.valid()){
            return new Promise((resolve, reject) => {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) =>{

                            var lat = position.coords.latitude;
                            var lon = position.coords.longitude;
                            resolve({status: true, pos:[lat, lon]});
                        },
                        (error) => {
                            resolve({status: false, error_code:error.PERMISSION_DENIED});
                            // switch(error.code) {
                            //     case error.PERMISSION_DENIED:
                            //         alert("User denied the request for Geolocation.");
                            //         break;
                            //     case error.POSITION_UNAVAILABLE:
                            //         alert("Location information is unavailable.");
                            //         break;
                            //     case error.TIMEOUT:
                            //         alert("The request to get user location timed out.");
                            //         break;
                            //     case error.UNKNOWN_ERROR:
                            //         alert("An unknown error occurred.");
                            //         break;
                            // }
                        }
                    )
                } else {
                    resolve({status: false, error_code:"Geolocation is not supported by this browser."});
                }
                
            });
        }

        return new Promise(async resolve => {

            if(typeof(window.GetLocation) === "undefined")
            {
                resolve({status: false, error_code:"GetLocation not found"});
                return
            }
            
            await appLib._init(resolve);

            window.GetLocation.postMessage({});
        });
    },
};