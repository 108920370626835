
import React, { useState, useEffect, useCallback, useRef, useMemo, } from "react";
// import { useCartContext } from '../context/CartContext';
//import Carousel from '@belcrod5/react-carousel';
import {Carousel} from './Carousel';
import View from './View';
import { getDoc, doc, } from "firebase/firestore";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import classes from './ViewList.module.scss';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { firebaseDB,  } from "./firebase";
import { FirestoreMap$profiles } from "./FirestoreMap";
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import algoliasearch from 'algoliasearch/lite'
const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
  const algoliaIndex = algoliaClient.initIndex("profile");

const ViewList = (props) => {

    const ref = useRef()
    const arrowRef_L = useRef()
    const arrowRef_R = useRef()
    const current_route_path = useLocation();

    // const { cart } = useCartContext();
    const [renderRoutePath, setRenderRoutePath] = useState(current_route_path.pathname)
    const [profileDatas, setprofileDatas] = useState([])
    const [nexloadIndex, setNextLoadIndex] = useState(0)
    const [loadListCounter, setLoadListCounter] = useState(0)
    const [currentLoadListCounter, setCurrentLoadListCounter] = useState(-1)
    const [isLoading, setIsLoading] = useState(0)
    const [slideIndex, setSlideIndex] = useState(0)
    const history = useHistory()
    const [helmetTitle, setHelmetTitle] = useState()
    const [helmetDescription, setHelmetDescription] = useState()
    const initializeRef = useRef(true)

    /* ----------------------------------------
    * setHelmet
    ----------------------------------------*/
    const setHelmet = (data)=>{

        console.log(data)
        setHelmetDescription(`イートプラス(お寺のお葬式)お店情報 ${data.address} ${data.cate} ${data.name}`)
        setHelmetTitle(`${data.name} | お寺のお葬式`)
    }

    /* ----------------------------------------
    * afterChange
    ----------------------------------------*/
    const afterChange = async (prevIndex, currentIndex)=>{
        console.log("afterChange", prevIndex, nexloadIndex - 1, "<=", currentIndex)
        
        // helmetの設定
        setHelmet(profileDatas[currentIndex])

        setSlideIndex(currentIndex)

        if(nexloadIndex - 1 <= currentIndex)
        {
            setIsLoading(true);

            const query_search = queryString.parse(current_route_path.search);
            const LIST_SIZE = query_search["single"] ? 1 : 3;
            for (let i = nexloadIndex; i < nexloadIndex + LIST_SIZE; i++) {

                const profileData = profileDatas[i];
                if(!profileData) break
                const docRef = await getDoc(doc(firebaseDB, FirestoreMap$profiles, profileData.id));
                profileDatas[i] = {  id: docRef.id, loaded: true, ...docRef.data() }
            }
            setprofileDatas(profileDatas)

            setNextLoadIndex(nexloadIndex + LIST_SIZE)


            setIsLoading(false);
        }
    }


    /* ----------------------------------------
    * loadList
    ----------------------------------------*/
    const loadList = useCallback(async () => {
        setIsLoading(true);
    
        console.log("loadList", )

        const query_search = queryString.parse(current_route_path.search);
        const LIST_SIZE = query_search["single"] ? 1 : 3;
        let _profileDatas = [];


        let _startDocId = props?.match?.params?.uuid
        const startDocRef = await getDoc(doc(firebaseDB, FirestoreMap$profiles, _startDocId));
        const startDocData = startDocRef.data()

        // ジオコードが無い場合
        if(!startDocData.geocode)
        {
            const docData = {  id: startDocRef.id, loaded: true, ...startDocData }

            _profileDatas.push(docData);

        } else {

            // Algoliaの検索オプションを設定
            const option = {
                aroundLatLng: `${startDocData.geocode[0]},${startDocData.geocode[1]}`, // 例: '35.689487,139.691706' (東京の緯度経度)
                aroundRadius: 200000, // 200km
            };
        
            // Algoliaで検索を実行
            try {
                const result = await algoliaIndex.search("", option);
            
                // 検索結果を処理
                for (let i = 0; i < result.hits.length; i++) {
                    const hit = result.hits[i];
                    
                    
                    let docData = { id: hit.objectID }
                    
                    // 制限以下はFirestoreから取得
                    if(i < LIST_SIZE){
                        const docRef = await getDoc(doc(firebaseDB, FirestoreMap$profiles, hit.objectID));
                        docData = {  id: hit.objectID, loaded: true, ...docRef.data() }
                    }

                    _profileDatas.push(docData);
                }

                // _profileDatasから_startDocIdを探して見つかったら、それを先頭にする
                const index = _profileDatas.findIndex((data)=>data.id === _startDocId)
                if(index !== -1){
                    const data = _profileDatas[index]
                    _profileDatas.splice(index, 1)
                    _profileDatas.unshift(data)
                }
                
            } catch (error) {
                console.error("Algolia search error:", error);
            }
        

            //次の読み込みインデックスを設定
            setNextLoadIndex(3)
        }


        //リストを設定
        _profileDatas = profileDatas.concat(_profileDatas)
        setprofileDatas(_profileDatas)
        

        // 初回のみhelmetの設定
        if(initializeRef.current) setHelmet(_profileDatas[0])
        initializeRef.current = false
        

        setIsLoading(false);
        
    }, [current_route_path.search, profileDatas, props?.match?.params?.uuid]);


    /* ----------------------------------------
    * onArrowClick
    ----------------------------------------*/
    const onArrowClick = useCallback((dir)=>{

        if(dir > 0){
            ref.current.next();
        } else {
            ref.current.previous();
        }

    }, [])
    
    /* ----------------------------------------
    * useEffect
    * 矢印のドラッグ禁止処理
    ----------------------------------------*/
    useEffect(()=>{

        //矢印エレメントが無い時はスキップ
        if(!arrowRef_R.current || !arrowRef_L.current)
            return

        const dragEvent = (evn)=>{
            evn.preventDefault()
            return false
        }
        const arrows = [arrowRef_R.current, arrowRef_L.current]

        arrows.map((arrow, index) => {
            arrow.addEventListener('dragstart', dragEvent, { passive: false })
            arrow.addEventListener('touchmove', dragEvent, { passive: false })

            return false
        })
        
        return () => {
            arrows.map((arrow, index) => {
                arrow.removeEventListener('dragstart', dragEvent)
                arrow.removeEventListener('touchmove', dragEvent)
                
                return false
            })
        }

    }, [])

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{

        if(renderRoutePath === current_route_path.pathname)
            return

        //リストを初期化
        setprofileDatas([])
        setNextLoadIndex(0)

        //現在のパスを設定
        setRenderRoutePath(current_route_path.pathname)

        //loadListを実行するために加算
        setLoadListCounter(loadListCounter + 1)

        //スライドをリセット
        ref.current.goToSlide(0)
        
    },[current_route_path, renderRoutePath, loadListCounter])

    //loadList用フック
    useEffect(()=>{
        
        if(loadListCounter === currentLoadListCounter)
            return
            
        setCurrentLoadListCounter(loadListCounter)

        loadList()
        
    }, [loadListCounter, currentLoadListCounter, loadList]);


    //左右矢印
    const arrowContent = useMemo(()=>{

        const query_search = queryString.parse(current_route_path.search);

        if(query_search["single"])
            return null

        return (
            <>
                <img alt="arrow_r" className={ classes.arrow_r } src='/arrow-r.svg' onClick={ ()=>{onArrowClick(+1)} } ref={ arrowRef_R } />
                <img alt="arrow_l" className={ classes.arrow_l + (slideIndex === 0 ? ` ${classes.hide}` : ``) } src='/arrow-r.svg' onClick={ ()=>{onArrowClick(-1)} } ref={ arrowRef_L } />
            </>
        )
    }, [onArrowClick, slideIndex, current_route_path.search, ])

    /* ----------------------------------------
    * return
    ----------------------------------------*/
    return (
        <Box>

            {helmetDescription && helmetTitle && <HelmetProvider>
                <Helmet>
                   <meta
                        name="description"
                        content={`${helmetDescription}`}
                    />
                    <title>{`${helmetTitle}`}</title>
                </Helmet>
            </HelmetProvider>}


            {isLoading && <LinearProgress sx={{ position: 'absolute', left:0, right:0, top: 0, margin: 'auto', zIndex: 10000, }} />}

            { (!isLoading && profileDatas.length === 0) && <Box sx={{
                    position: 'absolute', left:0, right:0, top: 0, 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                <div>何も見つかりませんでした</div>
            </Box> }

            { arrowContent }


            { profileDatas.length === 0 &&
                <>
                    <CardHeader
                        avatar={ <Skeleton animation="wave" variant="circular" width={70} height={70} /> }
                        title={ <Skeleton animation="wave" height={30} width="80%" sx={{ ml: 3 }} />}
                    />


                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3,  }}>
                        <Skeleton animation="wave" height={30} width="23%" />
                        <Skeleton animation="wave" height={30} width="23%" />
                        <Skeleton animation="wave" height={30} width="23%" />
                    </Box>

                    <Skeleton animation="wave" height={10} width="80%" sx={{ m: 3, ml: 2 }} />

                    <Skeleton sx={{ height: 190, mb: 1 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 190, mb: 1 }} animation="wave" variant="rectangular" />
                </>
            }
            

            <div className={classes.backbt} onClick={()=>history.goBack()}><ArrowBackIosNewIcon /></div>

            <Carousel
            ref={ref}
            transitionDuration={200}
            minimumTouchDrag={50}
            afterChange={ afterChange }
            >
                { profileDatas.filter(data => data.loaded).map((data, index)=>{

                    return <View key={data.id} uuid={ data.id } {...data} />
                })}
                
            </Carousel>
        </Box>
    );
};

export default ViewList;