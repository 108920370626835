import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import classes from './BottomMenu.module.scss'
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState, } from "react"
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useAuthContext } from '../context/lib/AuthContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { doc, getDoc } from 'firebase/firestore';
import { firebaseDB } from './lib/firebase';
import { FirestoreMap$users, FirestoreMap$users_readonly } from './lib/FirestoreMap';
import { useLocation } from 'react-router-dom';


const BottomNavigationAction2 = styled(BottomNavigationAction)(({ theme }) => ({
    
    '.MuiBottomNavigationAction-label': {
        fontSize: 11,
        whiteSpace: 'nowrap',
        color: "#2D2D2D",
    },
    '.MuiSvgIcon-root': {
        color: "#2D2D2D",
    },
    '.Mui-selected' : {
        fontSize: '80px !impotant',
        color: '#000',
        fill: '#000',
        fontWeight: 'bold',
    }
    
}));


const BottomMenu = ({ component: Component, ...rest }) => {
    
    
    const [navigationValue, setNavigationValue] = useState("home")
    const { user } = useAuthContext();
    const [userId, setUserId] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const rootRef = useRef(null)
    const current_route_path = useLocation();

    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined) return

        const init = async ()=>{

            //ログインしていない時
            if(user.current === null) return

            setUserId(user.current.uid)

            // 読み取り専用データを取得
            const userDoc = await getDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readonly, user.current.uid))
            if(!userDoc.exists()) return

            const userData = userDoc.data()
            if(userData === undefined) return

            setIsAdmin(userData.isAdmin)
        }
        init()

        
    }, [user])

    // ナビゲーションの選択状態を更新
    useEffect(()=>{
        if(rootRef.current === null) return

        console.log(current_route_path.pathname)
        rootRef.current.querySelectorAll(".MuiBottomNavigationAction-label").forEach(element => {
            element.classList.remove("Mui-selected")

            console.log(element.parentNode.getAttribute("href"))

            
                
            
            if(
                // ホームの時は完全一致
                (current_route_path.pathname === "/" && "/" === element.parentNode.getAttribute("href")) ||
                // elementのattribute hrefとcurrent_route_path.pathnameが一致したら選択状態にする
                (current_route_path.pathname !== "/" && element.parentNode.getAttribute("href").startsWith(current_route_path.pathname))
            ){
                element.classList.add("Mui-selected")
            }

        });
        
    }, [current_route_path.pathname])


    return (
        
        <Paper elevation={3} className={ classes.root } ref={rootRef}>

                <BottomNavigation
                    showLabels
                    value={ navigationValue }
                    onChange={(event, newValue) => {
                        //TODO:選択中の制御が難しいので自前のコンポーネントを作成する
                        setNavigationValue(newValue)
                    }}
                    sx={{
                        position: 'relative',
                        zIndex: 200,
                    }}
                    className={ classes.navi }
                    >

                    <BottomNavigationAction2 value="home" sx={{minWidth: ""}} icon={<HomeIcon />} label="ホーム" component={ Link } to={`/${window.location.search}`} />

                        
                    {/* <BottomNavigationAction2 value="news" sx={{minWidth: ""}} component={ Link } to="/news-box"
                    className={classes.news}
                    icon={
                        <div className={classes.anchor}>
                            <NotificationsOutlinedIcon className={ classes.icon } />
                        </div>
                    }
                    label={
                        <>
                            お知らせ
                            {(newsCount !== undefined && newsCount !== 0) && <div className={classes.num}><div>{ newsCount }</div></div>}
                        </>
                    }
                    /> */}

                    <BottomNavigationAction2 value="myshop" sx={{minWidth: ""}} icon={<StorefrontIcon />} label="マイショップ" component={ Link } to={`/shop/${userId}?q=test&single=1`} className={`${userId ? '' : classes.disabled}`} />

                    <BottomNavigationAction2 value="profile" sx={{minWidth: ""}} icon={<AccountCircleIcon />} label="編集" component={ Link } to="/profile/edit" />

                    <BottomNavigationAction2 value="account" sx={{minWidth: ""}} icon={<AccountCircleIcon />} label="アカウント" component={ Link } to="/account/edit" />
                    
                    { isAdmin && <BottomNavigationAction2 value="admin" sx={{minWidth: ""}} icon={<AdminPanelSettingsIcon />} label="管理者" component={ Link } to="/admin/shoplist" /> }
                    

                </BottomNavigation>
                
        </Paper>
    );
};

export default BottomMenu;