import classes from './AppLayout.module.scss'



const AppLayout = ({ component: Component, props }) => {
    
    return <>
        <div id="app_root" className={ classes.root }>

            <div className={ classes.box }>
                <Component {...props} />
            </div>
        </div>
        <div className={ classes.copylight }>© 2020-{new Date().getFullYear()} collabo inc.</div>
    </>;

};

export default AppLayout;