import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Polyline, useMap } from "react-leaflet";
import axios from "axios";
import { appLib } from "./lib/_app_lib";
import { Box } from "@mui/material";
import dayjs from "dayjs";


const RouteMap2 = (props) => {
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [center, setCenter] = useState([35.6528, 139.8395]);
  const [durationText, setDurationText] = useState('')

  useEffect(() => {

    (async () => {

      const location = await appLib.GetLocation()
      

      console.log(location)

      if(location.status){
        
        const startLocation = [...location.pos].reverse(); // [緯度, 経度]
        const endLocation = [props.match.params.geocode2, props.match.params.geocode1].map(Number); // [緯度, 経度]
        const centerLocation = [
          (startLocation[1] + endLocation[1]) / 2,  // 経度
          (startLocation[0] + endLocation[0]) / 2, // 緯度
        ];
        setCenter(centerLocation)

        // OpenRouteService APIのエンドポイント
        const url = `https://api.openrouteservice.org/v2/directions/driving-car`;
        

        // 東京から大阪までのルート探索
        axios
          .get(url, {
            params: {
              start: startLocation.join(","), // 経度、緯度の順に変換
              end: endLocation.join(","),
            },
            headers: {
              Authorization: process.env.REACT_APP_OPENROUTESERVICE_API_KEY, 
            },
          })
          .then((response) => {
            console.log(response)
            // ルートの座標を取得
            const coordinates = response.data.features[0].geometry.coordinates;
            setRouteCoordinates(coordinates.map((c) => c.reverse())); // 緯度、経度の順に変換

            const duration = response.data.features[0].properties.segments[0].duration; // 所要時間（秒）
            setDurationText(`${dayjs.duration({ seconds: duration }).humanize()} `)
          })
          .catch((error) => console.error(error));
      }

    })()

    
  }, [props.match.params.geocode1, props.match.params.geocode2]);


  const ChangeView = ({ center, zoom }) => {

    const map = useMap();
    useEffect(() => {
      map.setView(center, zoom);
    }, [center, zoom, map]); // Dependencies for the useEffect to run
    
    return null;
  }

  return (
    <>
    <MapContainer center={center} zoom={6} style={{ width: "100%", height: "100%" }}>
      <ChangeView center={center} zoom={13} />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {routeCoordinates.length > 0 && <Polyline positions={routeCoordinates} color="blue" />}
    </MapContainer>
    <Box sx={{ textAlign:'center', position: 'absolute', bottom: '50px', left: '10px', right: '10px', zIndex: 9999, background: '#0096f7', color:'#fff', fontSize:'1.5em', fontWeight:900, padding: 2, borderRadius: 1, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>
          所要時間：{durationText}
      </Box>
    </>
  );
};

export default RouteMap2;
