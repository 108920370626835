
import { doc, setDoc, } from "firebase/firestore";
import { firebaseDB } from "./firebase";
import {appLib} from './_app_lib'
import { FirestoreMap$users, FirestoreMap$users_writeonly, } from "./FirestoreMap";


/* -----------------------------------------------
* fcmInit
* プッシュ通知の初期化
----------------------------------------------- */
export const fcmInit = async (user, isPermissionAlert = false) => {

    // 匿名の時はスキップ
    if(user.current.isAnonymous)
        return

    //通知許可
    const permission = await appLib.FcmRequestPermission();
    console.log(permission)
    if(!permission.enabled)
    {
        if(isPermissionAlert)
            alert("通知が有効になっていません\nスマートフォンの設定アプリを開き通知を有効にしてください")
    }

    //トークンを取得
    const fcmToken = await appLib.GetFcmToken();
    console.log(`fcmToken.token=${fcmToken.token}`)
    
    if(fcmToken.token)
    {
        //ユーザーデータに保存
        await setDoc(
            doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_writeonly, user.current.uid),
            { fcmToken : fcmToken.token },
            { merge: true }
        )
    }
}
