
import classes from './Home2.module.scss';
import { useEffect, useRef, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.css";
import FixedLayout from './lib/FixedLayout';
import { useAuthContext } from '../context/lib/AuthContext';
// import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
// import { firebaseDB } from './lib/firebase';
// import { FirestoreMap$profiles } from './lib/FirestoreMap';
import ShopMap2 from './ShopMap2';
import ShopSearch2 from './ShopSearch2';
import SearchResult2 from './SearchResult2';
import HomeMenu from './HomeMenu';
import * as Scroll from 'react-scroll';
import SearchIcon from '@mui/icons-material/Search';
import sleep from './lib/sleep';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const searchList = [
    "ハンバーガー",
    "丼",
    "からあげ",
    "ピザ",
    "焼き鳥",
    "ソフトクリーム",
    "コーヒー",
    "サンドイッチ",
    "パン",
    "クレープ",
]

const Home = (props) => {
    
    const rootRef = useRef(null)
    const [isShowDownload, setIsShowDownload] = useState(false)
    const {user} = useAuthContext()
    const [searchShops, setSearchShops] = useState([])
    const [isShowSeachResult, setIsShowSeachResult] = useState(false)
    const [searchWord, setSearchWord] = useState("")
    const [searchCategory, setSearchCategory] = useState()
    const [searchState, setSearchState] = useState(0)
    const [isShowResultList, setIsShowResultList] = useState(false)
    const [adjustmentCenter, setAdjustmentCenter] = useState(true)
    const [isApp, setIsApp] = useState(false)

    /*useEffect(()=>{

        const init = async ()=>{

            const snapShot = await getDocs(query( collection(firebaseDB, FirestoreMap$profiles), limit(10), where("state", "==", "active-test"), orderBy("approvalDate", "desc")))
            const _newShopList = []
            for(const doc of snapShot.docs)
            {
                _newShopList.push({
                    id: doc.id,
                    icon: doc.data().icon || `/profile/shop.png`,
                    ...doc.data()
                })
            }
            console.log(_newShopList)
        }
        init()

    }, [])*/


    // ダウンロードボタン表示
    useEffect(() => {

        if (!rootRef?.current?.container?.current) return

        console.log(rootRef.current.container.current)

        // アプリブラウザ判定
        const isSpApp = window.navigator.userAgent.toLowerCase().trim().includes("collabo-app-browser")

        let isShow = false
        const scrollEvent = (e) => {
            
            // アプリの時はスキップ
            if(isSpApp) return

            if(e.target.scrollTop > e.target.offsetHeight && e.target.scrollTop < e.target.scrollHeight - (e.target.offsetHeight * 2))
            {
                if(!isShow){
                    isShow = true
                    console.log("show")
                    // setIsShowDownload(true)  //TODO: 一旦非表示
                }
                
            }else{

                if(isShow){
                    isShow = false
                    console.log("hide")
                    setIsShowDownload(false)
                }
            }
        }

        const targetElm = rootRef?.current?.container?.current
        targetElm.addEventListener("scroll", scrollEvent)

        return ()=>{
            targetElm.removeEventListener("scroll", scrollEvent)
        }
    }, [])

    const handleSearch = (word, center)=>{
        setSearchWord(word)
        setIsShowSeachResult(true)
        setSearchState(new Date().getTime())
        setAdjustmentCenter(center)
    }

    useEffect(()=>{
        // アプリブラウザ判定
        const ua = window.navigator.userAgent.toLowerCase().trim();
        setIsApp(ua.includes('collabo-app-browser'))
    }, [])

    // ブラウザチェック
    const handleCheckBrowser = (e)=>{

        // アプリでは無い時
        if(!isApp){
            const ua = window.navigator.userAgent.toLowerCase().trim();
            if(ua.includes("iphone")){
                e.preventDefault();
                window.location.href="https://apps.apple.com/us/app/eat-plus-%E3%82%A4%E3%83%BC%E3%83%88%E3%83%97%E3%83%A9%E3%82%B9/id6450914531"
            } else {
                alert("Android版ただいま準備中です、お楽しみに！")
            }
        }
    }

    return (
        <>
            <div className={classes.map}>
                <div className={classes.logo}><img src="/logo.svg" alt="bg" /></div>
                <HomeMenu />
                
                <ShopMap2 shopList={searchShops} onMoveEnd={ ()=>handleSearch("*", false) } adjustmentCenter={adjustmentCenter} />
                <SearchResult2 shopList={searchShops} onClosed={()=>setIsShowSeachResult(false)} show={isShowSeachResult} onApply={()=>console.log("onApply")} limit={4} isShowResultList={isShowResultList} horizontalShopList={false} horizontalCateList={true} onCateClick={(cate)=>setSearchCategory(cate)}>

                    <ShopSearch2
                        searchWord={searchWord}
                        searchCategory={searchCategory}
                        onCallBack={(data, isInital)=>{

                            setIsShowResultList(true)
                            setIsShowSeachResult(true)
                            setSearchShops(data)
                        }}
                        stateCheck searchState={searchState}
                        onFocus={()=>{
                            setIsShowResultList(false)
                        }}
                        onBlur={async ()=>{
                            await sleep(.1)
                            setIsShowResultList(true)
                            Scroll.animateScroll.scrollTo(0, {

                                containerId: 'search_result_container',
                                smooth: 'easeInOutSine',
                                duration: 500,
                            });
                        }}
                    />
                    

                    {!isShowResultList && <div className={classes.queies_list}>
                            {searchList.map((item, index)=><div key={index} onClick={()=>{handleSearch(item, true)}}>
                                <SearchIcon /> {item}
                        </div>)}
                    </div>}
                    
                    <br /><br />
                    <br /><br />
                    <img src="/home/info.png" alt="info" style={{width: '100%'}} />
                

                    <div className={ classes.exp } style={{display: 'none'}}>
                        <div className={ classes.content }>
                            {false && // アプリダウンロードのリンクはオミット中
                            !isApp && <><div className={classes.download_bt} onClick={handleCheckBrowser} >
                                アプリをダウンロード
                            </div>
                            Android版は開発中です<br /><br /><br />
                            </>}
                            {/* <Link2 className={ classes.exp_bt } href="/recruit-details" >店舗の登録はこちらから</Link2> */}
                        </div>
                    </div>

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <ArrowUpwardIcon onClick={() => 
                            Scroll.animateScroll.scrollTo(0, {

                                containerId: 'search_result_container',
                                smooth: 'easeInOutSine',
                                duration: 500,
                            })   
                        } />
                    </div>
                    <br /><br />
                    <br /><br />
                </SearchResult2>
            </div>
                    

                

            {/* アプリでは無い時 */}
            <FixedLayout><div className={ `${classes.download_app} ${(isShowDownload && !user?.current?.uid) ? classes.show : ''}` }>
                <div>
                    <img src="/appstore-download.svg" alt="appstore" className={ classes.ios } onClick={()=>{window.location.href="https://apps.apple.com/us/app/eat-plus-%E3%82%A4%E3%83%BC%E3%83%88%E3%83%97%E3%83%A9%E3%82%B9/id6450914531"}}/>
                </div>
                <div>
                    <img src="/google-play-badge.svg" alt="appstore" className={ classes.android } onClick={()=>alert("Android版ただいま準備中です、お楽しみに！")} />
                </div>
            </div></FixedLayout>

        </>
    );
};

export default Home;