import classes from "./AccountEditMenu.module.scss";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Link2 from "./Link2";
import { useAuthContext } from '../../context/lib/AuthContext';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { firebaseDB, firebaseFunctions, } from "./firebase";
import { httpsCallable } from "firebase/functions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useHistory } from "react-router-dom";
import { AccountEditMenuUnsubscribeDialog } from "../AccountEditMenuCustomize";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FixedLayout from './FixedLayout'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { doc, getDoc } from "firebase/firestore";
import { FirestoreMap$users, FirestoreMap$users_readonly } from "./FirestoreMap";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const functions = {
    sendUnsubscribeAccount : httpsCallable(firebaseFunctions, 'sendUnsubscribeAccount'),
}

const LinkMenuItem = (props) =>{

    if(props.show === false)
        return null

    return (
        <Link2 className={classes.item} href={ props.href } onClick={ props.onClick }>
            <props.icon className={classes.icon} />{ props.label }
            <ArrowForwardIosOutlinedIcon className={classes.arrow} />
        </Link2>
    )
}

const AccountEditMenu = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const { user } = useAuthContext()
    const [unsubscribePopup, setUnsubscribePopup] = useState(false)
    const history = useHistory()
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        if(!user.current)
            return

        const init = async ()=>{

            // 読み込みを完了させる
            setIsLoading(false)

            //　非同期で管理者かどうかを取得
            const userReadOnlyDoc = await getDoc(doc(firebaseDB, FirestoreMap$users, user.current.uid, FirestoreMap$users_readonly, user.current.uid))
            if(userReadOnlyDoc.exists() && userReadOnlyDoc.data().isAdmin){
                setIsAdmin(true)
            }

            
        }
        init()

    }, [user, ])

    /* -----------------------------------------------
    * handleUnsubscribeConfirm
    ----------------------------------------------- */
    const handleUnsubscribeConfirm = async()=>{

        setUnsubscribePopup(true)
    }

    /* -----------------------------------------------
    * handleUnsubscribe
    ----------------------------------------------- */
    const handleUnsubscribe = async(data)=>{

        const checkboxes = document.querySelectorAll(`.${classes.unsubscribe_popup} .${classes.checkbox} input[type=checkbox]`)
        let checkedCount = 0
        checkboxes.forEach((elm, index) => {
            if(elm.checked)
                checkedCount++
        })

        if(checkedCount !== 1)
        {
            alert("内容に同意したらチェックを入れてください")
            return
        }

        setIsLoading(true)
        const ret = await functions.sendUnsubscribeAccount(data)

        console.log(ret)

        if(ret && ret.data && ret.data.status){

            history.push('/unsubscribe/success')

        }else {

            alert("失敗しました。しばらく待ってから再度お試しください")
            window.location.href = "/"
        }
    }
    

    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <>


            <div className={ classes.header }>
                <div className={ classes.title }>
                    アカウント管理
                </div>
            </div>

            <div className={ classes.root }>
                {isLoading && 
                    <FixedLayout><Box sx={{
                        position: 'fixed', left:0, right:0, top:0, bottom: 0,
                        margin: 'auto', zIndex: 10000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#ffffff99', }}>
                        <CircularProgress />
                    </Box></FixedLayout>
                }


                <div className={classes.list}>
                    
                <br />
                    <br />
                    <LinkMenuItem icon={NotificationsActiveOutlinedIcon} href="/notification/edit" label="通知設定" />

                    <br />
                    <br />
                    <LinkMenuItem icon={VpnKeyIcon} href="/password/edit" label="パスワード設定・変更" />
                    

                    {isAdmin && <>
                        <br />
                        <br />

                        <div className={classes.label}>管理者メニュー</div>
                        <LinkMenuItem icon={PersonAddIcon} href="/admin/add_user" label="ユーザーの追加" />

                    </>}

                    <hr />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div className={classes.label}>その他</div>
                    <LinkMenuItem icon={HighlightOffIcon} onClick={handleUnsubscribeConfirm} href="#" label="サービスの退会" />

                    <hr />
                    <div style={{margin: "50px auto"}} className={classes.button} onClick={()=>setShowLogoutConfirm(true)}>ログアウト</div>

                </div>

                
                {/* ログアウト確認 */}
                <Dialog
                    open={ showLogoutConfirm }
                    onClose={ () => setShowLogoutConfirm(false) }
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                            ログアウトしても宜しいですか？
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setShowLogoutConfirm(false)}>キャンセル</Button>
                        <Button onClick={()=>history.push("/logout")} color="error" >ログアウト</Button>
                    </DialogActions>
                </Dialog>

                {/* 退会確認ポップアップ */}
                <AccountEditMenuUnsubscribeDialog onUnsubscribe={handleUnsubscribe} show={unsubscribePopup} onClose={()=>setUnsubscribePopup(false)} />
            </div>
        </>
};

export default AccountEditMenu;