import classes from './AppSupport.module.scss'
import { Helmet, HelmetProvider } from "react-helmet-async";

const AppSupport = (props) => {
    
    const img_path = window.location.pathname.includes("consumer") ? "app-support-consumer" : "app-support"

    return (
        <div className={ classes.root }>

            <HelmetProvider>
                <Helmet>
                   <meta
                        name="description"
                        content="お寺のお葬式 アプリ サポートページ"
                    />
                    <title>アプリ サポートページ | お寺のお葬式</title>
                </Helmet>
            </HelmetProvider>


            <img src={`/logo.svg`} alt="お寺のお葬式" className={classes.logo} />
            <hr />
            <h2>アプリ サポートページ</h2>
            <p>
                こちらは「お寺のお葬式」アプリ、サポートページです。<br />
                This is the support page.<br />
                <br />
                アプリに関する問い合わせはこちらで受け付けております。<br />
                If you have any questions about the application, you can contact us here.<br />
                <br />
                <a href="mailto:sales@shapeplanning.jp">sales@shapeplanning.jp</a>
            </p>
            <br />
            <h2>スクリーンショット</h2>
            <div className={classes.sceenshots}>
                {Array(3).fill(0).map((item, index)=>(
                    <div key={index} className={classes.item}>
                        <a href={`/${img_path}/${index+1}.png`} target='_blank' rel="noreferrer">
                            <img src={`/${img_path}/${index+1}.png`} loading="lazy" alt="screenshot" />
                        </a>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default AppSupport;