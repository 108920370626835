import React from 'react';

const throttle = require('lodash.throttle');

export class InfiniteScrollVertical extends React.Component {

  constructor(props) {
    super(props)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this._ref = React.createRef();
    this._scrollHeight = 0;
  }

  scrollHandler = () => {}

  resizeHandler = () => {}

  componentDidMount() {

    console.log("inf.componentDidMount");
    this.scrollHandler = throttle(this.checkWindowScroll, this.props.throttle);
    this.resizeHandler = throttle(this.checkWindowScroll, this.props.throttle);

    this._ref.current.addEventListener('scroll', this.scrollHandler);
    this._ref.current.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    this._ref.current.removeEventListener('scroll', this.scrollHandler);
    this._ref.current.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    this.scrollHandler();

    //変更が無い時はスキップ
    if(this.props.scrollParamCounter === prevProps.scrollParamCounter)
      return;

    //イベント発火スクロール位置をリセット
    this._scrollHeight = window.innerHeight



  }

  checkWindowScroll = () => {

    // console.log("Window height " + window.innerHeight + " Sentinel boundingRectTop " + this.sentinel.getBoundingClientRect().top)

    if (
      this.props.hasMore &&
      this._scrollHeight < this._ref.current.scrollHeight &&
      this.sentinel.getBoundingClientRect().top - window.innerHeight <
      this.props.threshold
    ) {

      //イベント発火スクロール位置を記録
      this._scrollHeight = this._ref.current.scrollHeight;

      this.props.onLoadMore();
    }
  }

  render() {
    const sentinel = <div ref={i => this.sentinel = i} />;

    // if(this.props.render) {
    //   return this.props.render({
    //     sentinel,
    //     children: this.props.children
    //   });
    // }

    // if(this.props.component) {
    //   const Container = this.props.component;
    //   return (
    //     <Container sentinel={sentinel}>
    //       {this.props.children}
    //     </Container>
    //   );
    // }

    const divStyle = {
      overflow: 'scroll',
      position: this.props.noFixed ? 'none' : 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };

    return (
      <div style={divStyle} ref={ this._ref } className={ this.props.className } id={ this.props.id }>
        {this.props.children}
        {sentinel}
      </div>
    );
  }
}