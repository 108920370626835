import { createContext, useState, useContext, } from 'react';

const CartContext = createContext();

export function useCartContext() {
    return useContext(CartContext);
}

export function CartProvider({ children }) {
    const [items, setItems] = useState([]);
    const [uuid, setUuid] = useState(0); // uuidのstateを追加
    
    const addItem = (item) => {
        const existingItemIndex = items.findIndex(i => i.uuid === item.uuid);
        if (existingItemIndex !== -1) {
            // 既存のアイテムがある場合は、そのアイテムを更新
            setItems(prevItems => prevItems.map((i, index) => index === existingItemIndex ? { ...i, ...item } : i));
        } else {
            // 新しいアイテムを追加
            setUuid(prevUuid => prevUuid + 1);
            setItems(prevItems => [...prevItems, { ...item, uuid: `${uuid + 1}` }]);
        }
    };

    const removeItem = (itemId) => {
        setItems(prevItems => prevItems.filter(item => item.uuid !== itemId));
    };

    const clearCart = () => {
        setItems([]);
    };

    const value = {
        items,
        addItem,
        removeItem,
        clearCart,
    };

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
}