import { useState, useCallback, } from 'react'
import Cropper from 'react-easy-crop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const makeThumbnail = (file, croppedArea, maxSize) => {

    return new Promise((resolve) => {
        try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.src = file;

            img.onload = () => {
                // クリッピングエリアがmaxSizeより大きい場合はスケールダウンし、
                // maxSizeより小さい場合はスケールアップする
                let scale = 1;
                if (croppedArea.width > maxSize.width || croppedArea.height > maxSize.height) {
                    scale = Math.min(maxSize.width / croppedArea.width, maxSize.height / croppedArea.height);
                } else if (croppedArea.width < maxSize.width || croppedArea.height < maxSize.height) {
                    scale = Math.min(maxSize.width / croppedArea.width, maxSize.height / croppedArea.height);
                }

                // スケール後のクリッピングエリアのサイズと位置を計算
                const scaledCroppedArea = {
                    x: croppedArea.x * scale,
                    y: croppedArea.y * scale,
                    width: croppedArea.width * scale,
                    height: croppedArea.height * scale
                };

                canvas.width = scaledCroppedArea.width;
                canvas.height = scaledCroppedArea.height;

                ctx.drawImage(img, -scaledCroppedArea.x, -scaledCroppedArea.y, img.width * scale, img.height * scale);
                
                canvas.toBlob((blob) => {

                    resolve([
                        canvas.toDataURL('image/jpeg', 0.5),
                        blob
                    ]);
                });
            };

            img.onerror = () => {
                throw new Error('error');
            };

        } catch (err) {
            console.error(err);
            resolve(false);
        }
    });
};



  
const CropWindow = (props) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedArea, setCroppedArea] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
  
    const onCropComplete = useCallback((_croppedArea, _croppedAreaPixels) => {
        console.log(_croppedArea)
        console.log(_croppedAreaPixels)
        setCroppedArea(_croppedAreaPixels)
    }, [])
  
    if(!props.show)
        return null

    return (
        <Box sx={{
            position:'absolute',
            zIndex:10000, 
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#00000066',
         }}>
            <Cropper
                image={props.src}
                crop={crop}
                zoom={zoom}
                aspect={3 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape={props.cropShape || 'round'}
            />

            <Button sx={{ backgroundColor:'#3089ff !important', position: 'absolute', zIndex:10000, left:0, right:0, bottom: '10%', margin:'auto', width:'70%', p:"10px !important", fontSize:"18px !important"}} size="large" variant="outlined"
                onClick={async ()=>{

                    const [croppedImg, croppedImgBlob] = await makeThumbnail(props.src, croppedArea, props.maxSize)
                    props.onChange(croppedImg, croppedImgBlob)

            }}>OK</Button>
      </Box>
    )
}


export default CropWindow;