import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import { firebaseDB as db} from "./lib/firebase";
import classes from './CallKitchenCar.module.scss';
import Footer from "./Footer";
import { useAuthContext } from '../context/lib/AuthContext';
import LoadingScreen from "./lib/LoadingScreen";


const CallKitchenCar = (props) => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [request, setRequest] = useState("");
    const history = useHistory();
    const { user } = useAuthContext()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{

        if(user.current === undefined) return

        const main = async () => {

        }
        main()


    },[user, history])


    const handlePost = async (e) => {
        e.preventDefault();
        
        setIsLoading(true)
    
        const callKitchenCarRef = collection(db, "call_kitchen_car");
    
        const newCallKitchenCar = {
            name: name,
            company: company,
            address: address,
            email: email,
            request: request,
        };
    
        // 入力が空の場合にエラーを表示して処理を中断します
        if(!name || !company || !address || !email || !request) {
            alert("全ての項目を入力してください");
            setIsLoading(false)
            return;
        }

        try {
            // 新しいcall_kitchen_carドキュメントを追加します。
            await addDoc(callKitchenCarRef, newCallKitchenCar);
    
            history.push("/call-kitchen-car/complete");
        } catch (e) {
            setIsLoading(false)
            console.error("Error adding documents: ", e);
            alert("エラーが発生しました。時間をおいて再度お試しください。")
        }
    
    };
    
    

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleCompany = (e) => {
        setCompany(e.target.value);
    };

    const handleAddress = (e) => {
        setAddress(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handleRequest = (e) => {
        setRequest(e.target.value);
    };

    return (
        <div className={classes.root}>
            <HelmetProvider>
                <Helmet>
                <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>応募フォーム | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            <LoadingScreen show={isLoading} />

            <div className={classes.header}>
                <img src="/call-kitchen-car/header.jpg" alt="main" />
                <div className={classes.title}>
                    キッチンカーを呼びたい方へ
                </div>
            </div>
            
            <div className={classes.intro}>
                <div className={classes.text}>
                イベントを盛り上げる、ユニークでオリジナリティあふれるメニューのキッチンカーを探しているのであれば、ここで一緒に探しましょう！何か疑問点や質問があれば、いつでもお気軽にお声がけください。ユニークで楽しいキッチンカーが、ここで待っています！
                </div>
            </div>
            
            <form className={classes.form} onSubmit={handlePost}>

                <div className={classes.item}>
                    <div className={classes.label}>お名前</div>
                    <input className={classes.input} placeholder="きっちん　たろう" name="name" value={name} onChange={handleName} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>会社名</div>
                    <input className={classes.input} placeholder="イートプラス" name="company" value={company} onChange={handleCompany} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>イベント予定地</div>
                    <input className={classes.input} placeholder="石川県金沢市" name="address" value={address} onChange={handleAddress} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>希望のキッチンカー</div>
                    <textarea className={classes.multiline} placeholder="クレープ、アイスクリーム、パスタ、たこ焼き、などご自由にお書きください" name="request" value={request} onChange={handleRequest} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>メールアドレス</div>
                    <input className={classes.input} placeholder="sales@eplus.com" name="email" value={email} onChange={handleEmail} />
                </div>
                <div className={classes.bt_container}>
                    <button className={classes.submit} type="submit">問い合わせする</button>
                </div>
            </form>
            <Footer />
        </div>
    );
};

export default CallKitchenCar;