import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { collection, doc, getDoc, writeBatch } from "firebase/firestore";
import { firebaseDB as db} from "./lib/firebase";
import classes from './Recruit.module.scss';
import Footer from "./Footer";
import { useAuthContext } from '../context/lib/AuthContext';
import LoadingScreen from "./lib/LoadingScreen";
import { FirestoreMap$profiles } from "./lib/FirestoreMap";


const Recruit = (props) => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const history = useHistory();
    const { user } = useAuthContext()
    const [isLoading, setIsLoading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("")

    useEffect(()=>{

        if(user.current === undefined) return

        const main = async () => {

            if(user.current.email) setEmail(user.current.email)
            if(user.current.phoneNumber){
                // 形式を変換する
                var number = user.current.phoneNumber
                number = number.replace('+81', '0');
                number = number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                setPhoneNumber(number)
            }

            //データを取得する
            const profileSnap = await getDoc(doc(db, FirestoreMap$profiles, user.current.uid))
                
            if(profileSnap.exists() && profileSnap.data().state !== "pending"){
                alert("お客様は既に承認済みです。マイページへ移動します。")
                history.push("/profile/edit/")
            }
        }
        main()


    },[user, history])

    const handlePost = async (e) => {
        e.preventDefault();
        
        setIsLoading(true)

        const recruitRef = collection(db, "recruit");
        const profileRef = collection(db, "profiles");

        const newRecruit = {
            name: name,
            company: company,
            address: address,
        };

        if(email) newRecruit.email = email
        if(phoneNumber) newRecruit.phoneNumber = phoneNumber

        for (const key in newRecruit) {
            if (newRecruit[key] === "") {
                alert("項目は全て記入ください");
                setIsLoading(false)
                return;
            }
        }

        const newProfile = {
            name: company,
            state: 'pending',
            isApp: window.navigator.userAgent.toLowerCase().trim().includes("collabo-app-browser"),
        };

        try {
            const batch = writeBatch(db);

            // 新しいrecruitドキュメントの参照を作成します。
            const newRecruitDocRef = doc(recruitRef, user.current.uid);

            // newRecruitを新しいドキュメントに書き込みます。
            batch.set(newRecruitDocRef, newRecruit);

            // 既存のprofileドキュメントの参照を作成します（ドキュメントIDとして現在のユーザーのUIDを使用）。
            const userProfileDocRef = doc(profileRef, user.current.uid);

            // newProfileを既存のドキュメントに追加します。
            batch.set(userProfileDocRef, newProfile);

            // バッチ操作をコミットします。
            await batch.commit();

            history.push("/recruit/complete");
        } catch (e) {
            setIsLoading(false)
            console.error("Error adding documents: ", e);
            alert("エラーが発生しました。時間をおいて再度お試しください。")
        }

    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleCompany = (e) => {
        setCompany(e.target.value);
    };

    const handleAddress = (e) => {
        setAddress(e.target.value);
    };


    return (
        <div className={classes.root}>
            <HelmetProvider>
                <Helmet>
                <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>応募フォーム | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            <LoadingScreen show={isLoading} />

            <div className={classes.header}>
                <img src="/recruit/header.svg" alt="main" />
            </div>
            
            <div className={classes.intro}>
                <div className={classes.text}>
                テイクアウト店オーナー募集!
自由な発想でクリエイティブなメニューを提供し、テイクアウトで食の魅力を発信しましょう。経験者も初心者も歓迎!食べ物のビジネスを始めるチャンスです。ぜひ参加してみてください。
                </div>
            </div>
            
            <form className={classes.form} onSubmit={handlePost}>

                <div className={classes.item}>
                    <div className={classes.label}>お名前</div>
                    <input className={classes.input} placeholder="きっちん　たろう" name="name" value={name} onChange={handleName} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>店舗名</div>
                    <input className={classes.input} placeholder="イートプラス" name="company" value={company} onChange={handleCompany} />
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>所在地</div>
                    <input className={classes.input} placeholder="石川県金沢市" name="address" value={address} onChange={handleAddress} />
                </div>
                {email && <div className={classes.item}>
                    <div className={classes.label}>メールアドレス</div>
                    <input className={classes.input} placeholder="sales@eplus.com" name="email" value={email} disabled />
                </div>}
                {phoneNumber && <div className={classes.item}>
                    <div className={classes.label}>電話番号</div>
                    <input className={classes.input} placeholder="sales@eplus.com" name="phoneNumber" value={phoneNumber} disabled />
                </div>}
                <div className={classes.bt_container}>
                    <button className={classes.submit} type="submit">登録する</button>
                </div>
            </form>
            <Footer />
        </div>
    );
};

export default Recruit;