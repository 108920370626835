import React from 'react';


export class Animator extends React.Component {

    /* -----------------------------------------------
    * constructor : コンストラクタ
    ----------------------------------------------- */
    constructor(props) {
        super(props)
        
        // this.props.ref2 -> ref だと上手く動作しなかった。予約文字なのかも
        this._ref = this.props.ref2 ? this.props.ref2 : React.createRef();

        this.state = {
            a: true,
        }

        this._animstep = this._animstep.bind(this);
    }

    /* -----------------------------------------------
    * _convert
    ----------------------------------------------- */
    _convert(params)
    {
        for(let key in params)
        {
            
            let val = params[key]

            switch(typeof(val))
            {
                case 'string':
                    const match = val.match(/^([-0-9]+)(.*)$/)
                    
                    val = { val: Number(match[1]), unit: match[2], }
                    break;

                case 'number':
                    val = { val: val, unit: null, }
                    break;

                default:
                    throw Error(`${val} typeof(${typeof(val)}) not suported!`);
            }

            params[key] = val;
        }

        return params;
    }

    /* -----------------------------------------------
    * componentDidMount
    ----------------------------------------------- */
    componentDidMount() {

        //開始データを設定
        this._start = this._convert(Object.assign({}, this.props.animate));

        //パラメーターを設定
        this._set_params();
        
        //スタイルを適応
        this._apply_style(1);

        // this._ref.current.parentElement.addEventListener('scroll', this.scrollHandler);
        // this._ref.current.parentElement.addEventListener('resize', this.resizeHandler);
    }

    /* -----------------------------------------------
    * componentWillUnmount
    ----------------------------------------------- */
    componentWillUnmount() {
        // this._ref.current.parentElement.removeEventListener('scroll', this.scrollHandler);
        // this._ref.current.parentElement.removeEventListener('resize', this.resizeHandler);
    }

    /* -----------------------------------------------
    * componentDidUpdate
    ----------------------------------------------- */
    componentDidUpdate(prevProps, prevState, snapshot) {

        // console.log(" + + + + + + + + + + + + + + + + + + + + + ")
        // console.log(prevProps)
        // console.log(prevState)
        // console.log(" + + + + + + + + + + + + + + + + + + + + + ")
        // this._ref.style.alpha =

        if(prevProps.animate !== this.props.animate)
        {
            //現在と過去のデータの入れ替え
            this._start = Object.assign({}, this._params_tmp);

            //パラメーターを設定
            this._set_params();

            //アニメーション開始
            this._requestAnimationFrameId = window.requestAnimationFrame(this._animstep);
        }

    }

    /* -----------------------------------------------
    * _set_params
    ----------------------------------------------- */
    _set_params()
    {
        //最初の状態を保存
        this._params_tmp = this._convert(Object.assign({}, this.props.animate));

        //データを初期化
        this._params = {};


        //開始位置から計算してデータを作成
        for(const key in this._params_tmp)
        {
            this._params[key] = {
                val : this._params_tmp[key].val - this._start[key].val,
                unit: this._params_tmp[key].unit,
            }
        }
    }

    /* -----------------------------------------------
    * _easeInOutSine
    ----------------------------------------------- */
    _easeInOutSine(x) {
        return -(Math.cos(Math.PI * x) - 1) / 2;
    }

    /* -----------------------------------------------
    * _animstep
    * アニメーション処理
    ----------------------------------------------- */
    _animstep(timestamp) {

        //オブジェクトが無い時はスキップ
        if(!this._ref.current)
          return;
  
        //開始時間の初期化
        if (!this._startTimestamp) this._startTimestamp = timestamp;
  
        //時間を計算
        var time = timestamp - this._startTimestamp;
        
        //アニメーション保管処理計算
        let val = this._easeInOutSine(time / this.props.transitionDuration);
        
        if(time > this.props.transitionDuration)
            val = 1.0;
  
  
        //アニメーションデータを時間で計算
        this._apply_style(val);
  

        //アニメーション時間内
        if (time < this.props.transitionDuration) {
  
            //もう一度アニメーション
            this._requestAnimationFrameId = window.requestAnimationFrame(this._animstep);
            
        } else {
  
            this._startTimestamp = undefined

            if(this.props.onComplete)
                this.props.onComplete()
        }
    }

    /* -----------------------------------------------
    * _apply_style
    ----------------------------------------------- */
    _apply_style(val)
    {
        const transformStyle = [];

        for(const key in this._params)
        {
            const from_param = this._start[key];
            const to_param = this._params[key];

            let val2 =  from_param.val + to_param.val * val;
            
            //transformの時
            if(key.startsWith('transform_'))
            {
                const trans_name = key.split('_')[1];

                //整数変換
                val2 = Math.round(val2)

                //単位を追加
                if(to_param.unit)
                    val2 += to_param.unit

                transformStyle.push(`${trans_name}(${val2})`);

                continue;
            }

            //単位を追加
            if(to_param.unit)
                val2 += to_param.unit

            //スタイルに設定する
            this._ref.current.style[key] = val2;

        }

        
        if(transformStyle.length > 0)
            this._ref.current.style.transform = transformStyle.join(" ")

    }

    /* -----------------------------------------------
    * render
    ----------------------------------------------- */
    render() {

        return (
            <div id={this.props.id} ref={ this._ref } className={this.props.className} onClick={ this.props.onClick }>
                {this.props.children}
            </div>
        );
    }
}