import { useHistory, } from 'react-router-dom';
import { getAuth, signInWithEmailLink, isSignInWithEmailLink,  } from "firebase/auth";
import { useEffect, useState, } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuthContext } from '../../context/lib/AuthContext';
import Link2 from './Link2';



const MailLogin = (props) => {

    const history = useHistory();
    const current_route_path = useLocation();
    const [ isError, setIsError ] = useState(false)
    const { user } = useAuthContext();
    const [isMount, setIsMount] = useState(false)
    const [userDisabled, setUserDisabled] = useState()


    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(() => {

        //ユーザー情報がとれていない時はスキップ
        if(!user)
            return

        //２度呼ばれないようにする
        if(isMount)
            return
        setIsMount(true)

        console.log("MailLogin")


        //リダイレクトパラメータを取得
        const param = JSON.parse(queryString.parse(current_route_path.search).param)

        


        const auth = getAuth()

        if (isSignInWithEmailLink(auth, window.location.href)) {


            //現状のアカウントとリンク
            signInWithEmailLink(auth, param.mail, window.location.href)
                .then(async (result) => {

                    console.log(result)


                    //コールバック
                    if(props.onCallback)
                        await props.onCallback(result.user, param.data)
                        

                    //リダレクト（ ✗ history.push → ○ window.location.href ：カートの反映遅延の対処）
                    window.location.href = decodeURIComponent(param.url);
                })
                .catch((error) => {
                    console.error(error)

                    //既にログイン済み
                    if(user && user.current)
                    {
                        //リダレクト
                        history.push(decodeURIComponent(param.url));
                        
                    } else {

                        // ユーザー無効
                        if(error.code === "auth/user-disabled")
                            setUserDisabled(true)

                        setIsError(true)
                    }
                })

        } else {

            
            console.error("not sign-in email link")

            //既にログイン済み
            if(user && user.current)
            {
                //リダレクト
                history.push(decodeURIComponent(param.url));

            } else {

                setIsError(true)
            }
        }

    }, [history, user, isMount, current_route_path.search, props, ])

    //エラーの場合
    if(isError)
    {
        // paramが存在する時は追加する
        const param = queryString.parse(current_route_path.search).param
        let loginPath = "/authLogin"
        if(param)
            loginPath += "?" + queryString.stringify( JSON.parse(param))

        return (
            <>
                <Box sx={{ padding: 5,  }}>
                    <img src="/logo.svg" style={{ width: '40%', maxWidth: 500  }} alt="logo" />
                </Box>
                
                <Box sx={{ backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Box sx={{ padding: 5,  }}>
                        { userDisabled && <>
                            アカウントが無効になっています。<br />
                            このアカウントでログインする場合は運営サポートまでご連絡ください。<br/>
                            <a href="mailto:sales@shapeplanning.jp">sales@shapeplanning.jp</a><br/>
                            <br/>
                            別のアカウントでログインするにはログインをタップしてください
                        </>}
                        { !userDisabled && <>URLが有効期限切れか間違えています。もう一度ログインをしなおしてください。</>}
                    </Box>
                    <Box sx={{m:2}} />

                    <Button component={ Link2 } href={loginPath} variant="contained" fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >ログイン</Button>


                </Box>
            </>
        )
    }

    return (

        
        <CircularProgress sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', zIndex: 10000, }} />
    );
};

export default MailLogin;