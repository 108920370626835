export const isEmail = (str) => {

    if(!str)
        return false

    const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    const match = str.match(re);
    return match && match[0] === str
}

export const isTel = (email, isSearch=false) =>
{
    if(!email)
        return false

    const re = /[0-9+\- ]{10,20}/;
    const isTest = re.test(String(email).toLowerCase());
    
    if(isSearch){
        return isTest;
    } else {
        const match = email.match(re);
        return match && match[0] === email;
    }
}