import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import classes from "./Recruit.module.scss";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { FirestoreMap$profiles } from "./lib/FirestoreMap";
import { useAuthContext } from "../context/lib/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { firebaseDB } from "./lib/firebase";
import { CircularProgress } from "@mui/material";

const RecruitComplete = () => {

  const { user } = useAuthContext()
  const [registActive, setRegistActive] = useState(false)

  useEffect(() => {

    if(user.current === undefined) return

    (async()=>{
      // firestore profilesを監視し、stateに変更があったら処理を実行
      onSnapshot(doc(firebaseDB, FirestoreMap$profiles, user.current.uid), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.state) {
            console.log("state change ... ", data.state)
            if(data.state === "active-test"){
              setRegistActive(true)
            }
          }
        } else {
          console.error('doc not found', FirestoreMap$profiles, user.current.uid);
        }
      });
    })()
  }, [user])

  return (
    <div className={classes.root}>
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="xxxx" />
          <title>応募完了 | 「お寺のお葬式」</title>
        </Helmet>
      </HelmetProvider>

      <div className={classes.header}>
        <img src="/recruit/header.svg" alt="main" />
      </div>

      

      <div className={classes.bt_container}>
        {!registActive && 
          <div className={classes.intro}>
            <div className={classes.text}>
              <p>
                <CircularProgress />
                <br />
                登録完了させています<br />
                しばらくお待ちください。
              </p>
            </div>
          </div>
        }
        {registActive && <>
          <div className={classes.intro}>
            <div className={classes.text}>
              <p>
                ご登録ありがとうございました！ <br />
                <br />
                出店場所やメニューを設定してお客様との新しい出会いを見つけましょう
              </p>
            </div>
          </div>
          <Link className={classes.submit} to="/profile/edit">
            編集ページへ
          </Link>
        </>}
      </div>
      <Footer />
    </div>
  );
};

export default RecruitComplete;