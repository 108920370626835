
import ReactDOM from 'react-dom';


const FixedLayout = ((props) => {

    /* ----------------------------------------
    * レンダリング全体
    ----------------------------------------*/

    return ReactDOM.createPortal(

            props.children

    , document.body
    );
    
});


export default FixedLayout;