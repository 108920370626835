import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from './Recruit.module.scss';
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";


const RecruitStart = (props) => {
    const history = useHistory()
    return (
        <div className={classes.root}>
            <HelmetProvider>
                <Helmet>
                <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>応募フォーム | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            <div className={classes.header}>
                <img src="/recruit/header.svg" alt="main" />
            </div>
            
            <div className={classes.intro}>
                <div className={classes.text}>
                テイクアウト店オーナー募集!
自由な発想でクリエイティブなメニューを提供し、テイクアウトで食の魅力を発信しましょう。経験者も初心者も歓迎!食べ物のビジネスを始めるチャンスです。ぜひ参加してみてください。
                </div>
            </div>
            
            <div className={classes.form}>
                応募するにはメールアドレスの認証が必要です
                <div className={classes.bt_container}>
                    <button className={classes.submit} onClick={()=>history.push(`/authLogin?url=${encodeURIComponent('/recruit')}&start=mail`)}>登録する</button>
                    
                    <Link to={`/authLogin?url=${encodeURIComponent('/profile/edit')}`}>登録済みの方はこちらから</Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RecruitStart;