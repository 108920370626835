import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDirectionsFactory from '@mapbox/mapbox-sdk/services/directions'
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language'; // eslint-disable-line import/no-extraneous-dependencies
import { appLib } from './lib/_app_lib';
import LoadingScreen from './lib/LoadingScreen';

// Mapboxの設定
mapboxgl.accessToken = 'pk.eyJ1IjoiYmVsY3JvZDVkNSIsImEiOiJjbG5hY3d6dWswMndvMnJvMG10eDh0eTd4In0.oTi93qIczDrYLQyDB5h1vA';
const directionsClient = MapboxDirectionsFactory({ accessToken: mapboxgl.accessToken })


const MapRoute3 = (props) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const zoom = 9;
    const [durationText, setDurationText] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (map.current) return;

        (async () => {

            const location = await appLib.GetLocation()


            if (location.status) {

                // 開始位置と終了位置
                const startLocation = [...location.pos].reverse(); // [緯度, 経度]
                const endLocation = [props.match.params.geocode2, props.match.params.geocode1].map(Number); // [緯度, 経度]
                const centerLocation = [
                    (startLocation[0] + endLocation[0]) / 2,  // 経度
                    (startLocation[1] + endLocation[1]) / 2, // 緯度
                ];
                

                map.current = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: centerLocation,
                    zoom: zoom
                });

                const language = new MapboxLanguage();
                map.current.addControl(language);



                map.current.on('load', async function () {
                    

                    const reqOptions = {
                        waypoints: [
                            { coordinates: startLocation },
                            { coordinates: endLocation },
                        ],
                        profile: 'driving',
                        geometries: 'geojson',
                    };


                    const res = await directionsClient.getDirections(reqOptions).send()
                    

                    // 経路取得
                    const route = res.body.routes[0].geometry;

                    // 所要時間
                    setDurationText(`${dayjs.duration({ seconds: res.body.routes[0].duration }).humanize()} `)


                    // 経路を地図に描画
                    map.current.addSource('route', {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: route
                        }
                    });

                    map.current.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': '#0096f7',
                            'line-width': 8
                        }
                    });

                    // 開始位置のマーカー
                    new mapboxgl.Marker({ color: 'green' })
                        .setLngLat(startLocation)
                        .addTo(map.current);

                    // 終了位置のマーカー
                    new mapboxgl.Marker({ color: 'red' })
                        .setLngLat(endLocation)
                        .addTo(map.current);

                    // 座標を指定して地図をフィットさせる
                    const bounds = new mapboxgl.LngLatBounds();
                        bounds.extend(startLocation);
                        bounds.extend(endLocation);
                        map.current.fitBounds(bounds, { padding: 50 }); 
                    });

                    setIsLoading(false)
            }

        })()

    }, [props.match.params.geocode1, props.match.params.geocode2]);


    return (
        <div>
            <LoadingScreen show={isLoading} />
            <div ref={mapContainer} className="map-container" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, }} />

            <Box sx={{ textAlign: 'center', position: 'absolute', bottom: '50px', left: '10px', right: '10px', zIndex: 9999, background: '#0096f7', color: '#fff', fontSize: '1.5em', fontWeight: 900, padding: 2, borderRadius: 1, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>
                所要時間：{durationText}
            </Box>
        </div>
    );
}

export default MapRoute3;