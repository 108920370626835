import classes from "./ProfileEdit.module.scss";
import { useEffect, useRef, useState, useCallback } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ValidateTextField from "./ValidateTextField";
import { FirestoreMap$profiles, FirestoreMap$users, FirestoreMap$users_readwrite, } from "./FirestoreMap";
import { useAuthContext } from '../../context/lib/AuthContext';
import { doc, setDoc, getDoc, updateDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firebaseDB, firebaseStorage } from "./firebase";
import { useHistory, } from "react-router-dom";
import { appLib } from './_app_lib';
import { ref, uploadBytes, getDownloadURL, } from "firebase/storage";
import CropWindow from './CropWindow';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Iframe from 'react-iframe'
import { logEvent, } from "./firebase";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import imageCompression from 'browser-image-compression';
import {fcmInit} from './FcmLib';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormControl, Select, MenuItem, Radio } from "@mui/material";
import copy from 'copy-text-to-clipboard';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const {v4: uuidv4 } = require('uuid');


const sects = ["浄土宗","浄土宗捨世派","西山浄土宗","浄土宗西山禅林寺派","浄土宗西山深草派","浄土真宗本願寺派","真宗大谷派","真宗高田派","真宗佛光寺派","真宗興正派","真宗木辺派","真宗出雲路派","真宗誠照寺派","真宗三門徒派","真宗山元派","東寺真言宗","高野山真言宗","真言宗善通寺派","真言宗醍醐派","真言宗御室派","真言宗大覚寺派","真言宗泉涌寺派","真言宗山階派","信貴山真言宗","真言宗中山寺派","真言三宝宗","真言宗須磨寺派","新義真言宗","真言宗智山派","真言宗豊山派","真言律宗","天台宗","天台寺門宗","天台真盛宗","日蓮宗","臨済宗建仁寺派","臨済宗建長寺派","臨済宗東福寺派","臨済宗妙心寺派","臨済宗円覚寺派","臨済宗南禅寺派","臨済宗大徳寺派","臨済宗国泰寺派","臨済宗向嶽寺派","臨済宗天龍寺派","臨済宗永源寺派","臨済宗方広寺派","臨済宗相国寺派","臨済宗佛通寺派","臨済宗興聖寺派","曹洞宗","法相宗","律宗","華厳宗","融通念仏宗","時宗","その他"];
const parkingOptions = ["あり", "なし"];

const ProfileEdit = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const inputContainerRef = useRef()
    const { user } = useAuthContext();
    const history = useHistory()
    const profileData = useRef({})
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])
    const [showCropWindow, setShowCropWindow] = useState()
    const [isNew, setIsNew] = useState()
    const currentEditImg = useRef()
    const currentImgMaxSize = useRef()
    const [notRegisterPopup, setNotRegisterPopup] = useState(false);
    const [notPendingPopup, setPendingPopup] = useState(false);
    const [currentAddress, setCurrentAddress] = useState()
    const [showRenewalPromptNotification, setShowRenewalPromptNotification] = useState(false)
    const [showUpdateComplete, setShowUpdateComplete] = useState(false)
    const [targetUid, setTargetUid] = useState()

    /* -----------------------------------------------
    * handleDragEnd : メニューのドラッグ
    ----------------------------------------------- */
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedImgs = Array.from(profileData.current.imgs || []);
        const [removed] = reorderedImgs.splice(result.source.index, 1);
        reorderedImgs.splice(result.destination.index, 0, removed);

        profileData.current.imgs = reorderedImgs
    };

    /* -----------------------------------------------
    * handleArrayDelete : 配列からデータを削除
    ----------------------------------------------- */
    const handleArrayDelete = (name, index) =>
    {
        console.log("delete", name, index)
        profileData.current[name].splice(index, 1)
        forceUpdate()
    }

    /* -----------------------------------------------
    * handleArrayAdd : 配列に新規追加
    ----------------------------------------------- */
    const handleArrayAdd = (name, data) =>
    {
        console.log("add", name)

        //無い時は作成する
        if(!profileData.current[name])
            profileData.current[name] = []

        data._uuid = uuidv4()
        profileData.current[name].push(data)
        forceUpdate()
    }


    /* -----------------------------------------------
    * handleSave
    ----------------------------------------------- */
    const handleSave = async () => {
        setIsLoading(true);

        let sendData = {};
        inputContainerRef.current.querySelectorAll(`input,textarea,select`).forEach((elm) => {
            if (!elm.name) return;

            const elmValue = elm.type === 'checkbox' ? elm.checked : elm.value;

            // typeがradioの時 チェックされていない時はスキップする
            if (elm.type === 'radio' && !elm.checked) return;
            
            sendData[elm.name] = typeof(elmValue) === 'boolean' ? (!elmValue ? "✕" : "〇") : elmValue;
        });

        // 配列から _uuidを削除する
        for(const key in sendData)
        {
            if(!Array.isArray(sendData[key])) continue

            const array = sendData[key]
            for(let i=0; i < array.length; i++)
            {
                if(typeof(array[i]) !== 'object') continue
                delete array[i]._uuid
            }
        }


        let docId


        // 画像のデータをコピー
        const imgs = profileData.current.imgs ? [...profileData.current.imgs] : []

        // blobを取り除く
        sendData.imgs = sendData.imgs ? sendData.imgs.map(img => {
            return {
                url: img.url,
            }
        }) : []
        
        // 新規の時は追加、それ以外は更新
        if(isNew){
            const newDoc = await addDoc(collection(firebaseDB, FirestoreMap$profiles), sendData);
            docId = newDoc.id
            
        }else{
            
            await updateDoc(doc(firebaseDB, FirestoreMap$profiles, targetUid), {...sendData, admin_edit: serverTimestamp()});
            docId = targetUid
        }

        // 画像のアップロード
        if(imgs)
        {
            profileData.current.imgs = await Promise.all(imgs?.map(async (img, index) => {

                if(!img?.blob) return img

                const storageRef = ref(firebaseStorage, `profiles/${docId}/${index+1}.jpg`)

                try {
                    // 圧縮画像の生成
                    img.blob = await imageCompression(img.blob, {
                        initialQuality: .9,
                        fileType: 'image/jpeg',
                    });
                    
                }catch{
                    console.warn('imageCompression skip')
                }

                console.log("upload....")
                // アップロード
                const uploadSnapShot = await uploadBytes(storageRef, img.blob, {
                    contentType: img.blob.type || 'image/jpeg',
                })
                console.log("upload done!")

                // URLを取得する
                const downloadURL = await getDownloadURL(uploadSnapShot.ref)
                img.url = downloadURL

                return {
                    url: img.url,
                    // blob: img.blob
                }
            }))

            await updateDoc(doc(firebaseDB, FirestoreMap$profiles, docId), {imgs: profileData.current.imgs, admin_edit: serverTimestamp()});
        }




        setIsLoading(false);
        setShowUpdateComplete(true);
    };


    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        if(!user.current)
            return

        console.log("useEffect")

        const init = async ()=>{

            setIsLoading(true)
            
            // ターゲットのUIDを取得する
            const _targetUid = props.shopId || user.current.uid
            setTargetUid(_targetUid)

            //データを取得する
            const profileSnap = await getDoc(doc(firebaseDB, FirestoreMap$profiles, _targetUid))
            
            // adminモードでは無い 未登録の時
            if(!props.shopId && (!profileSnap.exists() || profileSnap.data().state === "pending"))
            {
                if(!profileSnap.exists())
                {
                    setNotRegisterPopup(true)
                } else {
                    setPendingPopup(true)
                }
                return
            }

            profileData.current = (profileSnap.exists() ? profileSnap.data() : {})

            // メニューが無い時は作成する
            if(!profileData.current.menus) profileData.current.menus = [{}]

            // 配列データにuuidを設定する
            for(const key in profileData.current)
            {
                if(!Array.isArray(profileData.current[key])) continue

                const array = profileData.current[key]
                for(let i=0; i < array.length; i++)
                {
                    if(typeof(array[i]) !== 'object') continue
                    array[i]._uuid = uuidv4()
                }
            }

            // 新規か判定
            setIsNew(!profileSnap.exists())



            setIsLoading(false)


            //アナリティクス
            logEvent("profile_edit", { uuid: _targetUid })
        }
        init()


    }, [user, props.shopId])


    /* -----------------------------------------------  
    * handleEditImage : 画像の編集
    ----------------------------------------------- */
    const handleEditImage = async (index, imgMaxSize)=>{
        
        
        // 画像の最大サイズ
        currentImgMaxSize.current = imgMaxSize

        //ファイルダイアログを開く
        await appLib.FilePicker(async (blobs)=>{


            // 何も選択されていない時はスキップ
            if(blobs.length === 0)
                return

            //データにアップロードデータを紐付ける
            profileData.current.imgs[index] = {
                url: (window.URL || window.webkitURL).createObjectURL(blobs[0]),
                blob : blobs[0]
            }

            // 現在編集中の画像データ
            currentEditImg.current = {data: profileData.current.imgs, id: index}
            
            console.log(index)
            console.log(profileData.current.imgs)
            console.log(currentEditImg.current.id)

            console.log(currentEditImg.current?.data)

            // データを更新
            profileData.current.imgs[index] = {
                    url: (window.URL || window.webkitURL).createObjectURL(blobs[0]),
                    blob : blobs[0]
            }

            //クリッピングを表示
            setShowCropWindow(true)

            forceUpdate()


        },{ multiple: false })
    }

    /* -----------------------------------------------
    * handleSetRenewalPromptNotification
    ----------------------------------------------- */
    const handleSetRenewalPromptNotification = async ()=>{
        await fcmInit(user ,true)
        await setDoc(doc(firebaseDB, FirestoreMap$users, targetUid, FirestoreMap$users_readwrite, targetUid),{
            renewalPromptNotification: true,
        }, { merge: true })
        setShowRenewalPromptNotification(false)
        setShowUpdateComplete(true)
    }
    
    /* -----------------------------------------------
    * handleProfileUrlCopy
    ----------------------------------------------- */
    const handleProfileUrlCopy = () => {
        const url = `${window.location.origin}/temple/${targetUid}?q=test`;
        copy(url);
        alert("プロフィールのURLをコピーしました")
    };

    /* -----------------------------------------------  
    * handleAddImage : 画像の追加
    ----------------------------------------------- */
    const handleAddImage = async () => {

        // imgsに追加
        profileData.current.imgs.push({_uuid: uuidv4()});
        forceUpdate()


        // 1フレーム待つ
        await new Promise(resolve => setTimeout(resolve, 1));


        handleEditImage(profileData.current.imgs.length - 1, {width: 600, height: 600})
    };
    /* -----------------------------------------------  
    * handleRemoveImage : 画像の削除
    ----------------------------------------------- */
    const handleRemoveImage = (index) => {
        
        profileData.current.imgs.splice(index, 1);
        forceUpdate()
    };

    

    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <div className={ classes.root }>


            {isLoading && 
                <Box sx={{
                    position: 'fixed', left:0, right:0, top:0, bottom: 0,
                    margin: 'auto', zIndex: 10000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff99', }}>
                    <CircularProgress />
                </Box>
            }

            <div className={ classes.header }>
                <div className={ classes.title }>
                    プロフィールの編集
                </div>
                <button className={ classes.save_bt } onClick={ handleSave }>完了</button>
            </div>

            
            <div className={ classes.scroller } ref={ inputContainerRef }>
                

                <CropWindow
                    show={showCropWindow}
                    src={ currentEditImg.current?.data[currentEditImg?.current?.id]?.url || "/user-ico.svg" }
                    cropShape="rect"
                    maxSize={currentImgMaxSize.current}
                    onChange={(cropedImg, cropedImgBlob)=>{
                        console.log(currentEditImg.current.data)
                        currentEditImg.current.data[currentEditImg.current.id].url = cropedImg
                        currentEditImg.current.data[`${currentEditImg.current.id}`].blob = cropedImgBlob
                        forceUpdate()
                        setShowCropWindow(false)
                    }
                }/>
                <br /><br />

                <div className={classes.item}>
                    <div className={classes.label}>写真</div>
                    <div className={classes.imgs}>
                        {profileData.current.imgs && <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="menus">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                {profileData.current.imgs.map((item, index) => (
                                    <Draggable key={item._uuid} draggableId={item._uuid} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.item}>
                                                <img src={item.url} alt={index} onClick={() => handleEditImage(index, {width: 600, height: 600})} />
                                                <div className={classes.delete} onClick={() => handleRemoveImage(index)}>
                                                    <DeleteIcon />
                                                </div>
                                        </div>)}
                                    </Draggable>
                                    ))}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>}
                        {(!profileData.current.imgs || profileData.current.imgs.length === 0) && <Button onClick={() => handleAddImage()} style={{ fontSize: 'large' }}>
                            <AddPhotoAlternateIcon sx={{fontSize: 100}} />
                        </Button>}
                    </div>

                    {profileData.current.imgs && profileData.current.imgs.length > 0 && <Button onClick={() => handleAddImage()} style={{ float: 'right' }}>
                        <AddIcon />
                    </Button>}
                </div>

                <div className={classes.item} >
                    <button className={classes.outline} onClick={handleProfileUrlCopy}>プロフィールのURLをコピー</button>
                </div>




                <div className={classes.item} >
                    <div className={classes.label}>住所</div>
                    <ValidateTextField
                        name="所在地"
                        defaultValue={ profileData.current.所在地 }
                        inputProps={{ minLength: 1, maxLength: 40,  }}
                        autoFocus={true}
                        placeholder="石川県金沢市横川７丁目２６"
                        onChange={(val)=>{ setCurrentAddress(val)}}
                    />
                    
                </div>


                <div className={ classes.map }>
                    <Iframe
                        src={`https://maps.google.co.jp/maps?output=embed&q=${ currentAddress || profileData.current.所在地 }`}
                        width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="eager" />
                </div>





                <div className={classes.item}>
                    <div className={classes.label}>寺院名</div>
                    <ValidateTextField
                        name="寺院名"
                        defaultValue={profileData.current["寺院名"]}
                        inputProps={{ minLength: 1, maxLength: 100 }}
                        placeholder="寺院名"
                    />
                </div>

                <div className={classes.item}>
                    <div className={classes.label}>寺院郵便番号(‐あり)</div>
                    <ValidateTextField
                        name="寺院郵便番号(‐あり)"
                        defaultValue={profileData.current["寺院郵便番号(‐あり)"]}
                        inputProps={{ minLength: 1, maxLength: 10 }}
                        placeholder="郵便番号"
                    />
                </div>

                <div className={classes.item}>
                    <div className={classes.label}>寺院紹介文</div>
                    <ValidateTextField
                        name="寺院紹介文"
                        defaultValue={profileData.current["寺院紹介文"]}
                        inputProps={{ maxLength: 500 }}
                        placeholder="寺院紹介文"
                        multiline={true}
                        rows={4}
                    />
                </div>

                <div className={classes.item}>
                    <div className={classes.label}>宗派</div>
                    <FormControl fullWidth>
                        <Select
                            name="宗派"
                            value={profileData.current["宗派"] || ""}
                            onChange={(event) => {
                                profileData.current["宗派"] = event.target.value;
                                forceUpdate();
                            }}
                        >
                            {sects.map((sect) => (
                                <MenuItem key={sect} value={sect}>
                                    {sect}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.item}>
                    <div className={classes.label}>駐車場</div>
                    {parkingOptions.map((option) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Radio
                                    checked={profileData.current["駐車場"] === option}
                                    onChange={() => {
                                        profileData.current["駐車場"] = option;
                                        forceUpdate();
                                    }}
                                    name="駐車場"
                                    value={option}
                                />
                            }
                            label={option}
                        />
                    ))}
                </div>

                {profileData.current["駐車場"] === "あり" && (
                    <div className={classes.item}>
                        <div className={classes.label}>駐車可能台数</div>
                        <ValidateTextField
                            name="駐車可能台数"
                            defaultValue={profileData.current["駐車可能台数"]}
                            inputProps={{ minLength: 1, maxLength: 10 }}
                            placeholder="駐車可能台数"
                        />
                    </div>
                )}

                <div className={classes.item}>
                    <div className={classes.label}>収容人数</div>
                    <ValidateTextField
                        name="収容人数"
                        defaultValue={profileData.current["収容人数"]}
                        inputProps={{ minLength: 1, maxLength: 10 }}
                        placeholder="収容人数"
                    />
                </div>

                {["空調", "宿泊", "会食室", "バリアフリー", "貸し切り", "控室", "法要室", "飲食持ち込み"].map((item) => (
                    <div className={classes.item} key={item}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={item}
                                    checked={profileData.current[item] !== "✕"}
                                    onChange={() => {
                                        profileData.current[item] = profileData.current[item] === "✕" ? "◯" : "✕";
                                        forceUpdate();
                                    }}
                                />
                            }
                            label={item}
                        />
                    </div>
                ))}



                {false && <div className={`${classes.item} ${classes.links}`} >
                    <div className={classes.label}>リンク</div>
                    
                    { ['instagram', 'twitterx', 'line', 'tiktok'].map((item, index)=>(
                        <div key={item} className={`${classes.item} ${classes.sns}`}>
                            <img src={`/icon/${item}.svg`} alt="icon" />
                            <ValidateTextField
                                name={`sns.${item}`}
                                label="URL"
                                defaultValue={ profileData?.current?.sns?.[item] }
                                inputProps={{ minLength: 1, maxLength: 100,  }}
                                />
                        </div>
                    )) }

                    { profileData.current.links && profileData.current.links.map((item, index)=>(
                        <div key={item._uuid} className={`${classes.item} ${classes.web}`}>
                            <img src={`/icon/web.svg`} alt="icon" />
                            <div> 
                                <ValidateTextField
                                    name={`links[${index}].name`}
                                    label="リンク名"
                                    defaultValue={ item.name }
                                    inputProps={{ minLength: 1, maxLength: 100,  }}
                                    onChange={(value)=>{profileData.current.links[index].name = value}}
                                    onBlur={()=> forceUpdate()}
                                    />
                                <ValidateTextField
                                    name={`links[${index}].url`}
                                    label="URL"
                                    defaultValue={ item.url }
                                    inputProps={{ minLength: 1, maxLength: 100,  }}
                                    />
                            </div>
                            <DeleteIcon className={classes.trash} onClick={ ()=>handleArrayDelete('links', index) }/>
                        </div>
                    )) }
                </div>}

                {false && <Box sx={{ textAlign:'right', borderBottom: '1px solid #ccc', py:2, my: 2}}>
                    <Button size="large" variant="outlined" startIcon={<AddCircleIcon />} onClick={ ()=>handleArrayAdd('links', {label:'', url:''}) }>追加</Button>
                </Box>}

            </div>


            <Dialog open={notRegisterPopup}>
                <DialogTitle>お知らせ</DialogTitle>
                <DialogContent>
                    <p>編集する前に登録フォームより、ご登録をお願い致します。</p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={()=>history.push('/recruit-start')}>登録</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={notPendingPopup}>
                <DialogTitle>お知らせ</DialogTitle>
                <DialogContent>
                    <p>お客様のアカウントまだ認証処理中です。しばらく時間をおいてから再度アクセスしてみてください。</p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={()=>history.push('/')}>トップページへ</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showRenewalPromptNotification} onClose={()=>setShowRenewalPromptNotification(false)}>
                <DialogContent>
                    <p>本日の出店場所を毎日更新してお客様にお店をアピールする為のプッシュ通知をONにしてみませんか？</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowRenewalPromptNotification(false)}>キャンセル</Button>
                    <Button variant="contained" onClick={handleSetRenewalPromptNotification}>設定する</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showUpdateComplete} onClose={()=>setShowUpdateComplete(false)}>
                <DialogContent>
                    <p>お店情報の更新が完了しました。</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowUpdateComplete(false)}>キャンセル</Button>
                    <Button variant="outlined" onClick={()=>history.push(`/temple/${targetUid}?q=test&single=1`)}>表示する</Button>
                </DialogActions>
            </Dialog>


        </div>
};

export default ProfileEdit;