
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import classes from './View.module.scss'
import ShopDetail from '../ShopDetail';

const View = (props) => {

    return (
        
        <Box sx={{
            height: '100%',
        }} className={ classes.root }>

            <CssBaseline />

            <ShopDetail { ...props } />

        </Box>
    );
};

export default View;