import CustomTokenMailLogin from './lib/CustomTokenMailLogin'
import MailLogin from './lib/MailLogin'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const ViewMailLogin = ()=>{

    const current_route_path = useLocation();
    const handleCallback = async (user, data) => {

        console.log(data)
    }

    const param = queryString.parse(current_route_path.search)

    if(param.ctoken){
        return <CustomTokenMailLogin onCallback={ handleCallback } />
    }else{
        return <MailLogin onCallback={ handleCallback } />
    }
}

export default ViewMailLogin