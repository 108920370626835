import { useHistory, Link } from 'react-router-dom';
import classes from './EventSummer1.module.scss';
import CloseIcon from '@mui/icons-material/Close';



const EventSummer1 = (props) => {
    
    const history = useHistory();

    return (
        <div className={classes.root}>
            
            <div className={classes.header}>
                <CloseIcon onClick={()=>history.push("/")} className={ classes.closebt } />
                <div className={classes.title}>
                    SUMMER
                </div>
                <div className={classes.subtitle}>
                    夏のテイクアウト特集
                </div>
            </div>
            <div className={classes.content}>
                {["ソフトクリーム", "かき氷", "ジェラート", "シェイク", ].map((eName, i)=>(
                    <Link key={i} className={classes.item} to={`/shoplist/全国/[${eName}]`}>
                        <img className={classes.photo} src={`/special/summer1/${i+1}.jpg`} alt="main" />
                        <img className={classes.text} src={`/special/summer1/${i+1}.svg`} alt="text" />
                    </Link>
                ))}
            </div>

        </div>
    );
};

export default EventSummer1;