
import Typography from '@mui/material/Typography';
import classes from './RecruitDetails.module.scss';
import { useEffect, } from 'react';
import "react-responsive-carousel/lib/styles/carousel.css";
import { Link } from 'react-router-dom';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { firebaseDB } from './lib/firebase';
import { FirestoreMap$profiles } from './lib/FirestoreMap';
import EventHoldMap from './EventHoldMap';

const QandA = (props) => {

    return <div className={classes.item}>
        <div>
            <div className={classes.icon}>Q</div>
            <div>{props.children[0]}</div>
        </div>
        <div>
            <div className={classes.icon}>A</div>
            <div>{props.children[1]}</div>
        </div>
    </div>
}

const RecruitDetails = (props) => {
    

    useEffect(()=>{

        const init = async ()=>{

            const snapShot = await getDocs(query( collection(firebaseDB, FirestoreMap$profiles), limit(10), where("state", "==", "active-test"), orderBy("approvalDate", "desc")))
            const _newShopList = []
            for(const doc of snapShot.docs)
            {
                _newShopList.push({
                    id: doc.id,
                    icon: doc.data().icon || `/profile/shop.png`,
                    ...doc.data()
                })
            }
            console.log(_newShopList)
        }
        init()

    }, [])


    // ブラウザチェック
    const handleCheckBrowser = (e)=>{
        const ua = window.navigator.userAgent.toLowerCase().trim();

        // アプリでは無い時
        if(!ua.includes('collabo-app-browser')){
            
            if(ua.includes("iphone")){
                e.preventDefault();
                window.location.href="https://apps.apple.com/us/app/eat-plus-%E3%82%A4%E3%83%BC%E3%83%88%E3%83%97%E3%83%A9%E3%82%B9/id6450914531"
            } else {
                // alert("Android版ただいま準備中です、お楽しみに！")
            }
        }
    }

    return (
        <div className={classes.root}>

            <div className={classes.header}>
                <img className={classes.logo} src="/logo.svg" alt="bg" />
                <div className={classes.name}>
                    キッチンカー<br />
                    テイクアウト店
                </div>
                <div className={classes.regist}>
                    登録
                </div>
            </div>

            <div className={classes.intro}>
                <img className={classes.photo} src="/recruit-details/intro.jpg" alt="bg" />
                <div className={classes.pop}>
                    登録は無料です。今すぐ参加しましょう！
                </div>
                <Link className={classes.pinkbt} onClick={handleCheckBrowser} to="/recruit-start">
                    登録する
                </Link>
            </div>

            <div className={classes.what}>
                <div className={classes.title}>
                    イートプラスとは？
                </div>
                <div className={classes.text}>
                    お客様に「キッチンカー」、「テイクアウトができる店舗」を簡単に見つけてもらうことができるプラットフォームです。
                </div>
            </div>

            <div className={classes.feature}>

                <div className={classes.list}>
                    <div className={classes.item}>
                        <div className={classes.title}>
                            ①メニューの表示
                        </div>
                        <img className={classes.img} src="/recruit-details/feature-1.jpg" alt="feature" />
                        <div className={classes.text}>
                            フード、ドリンク、スイーツ、商品のメニューの表示ができる
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.title}>
                            ②地図で表示
                        </div>
                        <img className={classes.img} src="/recruit-details/feature-2.jpg" alt="feature" />
                        <div className={classes.text}>
                            キッチンカーはその日どこで出店されるか地図に表示されます。テイクアウト店はお店の場所を表示
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.title}>
                            ③ルート案内
                        </div>
                        <img className={classes.img} src="/recruit-details/feature-3.jpg" alt="feature" />
                        <div className={classes.text}>
                            お客様の位置から店舗までのルートを表示
                        </div>
                    </div>
                </div>

                <div className={classes.afterinfo}>
                    今後のアップデートでサービスを追加していきます。お楽しみに！
                </div>

                <br />
                <br />
            </div>

            <div className={classes.sticky}>
                <Link className={classes.pinkbt} onClick={handleCheckBrowser} to="/recruit-start">登録する</Link>
            </div>

            <div className={classes.feature}>
                <br />
                <br />
                <img className={classes.line} src="/recruit-details/line.png" alt="line" />
                <br />
                <br />

                <div className={classes.download}>
                    <div className={classes.text}>
                        ご利用開始から利用方法までは以下の資料をご参照ください
                    </div>
                    <a className={classes.bt} href="/doc/registration_usage3.pdf">
                        資料のダウンロード
                    </a>
                </div>

                <br />
                <br />
                <br />
                <br />
            </div>


            <Link to="/call-kitchen-car" className={`${classes.infotext} ${classes.white}`}>
                <div className={classes.title}>
                    <Typography variant="h3" component="h1">
                        キッチンカー募集
                    </Typography>
                    <Typography variant="h5" component="h3" className={classes.text}>
                        フェスやイベント、マルシェなどに呼びたいお店やキッチンカーを募集しましょう！
                    </Typography>
                </div>
                <div className={`${classes.event_hold_map}`}>
                    <EventHoldMap />
                </div>
                <br />
                <br />
                <div className={classes.pinkbt}>
                    キッチンカーを呼ぶ
                </div>
                <div className={classes.info}>
                    キッチンカーを呼びたい方はこちら
                </div>
            </Link>
        

            <div className={ classes.q_and_a }>
                <div className={ classes.title }>
                    Q&A
                </div>

                <div className={ classes.list }>
                    
                    <QandA>
                        <>イートインとテイクアウトどちらもしている店舗は登録できますか</>
                        <>はい、できます。テイクアウトしていればよいのでテント販売等の店舗様も登録できます。</>
                    </QandA>

                    <QandA>
                        <>休みの日は自分の店舗アイコンはどうなりますか</>
                        <>休みの日は地図から消えます</>
                    </QandA>
                    <QandA>
                        <>登録後は有料ですか？</>
                        <>このページ内で紹介しているサービスは無料です。現在有料の追加オプションを開発しております。</>
                    </QandA>
                    <QandA>
                        <>キッチンカーを呼べるのはイベント運営のみですか？</>
                        <>いいえ、自治体の許可がでていればどこでも呼べます。</>
                    </QandA>
                </div>
            </div>

        </div>
    );
};

export default RecruitDetails;