import { useEffect, } from 'react';
import classes from './Cart.module.scss'
import { useCartContext } from '../context/lib/CartContext';
import { Link } from 'react-router-dom';


const Cart = (props) => {
    
    const { items } = useCartContext();

    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{

        console.log(items)
        
    }, [items])

    if(items.length === 0) return null

    return (
        <div className={ classes.root }>
            <div className={ classes.num }>依頼リスト<span>{ items.length }</span>件</div>
            <Link to='/request' className={classes.bt}>
                依頼する
            </Link>
        </div>
    )

};

export default Cart;