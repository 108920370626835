import { initializeApp } from 'firebase/app';
import { initializeFirestore, connectFirestoreEmulator, persistentMultipleTabManager, persistentLocalCache, } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics as _getAnalytics, logEvent as _logEvent } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { appLib } from './_app_lib';
const { initializeAppCheck, CustomProvider } = require("firebase/app-check");

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};


export const firebaseApp = initializeApp(firebaseConfig);


export const appCheckListener = {
    info : "",
    log: (msg)=>{
        console.log(msg)
        appCheckListener.info += msg + "\n"
    }
}

//サーバーモードの時
if(process.env.REACT_APP_FIREBASE_MODE === 'server')
{
    //カスタムプロバイダーを作成
    const appCheckCustomProvider = new CustomProvider({
        getToken: () => {
            appCheckListener.log("initializeAppCheck-2")
            return new Promise(async (resolve, _reject) => {

                appCheckListener.log("appCheckCustomProvider")
                let response
                
                for(let i=0; i < 5; i++){
                    try{
                        appCheckListener.log(`serverToken start ${i+1}`)
                        response = await fetch("/app-check-mkdl2skc-v92", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({a: 123})
                            })

                        if (!response.ok) throw new Error("fetch error");
                        break

                    }catch(e){

                        appCheckListener.log(`serverToken retry ${i+1}\n${e}`)
                        // １秒待機
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                }

                if(!response.ok){
                    appCheckListener.log("serverToken failed reload")
                    if(process.env.REACT_APP_FIREBASE_MODE === 'server') appLib.SuperReload()
                    return
                }

                const serverToken = await response.json()
                appCheckListener.log("serverToken done")

                const appCheckToken = {
                    token: serverToken.token,
                    expireTimeMillis: Date.now() + serverToken.ttlMillis
                };

                resolve(appCheckToken);

            })
        }
    });

    appCheckListener.log("initializeAppCheck-1")
    console.log("initializeAppCheck-1")
    initializeAppCheck(firebaseApp, {
        provider: appCheckCustomProvider,
        isTokenAutoRefreshEnabled: true
    });
    appCheckListener.log("initializeAppCheck-3")

    
}

export const firebaseDB = initializeFirestore(firebaseApp, {localCache: 
    persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()})
});


export const firebaseStorage = getStorage(firebaseApp);

//リージョンを分岐
const region = process.env.REACT_APP_FIREBASE_MODE === 'local' ? undefined : 'asia-northeast1'

export const firebaseFunctions = getFunctions(firebaseApp, region);

//ローカルモードの時
if(process.env.REACT_APP_FIREBASE_MODE === 'local')
{
    //エミュレーターに接続
    connectFirestoreEmulator(firebaseDB, 'localhost', 8080);
    connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
    connectAuthEmulator(getAuth(), "http://localhost:9099");
    connectStorageEmulator(firebaseStorage, "localhost", 9199);
}


//ログイベント
const analytics = process.env.REACT_APP_FIREBASE_MODE === "local" ? null : _getAnalytics();
export const logEvent = (name, value) => {
    
    if(process.env.REACT_APP_FIREBASE_MODE === "local")
        return

    //スライド統計
    _logEvent(analytics, name, value);
}

