
import { Link } from 'react-router-dom';
import React from "react";

//インスタグラムのWebViewが戻るボタンが効かないのでAタグを通常のリンクにする
const Link2 = React.forwardRef((props, ref) => {

    const href = props.href ? props.href : ""
    
    //絶対パスの時
    if(href.startsWith("http") || href.startsWith("tel:") || href.startsWith("mailto:"))
    {
        return (
            <a {...props}>
                {props.children}
            </a>
        );
    } else {
        
        return (
            <Link {...props} to={ href }>
                {props.children}
            </Link>
        );
    }
});



export default Link2;