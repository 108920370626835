import classes from "./PasswordEdit.module.scss";
import { useEffect, useRef, useState, } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useAuthContext } from '../../context/lib/AuthContext';
import { useHistory, } from "react-router-dom";
import queryString from 'query-string';
import { logEvent, firebaseFunctions, } from "./firebase";
import { fcmInit } from "./FcmLib";
import { useLocation } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { httpsCallable } from "firebase/functions";

const functions = {
    changeAccountPassword : httpsCallable(firebaseFunctions, 'changeAccountPassword'),
}


const PasswordEdit = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const inputContainerRef = useRef()
    const { user } = useAuthContext();
    const history = useHistory()
    const [backUrl, setBackUrl] = useState('')
    const current_route_path = useLocation();
    const [confirmShow, setConfirmShow] = useState(false)
    const [completeShow, setCompleteShow] = useState(false)
    


    /* -----------------------------------------------
    * useEffect
    ----------------------------------------------- */
    useEffect(()=>{

        //アナリティクス
        logEvent("notication_edit")

    }, [])

    /* -----------------------------------------------
    * useEffect
    * プッシュ通知設定
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined)
            return

        const init = async()=>{
            await fcmInit(user, true)
        }
        init()

    }, [user])


    /* -----------------------------------------------
    * handleChangePassword
    ----------------------------------------------- */
    const handleChangePassword = async ()=>{

        setIsLoading(true)

        const sendData = {}
        inputContainerRef.current.querySelectorAll(`input,textarea`).forEach((elm, index)=>{

            // MUIで生成された不要なフォームはスキップ
            if(!elm.name)
                return

            var arrayMatch = elm.name.match(/(.*)\[([0-9]+)\](.*)/)

            // 配列の時
            if(arrayMatch)
            {
                if(!sendData[arrayMatch[1]])
                    sendData[arrayMatch[1]] = []

                // マップ形式の時
                if(arrayMatch[3])
                {
                    if(!sendData[arrayMatch[1]][Number(arrayMatch[2])])
                        sendData[arrayMatch[1]][Number(arrayMatch[2])] = {}

                    sendData[arrayMatch[1]][Number(arrayMatch[2])][arrayMatch[3].replace(/^\./, '')] = elm.type === 'checkbox' ? elm.checked : elm.value
                } else {
                    sendData[arrayMatch[1]][Number(arrayMatch[2])] = elm.type === 'checkbox' ? elm.checked : elm.value
                }

            } else {

                sendData[elm.name] = elm.type === 'checkbox' ? elm.checked : elm.value
            }

        })

        // パラメーター名変更（Chromeのパスワード自動作成対策）
        sendData.password1 = sendData.input1
        sendData.password2 = sendData.input2
        delete sendData.input1
        delete sendData.input2

        console.log(sendData)

        const _isValidPassword = (password) => {
            // 英半角で6文字以上
            if (password.length < 6) {
              return false;
            }
          
            // 大文字が1つ以上
            if (!/[A-Z]/.test(password)) {
              return false;
            }
          
            // 数字が1つ以上
            if (!/[0-9]/.test(password)) {
              return false;
            }
          
            return true;
        }
        if(!_isValidPassword(sendData.password1)){
            alert("パスワードは最低6文字以上で、少なくとも1つの大文字と1つの数字を含む必要があります。")
            setIsLoading(false)
            return
        }
        if(sendData.password1 !== sendData.password2){
            alert("パスワードが一致しません。")
            setIsLoading(false)
            return
        }
        const ret = await functions.changeAccountPassword(sendData)

        console.log(ret)
        
        setIsLoading(false)
        setConfirmShow(false)
        if(ret?.data?.status){
            setCompleteShow(true)
        } else {
            alert("パスワード変更に失敗しました。")
        }

    }

    /* -----------------------------------------------
    * handleCancel
    ----------------------------------------------- */
    const handleCancel = async ()=>{

        history.push(backUrl)
    }

    /* -----------------------------------------------
    * useEffect
    * 戻るパス取得
    ----------------------------------------------- */
    useEffect(()=>{
    

        //戻るボタンのパス
        const query_search = queryString.parse(current_route_path.search)
        if(query_search.back)
        {
            //指定のURL
            setBackUrl(query_search.back)
            
        } else {
            //チャットルームリスト
            setBackUrl('/account/edit')
        }
    }, [setBackUrl, current_route_path.search])

    if(isLoading)
        return <Box sx={{
            position: 'fixed', left:0, right:0, top:0, bottom: 0,
            margin: 'auto', zIndex: 10000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff99', }}>
            <CircularProgress />
        </Box>



    /* -----------------------------------------------
    * render : 描画
    ----------------------------------------------- */
    return <div className={ classes.root }>


            {isLoading && 
                <Box sx={{
                    position: 'fixed', left:0, right:0, top:0, bottom: 0,
                    margin: 'auto', zIndex: 10000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff99', }}>
                    <CircularProgress />
                </Box>
            }

            <div className={ classes.header }>
                <button className={ classes.back_bt } onClick={ handleCancel }>戻る</button>
                <div className={ classes.title }>
                    パスワード設定・変更
                </div>
            </div>

            
            <div className={ classes.scroller } ref={ inputContainerRef }>


            <div className={classes.item} >
                    <div className={classes.label}>アカウントパスワード</div>
                    <div className={classes.text}>
                        メールアドレスでログインするパスワードを設定、変更する事ができます。
                    </div>
                    <br />
                    <div className={classes.info}>パスワードは最低6文字以上で、少なくとも1つの大文字と1つの数字を含む必要があります。</div>
                    <br /><br /><br />
                    <TextField name="input1" fullWidth label='パスワード' />
                    <br /><br />
                    <TextField name="input2" fullWidth label='パスワード確認' />
                    
                    <br /><br /><br />
                    <Button fullWidth size="large" onClick={()=>setConfirmShow(true)}>変更する</Button>
                </div>

            </div>

            <Dialog
                open={ confirmShow }
                onClose={()=>setConfirmShow(false)}
            >
                <DialogTitle >
                    パスワードを変更して宜しいですか？
                </DialogTitle>
                
                <DialogActions sx={{justifyContent:'center'}}>

                    <Button autoFocus onClick={()=>setConfirmShow(false)} >
                        キャンセル
                    </Button>
                    <Button onClick={handleChangePassword} autoFocus variant="contained">
                        変更する
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={ completeShow }
                onClose={()=>setCompleteShow(false)}
            >
                <DialogTitle >
                    パスワードが変更されました。
                </DialogTitle>
                
                <DialogActions sx={{justifyContent:'center'}}>

                    <Button onClick={()=>setCompleteShow(false)} autoFocus variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            
        </div>
};

export default PasswordEdit;