import { firebaseApp } from "./firebase";
import { useHistory } from 'react-router-dom';
import { getAuth, signOut, } from "firebase/auth";
import { useEffect } from "react";
import queryString from 'query-string';

const auth = getAuth(firebaseApp);


const Logout = (props) => {

    const history = useHistory();

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{
        signOut(auth).then(() => {

            const query_search = queryString.parse(window.location.search)
            window.location.href = query_search.back || "/"
        })
    }, [history])


    return (
        
        <></>
            
    );
};

export default Logout;