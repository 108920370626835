import { useHistory, } from 'react-router-dom';
import { getAuth, signInWithCustomToken,  } from "firebase/auth";
import { useEffect, useState, } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link2 from './Link2';
import { firebaseFunctions, } from "./firebase";
import { httpsCallable } from "firebase/functions";

const functions = {
    sendVerifyInEmail : httpsCallable(firebaseFunctions, 'sendVerifyInEmail'),
}


const CustomTokenMailLogin = (props) => {

    const history = useHistory();
    const current_route_path = useLocation();
    const [ isError, setIsError ] = useState(false)
    const [ userDisabled, setUserDisabled ] = useState(false)


    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(() => {


        const init = async ()=>{

            // paramが存在する時は追加する
            const param = queryString.parse(current_route_path.search)

            console.log(param)
            console.log(param.uuid)
            console.log(param.continueUrl)


            // メール認証
            const result = await functions.sendVerifyInEmail({
                "uuid": param.uuid
            });
            
            console.log(result)

            // エラーがある時
            if(!result?.data?.success){
                setIsError(true)
                return
            }

            console.log(result?.data?.token)

            const auth = getAuth();
            signInWithCustomToken(auth, result?.data?.token)
            .then((userCredential) => {
                // サインインが成功したら、UserCredentialオブジェクトが返されます。
                var user = userCredential.user;
                console.log("logined!")
                console.log(user)

                
                history.push(param.continueUrl)

            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("login error")
                console.log(errorCode)
                console.log(errorMessage)

                setIsError(true)

                if(errorCode === "auth/user-disabled"){
                    setUserDisabled(true)
                }
                
            });
        }
        init()
        

    }, [current_route_path.search, history, ])

    //エラーの場合
    if(isError)
    {
        return (
            <>
                <Box sx={{ padding: 5,  }}>
                    <img src="/logo.svg" style={{ width: '40%', maxWidth: 500  }} alt="logo" />
                </Box>
                
                <Box sx={{ backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Box sx={{ padding: 5,  }}>
                        { userDisabled && <>
                            アカウントが無効になっています。<br />
                            このアカウントでログインする場合は運営サポートまでご連絡ください。<br/>
                            <a href="mailto:sales@shapeplanning.jp">sales@shapeplanning.jp</a><br/>
                            <br/>
                            別のアカウントでログインするにはログインをタップしてください
                        </>}
                        { !userDisabled && <>URLが有効期限切れか間違えています。もう一度ログインをしなおしてください。</>}
                    </Box>
                    <Box sx={{m:2}} />

                    <Button component={ Link2 } href="/authlogin" variant="contained" fullWidth={true} size="large" style={{ width: '90%', maxWidth: 500 }} >ログイン</Button>


                </Box>
            </>
        )
    }

    return (

        
        <CircularProgress sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', zIndex: 10000, }} />
    );
};

export default CustomTokenMailLogin;