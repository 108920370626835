import { useState,  } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import classes from './Request.module.scss';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { firebaseFunctions, } from "./lib/firebase";
import { httpsCallable } from "firebase/functions";
import LoadingScreen from './lib/LoadingScreen';
const functions = {
    funeralPlanRequest : httpsCallable(firebaseFunctions, 'funeralPlanRequest'),
}



const RequestQuick = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        email: '',
        phone: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const history = useHistory();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log(formData);

        // formDataの記入漏れ
        if(formData.name === '' || formData.area === '' || formData.email === '' || formData.phone === ''){
            alert('すべての項目を入力してください');
            return;
        }

        formData.お問い合わせ内容 = "本日対応可能なお寺をお問い合わせ"
        
        setIsLoading(true);
        const ret = await functions.funeralPlanRequest(formData)
        console.log(ret);
        setIsLoading(false);
        setIsComplete(true);
    };


    return (
        <Container className={classes.root}>
            <LoadingScreen show={isLoading} />
            <div className={classes.header}>
                <Link to="/" className={classes.backButton}>
                    <ArrowBackIosNewIcon />
                </Link>
            </div>
            <Typography className={classes.title} variant="h4" component="h1" gutterBottom>
                本日対応可能なお寺をお問い合わせ
            </Typography>
            {isComplete ? (
                <>
                    <br />
                    <div className={classes.complete}>
                        
                        <span>お問い合わせありがとうございます。</span>
                        周辺のお寺の確認がとれましたらお電話、メールでご連絡いたします。
                        
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button onClick={()=>history.push('/')} className={classes.button} type="submit" variant="contained" color="primary" size="large" fullWidth>
                        TOPに戻る
                    </Button>
                </>
            ) : (
                <form onSubmit={handleSubmit}>
                    <small>お客様が希望するエリア付近の対応可能なお寺をお探ししますので下記のフォームの入力をお願いします。</small>
                    <br /><br />
                    <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者氏名
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        探したいエリア
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="area"
                        type="text"
                        value={formData.area}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者様メールアドレス
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者様電話番号
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                
                <br />
                <br />
                <Button onClick={handleSubmit} className={classes.button} type="submit" variant="contained" color="primary" size="large" fullWidth>
                    依頼する
                    </Button>
                </form>
            )}
        </Container>
    );
};

export default RequestQuick;