import { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import classes from './Request.module.scss';
import { useCartContext } from '../context/lib/CartContext';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { firebaseFunctions, } from "./lib/firebase";
import { httpsCallable } from "firebase/functions";
import LoadingScreen from './lib/LoadingScreen';
const functions = {
    funeralPlanRequest : httpsCallable(firebaseFunctions, 'funeralPlanRequest'),
}



const Request = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        email: '',
        phone: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const history = useHistory();
    const { items, clearCart } = useCartContext();
    const [isSelectedPlan, setIsSelectedPlan] = useState(false);
    const [requestTitle, setRequestTitle] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log(formData);

        // formDataの記入漏れ
        if(formData.name === '' || formData.date === '' || formData.email === '' || formData.phone === ''){
            alert('すべての項目を入力してください');
            return;
        }

        const { uuid, name } = props.match.params;
        if(uuid && name){
            formData.お問い合わせ寺コード = uuid;
            formData.お問い合わせ寺名 = name;
        }else{
            formData.items = items.map((item) => `${item.コード} ${item.寺院名}`).join(',');
        }
        
        formData.お問い合わせ内容 = requestTitle
        
        setIsLoading(true);
        const ret = await functions.funeralPlanRequest(formData)
        console.log(ret);
        setIsLoading(false);
        setIsComplete(true);
        clearCart();
    };

    useEffect(() => {
        const { uuid, name } = props.match.params;
        if(uuid && name){
            setRequestTitle(`${name}お問い合わせ`);
        }else{
            setRequestTitle('寺院依頼');
            setIsSelectedPlan(true);
        }
    }, [props.match.params]);

    return (
        <Container className={classes.root}>
            <LoadingScreen show={isLoading} />
            <div className={classes.header}>
                <div className={classes.backButton} onClick={()=>history.goBack()}>
                    <ArrowBackIosNewIcon />
                </div>
            </div>
            <Typography className={classes.title} variant="h4" component="h1" gutterBottom>
                {requestTitle}
            </Typography>
            {isComplete ? (
                <>
                    <br />
                    <div className={classes.complete}>
                        
                        <span>ご依頼が完了しました。</span>
                        寺院との確認が取れ次第、メールにてご連絡いたします。
                        
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button onClick={()=>history.push('/')} className={classes.button} type="submit" variant="contained" color="primary" size="large" fullWidth>
                        TOPに戻る
                    </Button>
                </>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者氏名
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        葬儀希望日
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="date"
                        type="date"
                        value={formData.date}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者様メールアドレス
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                        お申込者様電話番号
                    </Typography>
                    <TextField
                        className={classes.input}
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </div>
                
                {isSelectedPlan && <><br /><Typography className={classes.itemTitle} variant="subtitle1" component="div" gutterBottom>
                    依頼寺院
                </Typography>
                <div className={classes.list}>
                    {items.map((item, index) => (
                        <ul key={index} className={classes.item}>
                            <li>{item.寺院名} あんしんプラン</li>
                        </ul>
                    ))}
                </div>
                </>}
                <br />
                <br />
                <Button onClick={handleSubmit} className={classes.button} type="submit" variant="contained" color="primary" size="large" fullWidth>
                    依頼する
                    </Button>
                </form>
            )}
        </Container>
    );
};

export default Request;