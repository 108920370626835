import { useEffect,  } from 'react';
import classes from './AppLayout.module.scss'


const SingleLayout = ({ component: Component, props }) => {
    
    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{

    }, [])

    return <>
        <div id="app_root" className={ classes.root }>
            <div className={ classes.box }>
                <Component {...props} />
            </div>
        </div>
        <div className={ classes.copylight }>© 2020-{new Date().getFullYear()} collabo inc.</div>
    </>;

};

export default SingleLayout;