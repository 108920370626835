import { useEffect } from 'react'
import Login from './lib/Login'
import {appLib} from './lib/_app_lib'

const ViewLogin = ()=>{


    const handleCallback = async (user) => {

        console.log("callback...")
    }

    useEffect(()=>{
        
        const init = async ()=>{
            //アプリトラッキング許可
            const ret = await appLib.AppTrackingTransparency()
            console.log(ret)
        }
        init()
    }, [])

    return <Login maildata={{test: 123}} onCallback={ handleCallback } useMailLogin usePassword skipRegisterDomain startMailLogin={window.location.search.includes("start=mail")} />
}

export default ViewLogin