import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from './BusinessHours.module.scss';
import TextField from '@mui/material/TextField';

const daysOfWeek = [
    { en: 'sunday', jp: '日曜日' },
    { en: 'monday', jp: '月曜日' },
    { en: 'tuesday', jp: '火曜日' },
    { en: 'wednesday', jp: '水曜日' },
    { en: 'thursday', jp: '木曜日' },
    { en: 'friday', jp: '金曜日' },
    { en: 'saturday', jp: '土曜日' }
];

const defaultHours = { open: '9:00', close: '17:00' };

export const getBusinessHourText = (props, dayIndex)=>{
    try{
        const today = new Date();
        if(dayIndex === undefined) dayIndex = today.getDay()
        
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const dayOfWeek = days[dayIndex]; // 曜日を文字列で取得
        const dayTimes = props.time?.find(time => time.day === dayOfWeek);
        if(dayTimes.closed) return "定休日"
        if (dayTimes && dayTimes.hours) {
            const hoursText = dayTimes.hours.map((hours, index, array) => 
                `${hours.start} ~ ${index === array.length - 1 ? (dayTimes.sold_out ? "売り切れ次第" : dayTimes.yet ? "未定" : hours.end) : hours.end}`
            ).join(', ');
            return hoursText
        }else{
            return ""
        }
    }catch(e){
        return ""
    }
}

function BusinessHours(props) {
    const [hours, setHours] = useState({
        sunday: [defaultHours],
        monday: [defaultHours],
        tuesday: [defaultHours],
        wednesday: [defaultHours],
        thursday: [defaultHours],
        friday: [defaultHours],
        saturday: [defaultHours]
    });

    const [closedDays, setClosedDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });

    const [uncertainDays, setUncertainDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });

    const [selloutDays, setSelloutDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });

    const handleTimeChange = (day, index, field, value) => {
        const newHours = { ...hours };
        newHours[day] = [...newHours[day]]; // この曜日の時間スロット配列も新しい配列としてコピー
        newHours[day][index] = { ...newHours[day][index], [field]: value }; // 指定された時間スロットのみ更新
        setHours(newHours);
    };

    const addTimeSlot = (day) => {
        const newHours = { ...hours };
        newHours[day].push({ ...defaultHours });
        setHours(newHours);
    };

    const removeTimeSlot = (day, index) => {
        const newHours = { ...hours };
        newHours[day].splice(index, 1);
        setHours(newHours);
    };

    const toggleClosedDay = (day) => {
        const newClosedDays = { ...closedDays };
        newClosedDays[day] = !newClosedDays[day];
        setClosedDays(newClosedDays);
    };

    const toggleUncertainDay = (day) => {
        const newUncertainDays = { ...uncertainDays };
        newUncertainDays[day] = !newUncertainDays[day];
        setUncertainDays(newUncertainDays);
    };

    const toggleSelloutDay = (day) => {
        const newSelloutDays = { ...selloutDays };
        newSelloutDays[day] = !newSelloutDays[day];
        setSelloutDays(newSelloutDays);
    };

    useEffect(() => {
        if (props.time && Array.isArray(props.time)) {
            const newHours = {};
            const newClosedDays = {};
            const newUncertainDays = {};
            const newSelloutDays = {};
    
            props.time.forEach(dayInfo => {
                const day = dayInfo.day;
                newHours[day] = dayInfo.hours.map(hour => ({ open: hour.start, close: hour.end }));
                newClosedDays[day] = dayInfo.closed;
                newUncertainDays[day] = dayInfo.yet;
                newSelloutDays[day] = dayInfo.sold_out;
            });
    
            setHours(newHours);
            setClosedDays(newClosedDays);
            setUncertainDays(newUncertainDays);
            setSelloutDays(newSelloutDays);
        }
    }, [props.time]);

    return (
        <div className={classes.root}>
            {daysOfWeek.map((dayObj, dayIndex) => (
                <div className={classes.item} key={dayObj.en}>
                    <input type="hidden" name={`time[${dayIndex}].day`} value={dayObj.en} />
                    <div>
                        <div className={classes.label}>{dayObj.jp}</div>
                        <label>
                            <input
                                type="checkbox"
                                checked={closedDays[dayObj.en]}
                                onChange={() => toggleClosedDay(dayObj.en)}
                                name={`time[${dayIndex}].closed`}
                            />
                            定休日
                        </label>
                    </div>
                    <div className={classes.time}>
                        {hours[dayObj.en].map((timeSlot, index) => (
                            <div key={index} className={classes.row}>
                                <TextField
                                    select
                                    label="開始時間"
                                    value={timeSlot.open}
                                    onChange={(e) => handleTimeChange(dayObj.en, index, 'open', e.target.value)}
                                    disabled={closedDays[dayObj.en]}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    name={`time[${dayIndex}].hours[${index}].start`}
                                >
                                    {Array.from({ length: 48 }, (_, i) => {
                                        const hour = Math.floor(i / 2);
                                        const minute = i % 2 === 0 ? '00' : '30';
                                        return (
                                            <option key={i} value={`${hour}:${minute}`}>
                                                {`${hour}:${minute}`}
                                            </option>
                                        );
                                    })}
                                </TextField>
                                <span> 〜 </span>
                                <TextField
                                    select
                                    label="終了時間"
                                    value={timeSlot.close}
                                    onChange={(e) => handleTimeChange(dayObj.en, index, 'close', e.target.value)}
                                    disabled={closedDays[dayObj.en]}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    name={`time[${dayIndex}].hours[${index}].end`}
                                >
                                    {Array.from({ length: 48 }, (_, i) => {
                                        const hour = Math.floor(i / 2);
                                        const minute = i % 2 === 0 ? '00' : '30';
                                        return (
                                            <option key={i} value={`${hour}:${minute}`}>
                                                {`${hour}:${minute}`}
                                            </option>
                                        );
                                    })}
                                </TextField>
                                
                                {index > 0 && <div className={classes.removebt} onClick={() => removeTimeSlot(dayObj.en, index)} disabled={closedDays[dayObj.en] || uncertainDays[dayObj.en] || selloutDays[dayObj.en]}>
                                    <DeleteIcon />
                                </div>}
                            </div>
                        ))}
                            <div className={`${classes.option} ${closedDays[dayObj.en] ? classes.disabled : ''}`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={uncertainDays[dayObj.en]}
                                        onChange={() => toggleUncertainDay(dayObj.en)}
                                        disabled={closedDays[dayObj.en]}
                                        name={`time[${dayIndex}].yet`}
                                    />
                                    未定
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selloutDays[dayObj.en]}
                                        onChange={() => toggleSelloutDay(dayObj.en)}
                                        disabled={closedDays[dayObj.en]}
                                        name={`time[${dayIndex}].sold_out`}
                                    />
                                    売り切れ次第終了
                                </label>
                            </div>
                        </div>
                        <div className={classes.addbt} onClick={() => addTimeSlot(dayObj.en)} disabled={closedDays[dayObj.en] || uncertainDays[dayObj.en] || selloutDays[dayObj.en]}>
                            <AddCircleOutlineIcon />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    
    export default BusinessHours;