import { useEffect, useState } from 'react';
import { useAuthContext } from '../context/lib/AuthContext';
import classes from './AppLayout.module.scss'
import BottomMenu from './BottomMenu'
import Cart from './Cart'


const MenuLayout = ({ component: Component, props }) => {
    
    const { user } = useAuthContext()
    const [isLogined, setIsLogined] = useState(false)

    /* -----------------------------------------------
    * useEffect
    * ユーザーログインをチェック
    ----------------------------------------------- */
    useEffect(()=>{


        if(user.current === undefined) return

        const init = async ()=>{

            //ログインしていない時
            if(user.current === null) return
            
            setIsLogined(true)
        }
        init()
        
    }, [user])

    return <>
        <div id="app_root" className={ classes.root }>

            <div className={ classes.box }>
                <Component {...props} />
            </div>
            <Cart />
            {isLogined && <BottomMenu /> }
        </div>
        <div className={ classes.copylight }>© 2020-{new Date().getFullYear()} collabo inc.</div>
    </>;

};

export default MenuLayout;