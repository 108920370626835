import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from './SpBoot.module.scss';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { useAuthContext } from '../context/lib/AuthContext';
import { useEffect, useState } from "react";
import LoadingScreen from "./lib/LoadingScreen";
import { appLib } from "./lib/_app_lib";


const SpBoot = (props) => {
    const history = useHistory()
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(()=>{

        if(user.current === undefined) return

        const init = async ()=>{

            //ログインしていない時
            if(user.current === null){

                setIsLoading(false)
                return
            }

            history.push(`/`)
        }
        init()

        
    }, [user, history, ])

    useEffect(()=>{
        
        // auth処理に失敗した時
        if(JSON.stringify(user) === '""')
        {
            // タイムアウト処理
            const timeoutId = setTimeout(async ()=>{

                console.log("start timer")

                try{
                    await window.localStorage.clear();
                }catch(e){
                    alert("error localStorage.clear()")
                }

                try{
                    document.cookie.split(";").forEach(function(c) {
                        document.cookie = c.trim().split("=")[0] + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                    });
                } catch(e){
                    alert("error document.cookie")
                }

                try{
                    await window.sessionStorage.clear();
                } catch(e){
                    alert("error sessionStorage.clear()")
                }

                try{
                    
                    var DB_NAMES = [
                        'firebase-app-check-database',
                        'firebase-heartbeat-database',
                        'firebase-installations-database',
                        'firebaseLocalStorageDb',
                        'firestore/[DEFAULT]/tera-match-ef9ac/main',
                    ]
                    for(var i=0; i < DB_NAMES.length; i++)
                    {
                        var DB_NAME = DB_NAMES[i];
                        console.log("delete database: " + DB_NAME)
                        var request = await indexedDB.deleteDatabase(DB_NAME);
                        request.onsuccess = function(event) {
                            console.log("Database deleted successfully");
                        };
                        request.onerror = function(event) {
                            console.log("Error deleting database");
                        };
                    }
                } catch(e){
                    alert("error indexedDB")
                }

                // TODO:メモ：無限リロードにならないか
                await appLib.SuperReload()

                // TODO:SuperReloadした後でもページ遷移できるか
                window.location.href = "/authLogin"

                alert("再ログインしてください。")
            }, 5 * 1000)

            return () => {
                console.log("clear timer")
                clearTimeout(timeoutId)
            }
        }

    }, [ user, history,])

    return (
        <div className={classes.root}>
            <LoadingScreen show={isLoading} />
            <HelmetProvider>
                <Helmet>
                <meta
                        name="description"
                        content="xxxx"
                    />
                    <title>応募フォーム | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>


            <Carousel className={classes.box} autoFocus showThumbs={false} showArrows={false} showStatus={false} showIndicators={true} swipeable={true} >
                <div className={classes.slide1}>
                    <div className={classes.container}>
                        <img src="/logo.svg" alt="main" />
                        <br />
                        お寺のお葬式（イートプラス）に<br />
                        あなたのお店を登録してビジネスを拡大しましょう！
                    </div>
                </div>
                
            </Carousel>

            <div className={classes.bt_container}>

                <button className={classes.search_shop} onClick={()=>history.push(`/`)}>お店を探す</button>
                <hr />
                <button className={classes.submit} onClick={()=>history.push(`/authLogin?url=${encodeURIComponent('/recruit')}&start=mail`)}>無料登録する</button>
                
                <Link to={`/authLogin?url=${encodeURIComponent('/profile/edit')}`}>登録済みの方はこちらから</Link>
            </div>
            
        </div>
    );
};

export default SpBoot;