import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents, Tooltip } from "react-leaflet";
import L from 'leaflet';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'leaflet/dist/leaflet.css';
import { styled } from '@mui/system';
import LoadingScreen from './lib/LoadingScreen';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useGeocodeContext } from '../context/lib/GeocodeContext';
import { appLib } from './lib/_app_lib';
import { firebaseDB } from './lib/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FirestoreMap$profiles, } from './lib/FirestoreMap';
import { useAuthContext } from '../context/lib/AuthContext';


const MapContainer2 = styled(MapContainer)({
    height: "100%",
    ".leaflet-tile-container" :{
        filter: "contrast(60%) brightness(125%) saturate(40%) sepia(10%)",
    },
    ".shop-icon" : {
        backgroundColor: "#FAFAFA !important",
        borderRadius: "100px !important",
        width: "4em !important",
        height: "4em !important",
        border: "solid 3px #fff !important",
        boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.35) !important",
    },
    ".my-location-icon" : {
        pointerEvents: "none !important",
        zIndex: "100000000000 !important",
    },
    ".address-location-bt" : {
        borderRadius: "100px !important",
        boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.35) !important",
        fontWeight: "bold !important",
        pointerEvents: "all !important",
    }
});


// インスタグラム判定
const isInstagram = window.navigator.userAgent.toLowerCase().trim().includes('instagram')

const position = [36.5425231,136.6249059]

const ShopMap = (props) => {

    const isDrag = useRef(false)
    const {user} = useAuthContext()

    const ChangeView = ({ center, zoom }) => {


        const map = useMap();
    
        useEffect(() => {

            const calculateLatitudeShift = (zoomLevel, pixelOffsetY) => {
                const metersPerPixel = 156543.03392 * Math.cos(center[0] * Math.PI / 180) / Math.pow(2, zoomLevel);
                const offsetLat = pixelOffsetY * metersPerPixel / 111111; // Convert meters to degrees (approx)
                return offsetLat;
            }

            if(!initRef.current) {
                initRef.current = true
    
                let clientHeight
                try{
                    clientHeight = mapRef.current._container.clientHeight
                }catch{}
    
                const offsetCenter = clientHeight ? [
                    center[0] - calculateLatitudeShift(zoom, clientHeight / 4 - 0), 
                    center[1]
                ] : center;
    
                map.setView(offsetCenter, zoom);
            }
        }, [center, zoom, map]); // Dependencies for the useEffect to run
        
        return null;
    }
    
    const [zoomLevel, setZoomLevel] = useState(5);
    const ChangeEventView = (_props) => {
        
        const _setZoomLevel = () => setZoomLevel(mapEvents.getZoom());
        const mapEvents = useMapEvents({
            dragstart: ()=> {isDrag.current = true},
            zoomstart: _setZoomLevel,
            zoomend: _setZoomLevel,
            moveend: () => {
                const center = mapEvents.getCenter();
                setGeocode([center.lat, center.lng])

                if(!isDrag.current) return
                props.onMoveEnd()
                isDrag.current = false
            }
        });

        return null
    }

    const [mapPos, setMapPos] = useState({});
    const history = useHistory();
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])
    const [shopList, setShopList] = useState([])
    const initRef = useRef(false)
    const mapRef = useRef(null)
    const [myLocation, setMyLocation] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { setGeocode, updateGps } = useGeocodeContext()
    const [showLocationConfirm, setShowLocationConfirm] = useState(
        isInstagram &&
        !updateGps
    )
    const shopListState = useRef(0)
    const shopListStateDone = useRef(0)
    const isSetLocation = useRef(false)
    const updateGpsTime = useRef(0)
    const [isKitchenCar, setIsKitchenCar] = useState(false)

    useEffect(()=>{
        
        // updateGpsTimeが１秒以内の時はスキップ
        if(updateGpsTime.current > new Date().getTime() - 1000) return
        
        const newShopList = props.shopList.map(item => {
            if(item._geoloc){
                return item;
            }else{
                console.warn("no geocode", item.id);
                return null;
            }
        }).filter(item => item !== null); // remove items without geocode

        const haversine = (lat1, lon1, lat2, lon2) => {
            const R = 6371e3; // 地球の半径 (メートル)
            const dLat = (lat2 - lat1) * Math.PI / 180;
            const dLon = (lon2 - lon1) * Math.PI / 180;
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        
            return R * c; // 距離 (メートル)
        }
        const geocodeDict = [];
        newShopList.forEach(item => {
            if (item._geoloc) {
                let matched = false;
                geocodeDict.forEach(group => {
                    if (haversine(item._geoloc.lat, item._geoloc.lng, group[0]._geoloc.lat, group[0]._geoloc.lng) <= 100) {
                        group.push(item);
                        matched = true;
                        return;
                    }
                });
                if (!matched) {
                    geocodeDict.push([item]);
                }
            }else{
                console.warn("no geocode", item.id);
            }
        });

        for(const key in geocodeDict)
        {
            const list = geocodeDict[key];
            if(list.length <= 1) continue;
            const angleStep = 2 * Math.PI / list.length;

            list.forEach((item, index) => {
                const angle = index * angleStep;
                const x = Math.cos(angle);
                const y = Math.sin(angle);
                item.stackPos = {x: x, y: y};
            })
        }

        setShopList(newShopList)
        shopListState.current = new Date().getTime()
    }, [props.shopList])

    const setLocation = async(_setGeocode)=>{

        // 既に設定済みの時はスキップ
        if(isSetLocation.current) return

        setIsLoading(true)
        // 現在位置を設定する
        const location = await appLib.GetLocation()
        isSetLocation.current = true
        setIsLoading(false)
        if(location.status){
            _setGeocode(location.pos, true)
            updateGpsTime.current = new Date().getTime()
            return location.pos
        }
        return null
    }

    useEffect(() => {

        if (props.adjustmentCenter === false) return
        if (shopList.length === 0) return;
        if (shopListState.current === 0) return;
        if (shopListState.current === shopListStateDone.current) return;
        shopListStateDone.current = shopListState.current;

        const init = async () =>{

            const latitudes = shopList.map(shop => shop._geoloc ? shop._geoloc.lat : position[0]);
            const longitudes = shopList.map(shop => shop._geoloc ? shop._geoloc.lng : position[1]);
            const minLat = Math.min(...latitudes);
            const maxLat = Math.max(...latitudes);
            const minLon = Math.min(...longitudes);
            const maxLon = Math.max(...longitudes);

            console.log(shopList.map(shop => shop._geoloc))

            const latDiff = maxLat - minLat;
            const lonDiff = maxLon - minLon;

            let zoomLevel = 9.5;

            // adjust zoom based on max difference
            if (Math.max(latDiff, lonDiff) < 0.05) {
            zoomLevel = 12;
            }
            if (Math.max(latDiff, lonDiff) > 0.2) {
            zoomLevel = 9.4;
            }
            if (Math.max(latDiff, lonDiff) > 0.4) {
            zoomLevel = 8.495;
            }
            if (Math.max(latDiff, lonDiff) > 2) {
            zoomLevel = 6;
            }
            // and so on for other zoom levels...



            const latitudes2 = shopList
            // .filter(shop => shop._geoloc)
            .map(shop => shop._geoloc.lat);

            const longitudes2 = shopList
            // .filter(shop => shop._geoloc)
            .map(shop => shop._geoloc.lng);

            const avgLat = latitudes2.reduce((sum, val) => sum + val, 0) / latitudes2.length;
            const avgLon = longitudes2.reduce((sum, val) => sum + val, 0) / longitudes2.length;


            // 中心位置(インスタグラムの時は現在位置を取得しない)
            let center = isInstagram && !updateGps ? null : await setLocation(setGeocode)
            if(center){
                zoomLevel = 12
                setMyLocation(center)
            }
            if(!center) center = latitudes2.length === 0 ? position : [avgLat, avgLon]
            

            const _mapPos = {
                dist: Math.max(latDiff, lonDiff),   // 確認用
                center: center,
                zoom: zoomLevel,
            }
            setMapPos(_mapPos)
            console.log(_mapPos)

            initRef.current = false
            forceUpdate()
        }
        init()

    }, [shopList, forceUpdate, setGeocode, updateGps, props.adjustmentCenter, ]);


    
    /* -----------------------------------------------
    * useEffect
    * キッチンカーのチェック
    ----------------------------------------------- */
    useEffect(()=>{

        if(user.current === undefined) return

        const init = async ()=>{

            //ログインしていない時
            if(user.current === null) return

            // 読み取り専用データを取得
            const profileDoc = await getDoc(doc(firebaseDB, FirestoreMap$profiles, user.current.uid))
            if(!profileDoc.exists()) return

            const profileData = profileDoc.data()
            if(profileData === undefined) return
            console.log(profileData)
            setIsKitchenCar(profileData?.genres?.includes("キッチンカー"))
        }
        init()

        
    }, [user])


    const renderContent = useMemo(() => {
        console.log("useMemo")

        // 現在位置で店の住所を設定
        const handleSetAddressLocation = async () => {
            console.log("handleSetAddressLocation")
            setIsLoading(true)
            
            console.log("myLocation", myLocation)

            await setDoc(doc(firebaseDB, FirestoreMap$profiles, user.current.uid), {
                    address: myLocation,
                }, { merge: true }
            )

            setIsLoading(false)

            alert("この位置で店の住所を設定しました")
        }
        
        const dist = zoomLevel > 14 ? 40 : 15

        return <MapContainer2 center={position} zoom={13} scrollWheelZoom={true} zoomControl={false} ref={mapRef}> 
            <ChangeView center={mapPos.center || position} zoom={mapPos.zoom || 13} />
            <ChangeEventView />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {/* 現在位置 */}
            {myLocation && <Marker position={myLocation} zIndexOffset={100000} icon={L.icon({iconUrl: '/marker/my-location.png', iconAnchor:[11,11], iconSize: [23,23], className: 'my-location-icon'})}>
                {isKitchenCar && <Tooltip permanent direction="top" className="address-location-bt">
                    <div onClick={handleSetAddressLocation} style={{zIndex: 999999, position:'relative'}}>
                        この位置で店の住所を設定
                    </div>
                </Tooltip>}
            </Marker>}

            {/* 店舗のポインタ */}
            {shopList.map((shop, index) => {

                const iconAnchor = [24, 24]
                if(shop.stackPos){
                    iconAnchor[0] += shop.stackPos.x * dist
                    iconAnchor[1] += shop.stackPos.y * dist
                }

                // TODO: デバッグhome2の時のみメニュー画像をiconとして利用する
                let icon = shop.icon
                if(window.location.pathname.startsWith("/home2")) icon = (shop?.menuIcons?.length > 0 ? shop.menuIcons[0] : null) || shop?.icon


                return (
                    <Marker key={index} position={[shop._geoloc.lat, shop._geoloc.lng ]} icon={L.icon({iconUrl: icon, iconAnchor:iconAnchor, className: 'shop-icon'})}
                        zIndexOffset={index * 1000}
                        eventHandlers={{
                            click: (e) => {
                                // TODO: テストroute_testを追加
                                const urlParams = new URLSearchParams(window.location.search);

                                let result = '';
                                for (let i = 1; i <= 4; i++) {  // '4' can be replaced with any number to handle more route_test{n} parameters
                                  const param = urlParams.get(`route_test${i}`);
                                  if (param) {
                                    result += `&route_test${i}=${param}`;
                                  }
                                }

                                // 開くアクション設定がある場合
                                if(shop.open_action){
                                    window.open(shop.open_action, '_blank')
                                } else {
                                    history.push(`/shop/${shop.id}?q=test${result}`)
                                }
                            },
                        }} />
            )})}
        </MapContainer2>
    }, [shopList, mapPos, history, zoomLevel, myLocation, user, isKitchenCar])


    return <>
        <LoadingScreen show={isLoading} zIndex={9999999}/>
        {renderContent}
        <Dialog
            open={ showLocationConfirm }
            onClose={()=>setShowLocationConfirm(false)}
            sx={{zIndex:999999}}
        >
            <DialogContent>
            現在位置を取得しますか？
            </DialogContent>

            <DialogActions sx={{justifyContent:'center'}}>

                <Button onClick={()=>setShowLocationConfirm(false)} >
                    キャンセル
                </Button>
                <Button onClick={async()=>{
                    let center = await setLocation(setGeocode)
                    if(center){
                        
                        setMapPos({
                            center: center,
                            zoom: 12,
                        })
                        setMyLocation(center)
                        initRef.current = false
                        forceUpdate()
                    }
                    setShowLocationConfirm(false)
                    }} variant="contained">
                    はい
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

export default ShopMap;
