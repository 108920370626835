import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import classes from "./CallKitchenCar.module.scss";
import Footer from "./Footer";

const CallKitchenComplete = () => {
  return (
    <div className={classes.root}>
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="xxxx" />
          <title>応募完了 | 「お寺のお葬式」</title>
        </Helmet>
      </HelmetProvider>

      <div className={classes.header}>
        <img src="/call-kitchen-car/header.jpg" alt="main" />
      </div>

      <div className={classes.intro}>
        <div className={classes.text}>
          <p>
            ご応募ありがとうございました！ <br />
            <br />
            ご応募いただいた内容を確認のうえ、ご連絡いたしますので、 <br />
            今しばらくお待ちくださいませ。
          </p>
        </div>
      </div>

      <div className={classes.bt_container}>
        <Link className={classes.submit} to="/">
          トップページに戻る
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default CallKitchenComplete;