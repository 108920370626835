import classes from './Terms.module.scss'
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TermsText from './TermsText';

const Terms = (props) => {
    
    return (
        <div className={ classes.root }>

            <div className={ classes.header }>
                <ArrowBackIosNewIcon onClick={()=>window.history.back() }/>
                <h1>利用規約</h1>
            </div>

            <HelmetProvider>
                <Helmet>
                   <meta
                        name="description"
                        content="「お寺のお葬式」の利用規約"
                    />
                    <title>利用規約 | 「お寺のお葬式」</title>
                </Helmet>
            </HelmetProvider>

            <TermsText classes={classes} />

        </div>
    );
};

export default Terms;