import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import classes from "./lib/AccountEditMenu.module.scss";
import ValidateTextField from './lib/ValidateTextField';

/* -----------------------------------------------
* AccountEditMenuUnsubscribeDialog
----------------------------------------------- */
export const AccountEditMenuUnsubscribeDialog = (props) => {

    const handleonUnsubscribe = async ()=>{
        const data = {}

        // classes.unsubscribe_popup以下をdocumentQuerySelectorAllでinputを取得してdataに格納する
        const inputs = document.querySelectorAll(`.${classes.unsubscribe_popup} input,textarea`)
        for(let i=0; i < inputs.length; i++)
        {
            const input = inputs[i]
            data[input.name] = input.value
        }
        
        props.onUnsubscribe(data)
    }

    return (
        <Dialog
            open={ props.show }
            onClose={()=>props.onClose}
            className={classes.unsubscribe_popup}
        >
            <DialogTitle >
                本当に退会しても宜しいですか？
            </DialogTitle>
            <DialogContent>
                退会は即時反映され、一度退会すると復活できません。
                <p>また退会前に必ず退会に関するガイドをご確認ください。</p>

                <div className={classes.unsubscribe_guide}>
                    <div className={classes.title}>
                        退会ガイド
                    </div>
                    <ul className={classes.list}>
                        <li>ご利用のアカウントでは「お寺のお葬式」にアクセスできなくなります</li>
                        <li>お客様の情報は消去されます</li>
                        <li>送信した写真や文章は取得、確認できなくなります</li>
                        <li>アカウントの復帰には再度審査が必要になります</li>
                    </ul>
                </div>

            </DialogContent>

            <div className={classes.checkbox}>
                <FormControlLabel control={<Checkbox />} label="退会ガイドを確認しました" />
            </div>

            <div className={classes.reason}>
                <div className={classes.label}>退会理由をお聞かせください（自由入力）</div>
                <ValidateTextField rows={1} multiline name="reason" />
            </div>

            <DialogActions sx={{justifyContent:'center'}}>

                <Button autoFocus onClick={props.onClose} >
                    キャンセル
                </Button>
                <Button onClick={handleonUnsubscribe} autoFocus color="error" variant="contained">
                    上記に同意して<br />退会する
                </Button>
            </DialogActions>
        </Dialog>
    )
}