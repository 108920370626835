import React, { useEffect, useState, } from "react";
import classes from './SearchResult2.module.scss';
import ModalBottomSheet from './lib/ModalBottomSheet';
import FixedLayout from './lib/FixedLayout';
import { Link, } from "react-router-dom";
import NetImg from "./lib/NetImg";
import * as Scroll from 'react-scroll';
import GridOnIcon from '@mui/icons-material/GridOn';
import MenuIcon from '@mui/icons-material/Menu';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Dialog } from "@mui/material";
import { getBusinessHourText } from "./lib/BusinessHours";


const cate_list = ["営業中"]


const SearchResult = (props) => {

    const[modalShow, setModalShow] = useState(false)
    const[applyFlag, setApplyFlag] = useState()
    const[limitCount, setLimitCount] = useState(props.limit || 9999999)
    const[selectedTab, setSelectedTab] = useState('menu')
    const [shopList, setShopList] = useState(props.shopList || [])
    const [showVideoPopup, setShowVideoPopup] = useState(false)
    const [selectedShop, setSelectedShop] = useState({})
    const [selectedCate, setSelectedCate] = useState()
    

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{

        if(!props.show)
            return

        setModalShow(true)
        setApplyFlag(false)


        
    }, [props.show])

    /* ----------------------------------------
    * useEffect
    ----------------------------------------*/
    useEffect(()=>{
        
        // インスタグラムブラウザでの高さ調整
        const ua = window.navigator.userAgent.toLowerCase().trim();
        if(!ua.includes('instagram')) return

        setTimeout(() => {
            document.querySelector(`.${classes.bottom_sheet}`).style.paddingTop = `${window.innerHeight - 150}px`
        }, 1000)
    }, [])

    useEffect(()=>{

        console.log("show!!!")
        if(!props.isShowResultList) return

        Scroll.animateScroll.scrollTo(window.innerHeight / 3, {

            containerId: 'search_result_container',
            smooth: 'easeInOutSine',
            duration: 500,
        });

    }, [props.shopList, props.isShowResultList])

    // タブの状態でshopListをフィルタリング
    useEffect(()=>{
            
            if(!props.shopList) return
    
            // pr_video を含むアイテムと含まないアイテムを分ける
            const shopsWithVideo = props.shopList.filter((shop) => shop.pr_video);
            const shopsWithoutVideo = props.shopList.filter((shop) => !shop.pr_video);

            // 両リストをシャッフル
            shopsWithVideo.sort(() => 0.5 - Math.random());
            shopsWithoutVideo.sort(() => 0.5 - Math.random());

            // 5件ごとに pr_video があるアイテムを挿入
            const combinedShopList = [];
            let videoIndex = 0;
            let count = 0;

            for (const shop of shopsWithoutVideo) {
                combinedShopList.push(shop);
                count++;

                // 5件ごとに pr_video があるアイテムを追加
                if (count % 5 === 0 && videoIndex < shopsWithVideo.length) {
                    combinedShopList.push(shopsWithVideo[videoIndex]);
                    videoIndex++;
                }

            }


            


            // タブに応じたフィルタリング
            const filteredShopList = combinedShopList.filter((shop) => {
            if (selectedTab === 'all' || selectedTab === 'menu') return true;

            if (selectedTab === 'video') {
                return shop.pr_video;
            }

            return true;
            });

            setShopList(filteredShopList.map((shop)=>{
                return {
                    ...shop,
                    time: getBusinessHourText(shop)
                }
            }));

    
    }, [props.shopList, selectedTab])


    /* ----------------------------------------
    * handleApply
    ----------------------------------------*/
    const handleOnClose = ()=>{

        props.onClosed()
        if(applyFlag)
            props.onApply()

    }

    /* ----------------------------------------
    * handleChangeTab
    ----------------------------------------*/
    const handleChangeTab = (tab)=>{
        console.log(tab)
        setSelectedTab(tab)
    }

    /* ----------------------------------------
    * handleCateClick
    ----------------------------------------*/
    const handleCateClick = (cate)=>{
        console.log(cate)

        // 選択中をタップした時は解除
        if(cate === selectedCate){
            cate = null
        }

        props.onCateClick(cate)
        setSelectedCate(cate)
    }

    /* ----------------------------------------
    * レンダリング全体
    ----------------------------------------*/
    return (
        <FixedLayout>

            
            <div className={`${classes.root} ${props.show ? '' : classes.hide}`}>

                <ModalBottomSheet id="search_result_container" onBackClick={()=>{}} onClosed={handleOnClose} isComplete={false} show={modalShow && props.show} className={classes.bottom_sheet}>

                    <div className={ `${classes.content} ${ props.isShowResultList ? classes.list_mode : classes.search_mode}` }>

                        <hr className={classes.bar} />

                        {props.isShowResultList && <>

                            {props.horizontalShopList && props.shopList.length > 0 && <div className={ classes.shops }>
                                {props.shopList.slice(0, limitCount).map((shop, index) => (
                                    <Link key={index} className={classes.item} to={`/shop/${shop.id}?q=test`}>
                                        <NetImg src={shop.icon || '/profile/food-ico.svg'} />
                                        <div className={classes.name}>{shop.name}</div>
                                    </Link>
                                ))}
                            </div>}
                            
                            <div className={classes.tab}>
                                <div>
                                    絞り込み　＞
                                </div>
                                <div>
                                    並べ替え ＋
                                </div>
                            </div>
                            {props.horizontalCateList && <div className={ classes.cate_list } style={{display: 'none'}}>
                                {cate_list.map((name, index) => (
                                    <div key={index} className={`${classes.item} ${selectedCate === name && classes.selected}`} onClick={()=>handleCateClick(name)}>
                                        <NetImg src={`/cate-icon/${index+1}.svg`} />
                                        <div className={classes.name}>{name}</div>
                                    </div>
                                ))}
                            </div>}

                            <div className={classes.tabs} style={{display: 'none'}}>
                                <div className={`${classes.bt} ${selectedTab === 'menu' && classes.selected}`} onClick={()=>handleChangeTab('menu')}><MenuIcon /></div>
                                <div className={`${classes.bt} ${selectedTab === 'all' && classes.selected}`} onClick={()=>handleChangeTab('all')}><GridOnIcon /></div>
                                <div className={`${classes.bt} ${selectedTab === 'video' && classes.selected}`} onClick={()=>handleChangeTab('video')}><VideocamIcon /></div>
                            </div>
                            <div className={ `${classes.list} ${selectedTab === 'menu' ? classes.menu : ''}` }
                                // style={{height: `${window.innerHeight * 0.96 / 3 *  Math.floor(props.shopList.length / 3) }px`} } ref={}
                                >
                                {shopList.length === 0 && <div className={classes.no_result}>何も見つかりませんでした</div>}
                                {shopList.slice(0, limitCount).map((shop, index) => {

                                    if(selectedTab === 'menu'){
                                        return (
                                            <Link key={index} className={classes.item} to={`/shop/${shop.id}?q=test`}>
                                                
                                                <NetImg className={classes.img} src={shop.icon || '/profile/food-ico.svg'} />
                                                <div className={classes.info}>
                                                    <div className={classes.name}>{shop.name}</div>
                                                    <div className={classes.cate}>{Array.isArray(shop.cate) ? shop.cate.join('、') : shop.cate}</div>
                                                    <div className={classes.address}>{shop.address}</div>
                                                    <div className={classes.time}>{shop.time}</div>
                                                    <div className={classes.time}>¥150,000〜</div>
                                                </div>
                                            
                                            </Link>
                                        );
                                    }

                                    if(selectedTab !== 'menu' && shop.pr_video){
                                        return <div key={index} className={`${classes.item} ${classes.video} ${selectedTab === 'video' ? classes.video_only : ''}`}>
                                            <video src={shop.pr_video} playsInline autoPlay muted loop onClick={()=>{
                                                setSelectedShop(shop)
                                                setShowVideoPopup(true)
                                            }}/>
                                        </div>
                                    }

                                    var foodIcon = null
                                    if(shop.menuIcons){

                                        // ハイライトされたハッシュタグがあればアイコンを変更
                                        for (let i = 0; i < shop._highlightResult?.menu_hashtags?.length; i++) {
                                            const hashData = shop._highlightResult.menu_hashtags[i];
                                            
                                            if (hashData.matchLevel !== "none") {
                                                foodIcon = shop.menuIcons[i]
                                                break
                                            }
                                        }
                                        // 見つからなかった時はランダムで選択
                                        if(!foodIcon){
                                            const menuIcons = shop.menuIcons.filter((icon)=>icon)
                                            const rand = Math.floor(Math.random() * menuIcons.length)
                                            foodIcon = menuIcons[rand]
                                        }
                                    }

                                    // 見つからなかった時はお店のアイコン
                                    if(!foodIcon){
                                        foodIcon = shop.icon
                                    }

                                    // それでもなかったらデフォルトのアイコン
                                    if(!foodIcon){
                                        foodIcon = '/profile/food-ico.svg'
                                    }

                                    
                                    return <Link key={index} className={classes.item} to={`/shop/${shop.id}?q=test`}>
                                        <NetImg src={foodIcon} />
                                    </Link>
                                    }
                                )}
                                
                            </div>
                            {props.shopList.length > limitCount && <div className={classes.more} onClick={()=>setLimitCount(limitCount + 9)}>もっと見る</div>}
                        </>}

                        
                    </div>
                    {props.children}

                </ModalBottomSheet>

            </div>

            <Dialog
                open={ showVideoPopup }
                onClose={()=>setShowVideoPopup(false)}
                className={classes.video_popup}
            >
                <Link className={classes.header} to={`/shop/${selectedShop.id}?q=test`}>
                    <NetImg src={selectedShop.icon || '/profile/food-ico.svg'} />
                    <div className={classes.name}>{selectedShop.name}</div>
                </Link>
                <video src={selectedShop.pr_video} playsInline autoPlay muted loop controls />
            </Dialog>

        </FixedLayout>
    );
};

export default SearchResult;